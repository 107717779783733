import React, { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import { useLocation } from "react-router-dom";

// IMPORTADOS
import UpIcon from "@mui/icons-material/KeyboardArrowUp";

const FloatButton = () => {
  const { pathname } = useLocation();

  // EFECTO BOTON OCULTO
  const [showButtonFloat, setShowButtonFloat] = useState(true);
  const [effectColor, setEffectColor] = useState(false);

  const controlButtonFloat = () => {
    // BOTON OCULTO
    if (window.scrollY > 500) {
      setShowButtonFloat(false);
    } else {
      setShowButtonFloat(true);
    }
    
    // COLOR BOTON FLOTANTE
    // if (window.scrollY > 3400) {
    //   setEffectColor(true);
    // } else {
    //   setEffectColor(false);
    // }
  };
  // ESTADO DE TEMA
  // const [effectColor, setEffectColor] = useState(false);

  // // CONTROL TEMA
  // const controlTheme = () => {
  //   if (window.scrollY > 3400) {
  //     setEffectColor(true);
  //   } else {
  //     setEffectColor(false);
  //   }
  // };

  useEffect(() => {
    window.addEventListener("scroll", controlButtonFloat);
    return () => {
      window.removeEventListener("scroll", controlButtonFloat);
    };
  }, []);

  // OCULTAR EN PAGINA EVENTOS
  if (pathname === "/events") return null;

  return (
    <Fab
      href="#header"
      // href="#video"
      color="primary"
      aria-label="add"
      sx={{
        display: showButtonFloat && "none",
        backgroundColor: "#F8D29A",
        // backgroundColor: pathname === 'drinks' ? (effectColor && "#F8D29A") : "#6D228B",
        position: "fixed !important",
        bottom: "60px",
        right: "60px",
        boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
        ":hover": {
          backgroundColor: "#F8D29A",
          boxShadow: "0px 15px 20px rgba(0, 0, 0, 0.3)",
        },

        ">svg": {
          width: 30,
          height: 30,
          ">path": {
            color: "#CD6445",
          },
        },
      }}
    >
      <UpIcon />
    </Fab>
  );
};

export default FloatButton;
