import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// IMPORTADOS
import "../Menu.scss";
import BannerComidas from "../../../images/menu/comidas/bannerComidas.jpg";
import fondoMenu from "../../../images/menu/fondoMenu.png";
import SeccionCard from "./seccion/SeccionCard";
import SeccionList from "./seccion/SeccionList";
import IconFama from "../../../components/icons/IconFama";
import imgBannerEntradas from "../../../images/menu/comidas/entradas/bannerEntradas.png";
import imgBannerArroz from "../../../images/menu/comidas/arroces/bannerArroz.png";
import imgBannerCarnesYaves from "../../../images/menu/comidas/carnesYaves/carnesYaves.png";
import imgBannerPastasYrisottos from "../../../images/menu/comidas/pastasYrisottos/pastasYrisottos.png";
import imgBannerPescados from "../../../images/menu/comidas/pescado/pescados.png";
import imgBannerSopas from "../../../images/menu/comidas/sopas/sopas.png";
import imgBannerPostres from "../../../images/menu/comidas/postres/postres.png";
import imgBannerKids from "../../../images/menu/comidas/kids/kids.png";
import imgBannerSides from "../../../images/menu/comidas/sides/sides.png";

// API
import {
  apiEntradasEs,
  apiArrocesEs,
  apiCarnesYavesEs,
  apiPastasYrisottosEs,
  apiPescadosEs,
  apiSopasEs,
  apiPostresEs,
  apiKidsEs,
  apiSidesEs,
} from "../../../components/data/dataApiEs";
import {
  apiArrocesEn,
  apiCarnesYavesEn,
  apiEntradasEn,
  apiKidsEn,
  apiPastasYrisottosEn,
  apiPescadosEn,
  apiPostresEn,
  apiSidesEn,
  apiSopasEn,
} from "../../../components/data/dataApiEn";

const Foods = () => {
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("menu");

  const [effectColor, setEffectColor] = useState(false);

  const controlTheme = () => {
    if (window.scrollY > 9100) {
      setEffectColor(true);
    } else {
      setEffectColor(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlTheme);
    return () => {
      window.removeEventListener("scroll", controlTheme);
    };
  }, []);

  return (
    // CONTENEDOR COMIDA
    <Box
      // className="containerFood"
      id="foods"
      className={`containerFood ${effectColor && "containerFoodFoods"}`}
      sx={{ paddingBottom: pathname === "/foods" ? "200px" : null }}
    >
      {/*************
        FONDO IMAGEN
      *************/}
      <Box
        className="backgroundImage"
        id="up"
        sx={{
          backgroundImage: `url(${BannerComidas})`,
        }}
      >
        {/*************
          DEGRADADO
        *************/}
        <Box className="degradado">
          <Box className="title" sx={{ maxWidth: "1430px", width: "80%" }}>
            <Typography component={"span"}>
              {t("bannerFood.subtitle")}
            </Typography>

            <Typography>{t("bannerFood.title")}</Typography>
          </Box>
        </Box>
      </Box>

      {/***********
        BAR MENU
      ***********/}
      <Box className="barMenu" sx={{ backgroundImage: `url(${fondoMenu})` }}>
        <Box
          className="contentBarMenu"
          sx={{
            marginLeft: "9%",
            marginRight: "9%",
          }}
        >
          <Typography component={"a"} href="#seccionEntrada">
            {t("linkBannerFood.starter")}
          </Typography>

          <Typography component={"a"} href="#seccionArroces">
            {t("linkBannerFood.rice")}
          </Typography>

          <Typography component={"a"} href="#seccionCarnesYAves">
            {t("linkBannerFood.meats")}
          </Typography>

          <Typography component={"a"} href="#seccionPastasYrisottos">
            {t("linkBannerFood.pastaAndRisottos")}
          </Typography>

          <Typography component={"a"} href="#seccionPescados">
            {t("linkBannerFood.seefood")}
          </Typography>

          <Typography component={"a"} href="#seccionSopas">
            {t("linkBannerFood.soups")}
          </Typography>

          <Typography component={"a"} href="#seccionKids">
            {t("linkBannerFood.kidsMenu")}
          </Typography>

          <Typography component={"a"} href="#seccionPostres">
            {t("linkBannerFood.desserts")}
          </Typography>

          <Typography component={"a"} href="#seccionSides">
            {t("linkBannerFood.sides")}
          </Typography>
        </Box>
      </Box>

      {/**********
        CONTENT
      **********/}
      <Box className="contentFood">
        {/********************
          SECCION DE ENTRADAS
        **********************/}
        <SeccionCard
          id={"seccionEntrada"}
          title={t("linkBannerFood.starter")}
          api={i18n.language === "es" ? apiEntradasEs : apiEntradasEn}
          img={imgBannerEntradas}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle" sx={{ ">p": { marginTop: "50px" } }}>
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#804C33" : "#CD6440"}
          />

          <Typography>{t("titles.mainDishes")}</Typography>
        </Box>

        {/****************************
          SECCION DE PLATOS FUERTES
        ******************************/}
        {/*********************
          SECCION DE ARROCES
        ***********************/}
        <SeccionCard
          id={"seccionArroces"}
          title={t("linkBannerFood.rice")}
          api={i18n.language === "es" ? apiArrocesEs : apiArrocesEn}
          img={imgBannerArroz}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#804C33" : "#CD6440"}
          />
        </Box>

        {/****************************
          SECCION DE CARNES Y AVES
        ******************************/}
        <SeccionCard
          id={"seccionCarnesYAves"}
          title={t("linkBannerFood.meats")}
          api={i18n.language === "es" ? apiCarnesYavesEs : apiCarnesYavesEn}
          img={imgBannerCarnesYaves}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#804C33" : "#CD6440"}
          />
        </Box>

        {/****************************
          SECCION DE PASTAS Y RISOTTOS
        ******************************/}
        <SeccionCard
          id={"seccionPastasYrisottos"}
          title={t("linkBannerFood.pastaAndRisottos")}
          api={
            i18n.language === "es" ? apiPastasYrisottosEs : apiPastasYrisottosEn
          }
          img={imgBannerPastasYrisottos}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#804C33" : "#CD6440"}
          />
        </Box>

        {/****************************
          SECCION DE PESCADOS
        ******************************/}
        <SeccionCard
          id={"seccionPescados"}
          title={t("linkBannerFood.seefood")}
          api={i18n.language === "es" ? apiPescadosEs : apiPescadosEn}
          img={imgBannerPescados}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#804C33" : "#CD6440"}
          />
        </Box>

        {/******************
          SECCION DE SOPAS
        ********************/}
        <SeccionCard
          id={"seccionSopas"}
          title={t("linkBannerFood.soups")}
          api={i18n.language === "es" ? apiSopasEs : apiSopasEn}
          img={imgBannerSopas}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#804C33" : "#CD6440"}
          />
        </Box>

        {/****************************
          SECCION DE INFANTIL
        ******************************/}
        <SeccionCard
          id={"seccionKids"}
          title={t("linkBannerFood.kidsMenu")}
          api={i18n.language === "es" ? apiKidsEs : apiKidsEn}
          img={imgBannerKids}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#804C33" : "#CD6440"}
          />
        </Box>

        {/****************************
          SECCION DE POSTRES
        ******************************/}
        <SeccionCard
          id={"seccionPostres"}
          title={t("linkBannerFood.desserts")}
          api={i18n.language === "es" ? apiPostresEs : apiPostresEn}
          img={imgBannerPostres}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#804C33" : "#CD6440"}
          />
        </Box>

        {/****************************
          SECCION DE ACOMPAÑANTES
        ******************************/}
        <SeccionList
          id={"seccionSides"}
          title={t("linkBannerFood.sides")}
          api={i18n.language === "es" ? apiSidesEs : apiSidesEn}
          img={imgBannerSides}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box
          className="titleMiddle"
          sx={{ paddingTop: "200px", paddingBottom: "0px" }}
        >
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#804C33" : "#CD6440"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Foods;
