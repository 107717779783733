import React from "react";
import { useLocation } from "react-router-dom";

const IconX = ({ width = 17, height = 17, color = "#F8D29A" }) => {
  const { pathname } = useLocation();

  if (pathname === "/home") {
    color = "#F8D29A";
  } else if (pathname === "/foods") {
    color = "#804C33";
  } else if (pathname === "/drinks") {
    color = "#E6CAF0";
  } else if (pathname === "/night") {
    color = "#FFF7EB";
  } else if (pathname === "/events") {
    color = "#F8D29A";
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7071 0.292893C16.3166 -0.0976311 15.6834 -0.0976311 15.2929 0.292893L0.292892 15.2929C-0.0976334 15.6834 -0.0976334 16.3166 0.292892 16.7071C0.683416 17.0976 1.31658 17.0976 1.70711 16.7071L16.7071 1.70711C17.0976 1.31658 17.0976 0.683417 16.7071 0.292893Z"
        fill={color}
      />
    </svg>
  );
};

export default IconX;
