import React from "react";
import { Box, Typography } from "@mui/material";
import FamaLogoFooter from "../../../images/logos/FamaLogoFooter";

const ConditionService = () => {
  return (
    <Box
      sx={{
        /* backgroundColor: "pink", */ marginTop: "-73px",
        paddingTop: "200px",
        // paddingX: "20%",

        ">.contentImg": {
          // backgroundColor: "pink",
          textAlign: "center",
        },

        ">.contentInfo": {
          // backgroundColor: "aqua",
          margin: "50px 20% 70px 20%",
        },
      }}
    >
      <Box className="contentImg">
        <FamaLogoFooter color="#f8d29a" />
      </Box>

      <Box className="contentInfo">
        <Typography component={"h1"}>
          Condiciones del servicio de Fama
        </Typography>

        <Box>
          <Typography>
            Esta Política de privacidad describe cómo se recopila, utiliza y
            comparte su información personal cuando visita o hace una compra en
            https://famagastrobar.com/ (denominado en lo sucesivo el “Sitio”).
          </Typography>

          <Typography component={"h2"}>
            INFORMACIÓN PERSONAL QUE RECOPILAMOS
          </Typography>

          <Typography>
            Cuando visita el Sitio, recopilamos automáticamente cierta
            información sobre su dispositivo, incluida información sobre su
            navegador web, dirección IP, zona horaria y algunas de las cookies
            que están instaladas en su dispositivo. Además, a medida que navega
            por el Sitio, recopilamos información sobre las páginas web
            individuales o los productos que ve, las páginas web o los términos
            de búsqueda que lo remitieron al Sitio e información sobre cómo
            interactúa usted con el Sitio. Nos referimos a esta información
            recopilada automáticamente como “Información del dispositivo”.
          </Typography>

          <Typography component={"b"}>
            Recopilamos Información del dispositivo mediante el uso de las
            siguientes tecnologías:
          </Typography>

          <Typography component={"span"}>
            - Los “Archivos de registro” rastrean las acciones que ocurren en el
            Sitio y recopilan datos, incluyendo su dirección IP, tipo de
            navegador, proveedor de servicio de Internet, páginas de
            referencia/salida y marcas de fecha/horario.
          </Typography>

          <Typography component={"span"}>
            - Las “balizas web”, las “etiquetas” y los “píxeles” son archivos
            electrónicos utilizados para registrar información sobre cómo navega
            usted por el Sitio.
          </Typography>

          <Typography>
            [[INSERTAR DESCRIPCIONES DE OTROS TIPOS DE TECNOLOGÍAS DE
            SEGUIMIENTO QUE SE UTILICEN]] Además, cuando hace una compra o
            intenta hacer una compra a través del Sitio, recopilamos cierta
            información de usted, entre la que se incluye su nombre, dirección
            de facturación, dirección de envío, información de pago (incluidos
            los números de la tarjeta de crédito [[INSERTAR CUALQUIER OTRO TIPO
            DE PAGO ACEPTADO]]), dirección de correo electrónico y número de
            teléfono. Nos referimos a esta información como “Información del
            pedido”.
          </Typography>

          <Typography>
            [[INSERTAR CUALQUIER OTRA INFORMACIÓN QUE USTED RECOPILA: DATOS SIN
            CONEXIÓN, LISTAS/DATOS DE MARKETING ADQUIRIDOS]]
          </Typography>

          <Typography>
            Cuando hablamos de “Información personal” en la presente Política de
            privacidad, nos referimos tanto a la Información del dispositivo
            como a la Información del pedido.
          </Typography>

          <Typography>¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?</Typography>

          <Typography>
            Usamos la Información del pedido que recopilamos en general para
            preparar los pedidos realizados a través del Sitio (incluido el
            procesamiento de su información de pago, la organización de los
            envíos y la entrega de facturas y/o confirmaciones de pedido).
            Además, utilizamos esta Información del pedido para: comunicarnos
            con usted; examinar nuestros pedidos en busca de fraudes o riesgos
            potenciales; y cuando de acuerdo con las preferencias que usted
            compartió con nosotros, le proporcionamos información o publicidad
            relacionada con nuestros productos o servicios. [[INSERTAR OTROS
            USOS DE INFORMACIÓN DEL PEDIDO]] Utilizamos la Información del
            dispositivo que recopilamos para ayudarnos a detectar posibles
            riesgos y fraudes (en particular, su dirección IP) y, en general,
            para mejorar y optimizar nuestro Sitio (por ejemplo, al generar
            informes y estadísticas sobre cómo nuestros clientes navegan e
            interactúan con el Sitio y para evaluar el éxito de nuestras
            campañas publicitarias y de marketing). [[INSERTAR OTROS USOS DE
            INFORMACIÓN DEL DISPOSITIVO, INCLUIDOS PUBLICIDAD/RETARGETING]]
          </Typography>

          <Typography>COMPARTIR SU INFORMACIÓN PERSONAL</Typography>

          <Typography>
            Compartimos su Información personal con terceros para que nos ayuden
            a utilizar su Información personal, tal como se describió
            anteriormente. Por ejemplo, utilizamos la tecnología de Shopify en
            nuestra tienda online. Puede obtener más información sobre cómo
            Shopify utiliza su Información personal aquí:
            https://www.shopify.com/legal/privacy. También utilizamos Google
            Analytics para ayudarnos a comprender cómo usan nuestros clientes el
            Sitio. Puede obtener más información sobre cómo Google utiliza su
            Información personal aquí:
            https://www.google.com/intl/es/policies/privacy/. Puede darse de
            baja de Google Analytics aquí:
            https://tools.google.com/dlpage/gaoptout.
          </Typography>

          <Typography>
            Finalmente, también podemos compartir su Información personal para
            cumplir con las leyes y regulaciones aplicables, para responder a
            una citación, orden de registro u otra solicitud legal de
            información que recibamos, o para proteger nuestros derechos.
            [[INCLUIR SI SE USA REMARKETING O PUBLICIDAD DIRIGIDA]] PUBLICIDAD
            ORIENTADA POR EL COMPORTAMIENTO
          </Typography>

          <Typography>
            Como se describió anteriormente, utilizamos su Información personal
            para proporcionarle anuncios publicitarios dirigidos o
            comunicaciones de marketing que creemos que pueden ser de su
            interés. Para más información sobre cómo funciona la publicidad
            dirigida, puede visitar la página educativa de la Iniciativa
            Publicitaria en la Red ("NAI" por sus siglas en inglés) en
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
          </Typography>

          <Typography>
            Puede darse de baja de la publicidad dirigida mediante los
            siguientes enlaces:
          </Typography>

          <Typography>
            [[ INCLUIR ENLACES PARA DARSE DE BAJA DE CUALQUIER SERVICIO QUE SE
            UTILICE. ALGUNOS ENLACES FRECUENTES INCLUYEN: FACEBOOK:
            https://www.facebook.com/settings/?tab=ads GOOGLE:
            https://adssettings.google.com/authenticated?hl=es BING:
            https://about.ads.microsoft.com/es-es/recursos/directivas/anuncios-personalizados
            ]]
          </Typography>

          <Typography>
            Además, puede darse de baja de algunos de estos servicios visitando
            el portal de exclusión voluntaria de Digital Advertising Alliance
            en: ttp://optout.aboutads.info/.
          </Typography>

          <Typography>NO RASTREAR</Typography>

          <Typography>
            Tenga en cuenta que no alteramos las prácticas de recopilación y uso
            de datos de nuestro Sitio cuando vemos una señal de No rastrear
            desde su navegador.
          </Typography>

          <Typography>
            [[INCLUIR SI LA TIENDA ESTÁ UBICADA EN EUROPA O SI TIENE CLIENTES EN
            EUROPA]] SUS DERECHOS Si usted es un residente europeo, tiene
            derecho a acceder a la información personal que tenemos sobre usted
            y a solicitar que su información personal sea corregida, actualizada
            o eliminada. Si desea ejercer este derecho, comuníquese con nosotros
            a través de la información de contacto que se encuentra a
            continuación. Además, si usted es un residente europeo, tenemos en
            cuenta que estamos procesando su información para cumplir con los
            contratos que podamos tener con usted (por ejemplo, si realiza un
            pedido a través del Sitio) o para perseguir nuestros intereses
            comerciales legítimos enumerados anteriormente. Además, tenga en
            cuenta que su información se transferirá fuera de Europa, incluidos
            Canadá y los Estados Unidos.
          </Typography>

          <Typography>RETENCIÓN DE DATOS</Typography>

          <Typography>
            Cuando realiza un pedido a través del Sitio, mantendremos su
            Información del pedido para nuestros registros a menos que y hasta
            que nos pida que eliminemos esta información.
          </Typography>

          <Typography>
            [[INSERTAR SI SE REQUIERE RESTRICCIÓN DE EDAD]] MENORES
          </Typography>

          <Typography>
            El sitio no está destinado a personas menores de [[INSERTAR EDAD]].
          </Typography>

          <Typography>CAMBIOS</Typography>

          <Typography>
            Podemos actualizar esta política de privacidad periódicamente para
            reflejar, por ejemplo, cambios en nuestras prácticas o por otros
            motivos operativos, legales o reglamentarios.
          </Typography>

          <Typography>CONTÁCTENOS</Typography>

          <Typography>
            Para obtener más información sobre nuestras prácticas de privacidad,
            si tiene alguna pregunta o si desea presentar una queja, contáctenos
            por correo electrónico a famagastrobar@gmail.com o por correo
            mediante el uso de la información que se proporciona a continuación:
          </Typography>

          <Typography>
            3815 W Vine St, Kissimmee, FL 34741, EE. UU., Orlando, FL, 34741,
            Estados Unidos
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ConditionService;
