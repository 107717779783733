import React from "react";
import { Box, Typography } from "@mui/material";

// IMPORTADO
import { useLocation } from "react-router-dom";
import CardList from "./CardList";

const SeccionCardList = ({ id, img, title = "Titulo", api = [] }) => {
  const { pathname } = useLocation();

  return (
    // CONTENEDOR SECCION
    <Box className="contentCardList" id={id}>
      {/***********************
        BANNER DE LA SECCION
      ***********************/}
      <Box className="bannerCardList" sx={{ backgroundImage: `url(${img})` }}>
        {/******************
          FONDO DEGRADADO
        ******************/}
        <Box
          className="degBannerCardList"
          sx={{
            backgroundColor:
              pathname === "/foods"
                ? "rgba(205, 100, 64, 0.50) !important"
                : "rgba(205, 100, 64, 0.50)" && pathname === "/drinks"
                ? "rgba(175, 73, 215, 0.50) !important"
                : "rgba(175, 73, 215, 0.50)" && pathname === "/night"
                ? "rgba(248, 210, 154, 0.35) !important"
                : "rgba(205, 100, 69, 0.5)",
          }}
        >
          {/**********
            TITULO
          **********/}
          <Typography>{title}</Typography>

          {/*********
            RAYITA
          *********/}
          <span></span>
        </Box>
      </Box>

      {/***************************
        CONTENEDOR DE LAS CARTAS
      ***************************/}
      <Box className="containerCardLists">
        {/***************************
          CONTENIDO DE LAS CARTAS
        ***************************/}
        {api.map((apiSeccion) => (
          <CardList
            slogan={apiSeccion.slogan}
            titleCard={apiSeccion.titleCard}
            description={apiSeccion.description}
            valor={apiSeccion.valor}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SeccionCardList;
