import React from "react";
import { Box, Typography } from "@mui/material";

const CardList = ({
  id,
  slogan,
  titleCard,
  description,
  valor,
}) => {
  // const { pathname } = useLocation();

  return (
    // CONTENIDO DE LAS CARTAS
    <Box
      className="contentListDesc"
    >
      {/***********************
        CONTENIDO DEL TEXTO
      ***********************/}
      <Box className="contentText">
        {/**************
          TITLE TEXTO
        ***************/}
        <Box >
          <Typography className="slogan">{slogan}</Typography>
          <Typography className="title">{titleCard}</Typography>
        </Box>

        {/* VALOR */}
        <span>${valor}</span>
      </Box>

        {/* DESCRIPCION */}
        <Typography className="desc">{description}</Typography>
    </Box>
  );
};

export default CardList;
