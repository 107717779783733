import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

// IMPORTADOS
import Header from "./header/Header";
import Footer from "./footer/Footer";
import FloatButton from "../buttons/FloatButton";

const Layout = () => {
  return (
    <Box>
      <Header />
      <Outlet />
      <FloatButton />
      <Footer />
    </Box>
  );
};

export default Layout;
