import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

// IMPORTADOS
import "../Menu.scss";
import "./Event.scss";
import FamaLogo from "../../../images/logos/FamaLogo";
import imgLugarPerfecto from "../../../images/eventos/lugarPerfecto.png";
import Structure from "../../../images/Estructura.png";
import txCho from "../../../images/texturaChocolate6.png";
import { useTranslation } from "react-i18next";

const Events = () => {
  const [t] = useTranslation("menu");

  // EFECTO NAVBAR
  const [showEffect, setShowEffect] = useState(false);
  const [showEffect2, setShowEffect2] = useState(false);

  const controlNavBar = () => {
    if (window.scrollY > 50) {
      setShowEffect2(true);
    } else {
      setShowEffect2(false);
    }
    if (window.scrollY > 80) {
      setShowEffect(true);
    } else {
      setShowEffect(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavBar);
    return () => {
      window.removeEventListener("scroll", controlNavBar);
    };
  }, []);

  return (
    <Box className="containerFood">
      {/*************
        FONDO IMAGEN
      *************/}
      <Box
        id="up"
        className="backgroundImage"
        sx={{
          backgroundImage: `url(${txCho})`,
          height: "115vh",

          // RESPONSIVE - ALTO
          "@media (max-height: 700px)": {
            "&": {
              height: "125vh",
            },
          },
        }}
      >
        {/*************
          DEGRADADO
        *************/}
        <Box className="degradado">
          <Box
            // className="event"
            className={`event ${showEffect && "efectoArco"}`}
            id="doorScrolling"
            sx={{
              transition:
                showEffect2 === true
                  ? "all 1000ms ease-in-out"
                  : "all 2000ms ease-in-out",
              height: showEffect2 === true ? "100%" : "86%",
            }}
          >
            <Box
              className={`efectoSombra ${showEffect && "efectoSombraScroll"}`}
            >
              <FamaLogo width={253.41} height={180} color="#F8D29A" />

              <Typography component={"span"}>
                {t("events.doorScrolling.pretitle")}
              </Typography>

              <Typography
                sx={{ textTransform: "uppercase", color: "#F8D29A !important" }}
              >
                {t("events.doorScrolling.title")}
              </Typography>

              <Typography component={"span"}>
                {t("events.doorScrolling.subtitle")}
              </Typography>

              {/****************
                BOTON RESERVA
              ****************/}
              <Button
                href="https://www.toasttab.com/fama-restaurant-new-3815-w-vine-street"
                disableTouchRipple
                sx={{
                  ":hover": {
                    backgroundColor: "#F8D29A",
                    color: "#804C33 !important",
                    border: "0px solid transparent !important",
                    transition: "all ease-in-out 0.6s",
                  },
                }}
              >
                {t("events.doorScrolling.button")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/****************
        LUGAR PERFECTO
      ****************/}
      <Box
        className="contentLugarPerfecto"
        sx={{
          backgroundImage: `url(${imgLugarPerfecto})`,
        }}
      >
        <Box className="filterPurple">
          <Box className="sombraDegradado">
            <Box className="contentText">
              <Typography className="subtitle">
                {t("events.perfectPlace.pretitle")}
              </Typography>

              <Typography className="title">
                {t("events.perfectPlace.title")}
              </Typography>
            </Box>

            {/****************
              BOTON RESERVA
            ****************/}
            <Button
              href="https://www.toasttab.com/fama-restaurant-new-3815-w-vine-street"
              target="_blank"
              disableTouchRipple
              sx={{
                ":hover": {
                  backgroundColor: "#E6CAF0",
                  color: "#6D228B !important",
                  border: "0px solid transparent !important",
                  transition: "all ease-in-out 0.6s",
                },
              }}
            >
              {t("events.perfectPlace.button")}
            </Button>
          </Box>
        </Box>
      </Box>

      {/**************
        BUENA FAMA
      **************/}
      <Box className="buenaFama">
        <Box className="contentFama">
          {/* IMAGEN */}
          <Box className="imgReservarFama"></Box>

          {/* TITULO */}
          <Typography className="title">
            {t("events.structure.title")}
          </Typography>

          {/* TEXTO */}
          <Typography className="parrafo">
            {t("events.structure.paragraph")}
          </Typography>

          {/****************
            BOTON RESERVA
          ****************/}
          <Button
            href="https://www.toasttab.com/fama-restaurant-new-3815-w-vine-street"
            target="_blank"
            disableTouchRipple
            sx={{
              ":hover": {
                backgroundColor: "#C29AD1",
                color: "#6D228B !important",
                border: "0px solid transparent !important",
                transition: "all ease-in-out 0.6s",
              },
            }}
          >
            {t("events.structure.button")}
          </Button>
        </Box>
      </Box>

      <Box className="containerStructure">
        <img src={Structure} alt="" className="imgStructure" loading="lazy" />

        <Box className="vip">
          <Box>
            <Box className="vip1"></Box>

            <Typography>
              {t("events.structure.openVip1")}
              <br />
              {t("events.structure.openVip2")}
            </Typography>
          </Box>

          <Box>
            <Box className="vip2"></Box>

            <Typography>
              {t("events.structure.privateVip1")}
              <br />
              {t("events.structure.privateVip2")}
            </Typography>
          </Box>
        </Box>

        {/****************
          BOTON RESERVA
        ****************/}
        <Button
          disableTouchRipple
          // href="https://wa.me/14077504070?text=Bienvenido%20a%20Fama%20-%20Gastrobar"
          href="https://wa.me/14074584631?text=Bienvenido%20a%20Fama%20-%20Gastrobar"
          target="_blank"
          sx={{
            ":hover": {
              backgroundColor: "#C29AD1",
              color: "#6D228B !important",
              border: "0px solid transparent !important",
              transition: "all ease-in-out 0.6s",
            },
          }}
        >
          {t("events.structure.buttonWsp")}
        </Button>
      </Box>
    </Box>
  );
};

export default Events;
