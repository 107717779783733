import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const List = ({ id, titleList, valor }) => {
  const { pathname } = useLocation();

  return (
    // CONTENIDO DE LAS CARTAS
    <Box className="contentList">
      {/**************
        TITLE TEXTO
      ***************/}
      <Box
        className="list"
        sx={{
          ">.titleList, >span": {
            color: pathname === "/drinks" && "#6D228B !important",
          },
          ">.borderSpace": {
            border: pathname === "/drinks" && "2px dashed #6D228B",
          },
          ">span": {
            border: pathname === "/drinks" && "2px solid #6D228B !important",
          },

          "@media (max-width: 420px)": {
            ">p": {
              whiteSpace: pathname === "/foods" ? "pre-wrap !important" : "pre",
            },
          },
        }}
      >
        <Typography className="titleList">{titleList}</Typography>

        <Typography className="borderSpace"></Typography>

        <span>${valor}</span>
      </Box>
    </Box>
  );
};

export default List;
