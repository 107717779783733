import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// IMPORTADOS
import "./BannerButtons.scss";
import IconChef from "../../../components/icons/IconChef";
import IconCocktail from "../../../components/icons/IconCocktail";
import IconPrivateEvents from "../../../components/icons/IconPrivateEvents";
import IconMusic from "../../../components/icons/IconMusic";
import IconGastronomy from "../../../components/icons/IconGastronomy";

const BannerButtons = () => {
  // const [globalMousePos, setGlobalMousePos] = useState({});
  // const [localMousePos, setLocalMousePos] = useState({});

  // const handleMouseMove = (event) => {
  //   // 👇 Get mouse position relative to element
  //   const localX = event.clientX - event.target.offsetLeft;
  //   const localY = event.clientY - event.target.offsetTop;

  //   setLocalMousePos({ x: localX, y: localY });
  // };

  // useEffect(() => {
  //   const handleMouseMove = (event) => {
  //     setGlobalMousePos({
  //       x: event.clientX,
  //       y: event.clientY,
  //     });
  //   };

  //   window.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  // FUNCIONAL PERO DEMORA
  // useEffect(() => {
  //   document.querySelectorAll(".container").forEach((elm) =>
  //     elm.addEventListener("mousemove", function (e) {
  //       console.log("123");
  //       const cur = e.currentTarget;
  //       const banner = cur.querySelector(".banner");
  //       var xPosition = e.clientX - cur.getBoundingClientRect().left;
  //       var yPosition = e.clientY - cur.getBoundingClientRect().top;
  //       banner.style.setProperty("--x", xPosition + "px");
  //       banner.style.setProperty("--y", yPosition + "px");
  //     })
  //   );
  // });

  const [t] = useTranslation("home");

  return (
    <Box className="containerBannerButtons">
      <Box className="contentBannerButtons">
        <Box
          sx={{
            transition: "all 200ms ease-in-out",
            ":hover": {
              marginTop: "-30px",
              transition: "all 200ms ease-in-out",

              ">svg": {
                ">path": {
                  fill: "#FFF7EB",
                },
              },
              ">p": {
                color: "#FFF7EB",
                textShadow: "0px 0px 10px #C29AD1",
              },
            },
          }}
        >
          {/*************
            ICONO CHEF
          *************/}
          <IconChef />

          <Typography>{t("buttonsBanner.chef")}</Typography>
        </Box>

        <Box
          sx={{
            transition: "all 200ms ease-in-out",
            ":hover": {
              marginTop: "-30px",
              transition: "all 200ms ease-in-out",

              ">svg": {
                ">path": {
                  fill: "#FFF7EB",
                },
              },
              ">p": {
                color: "#FFF7EB",
                textShadow: "0px 0px 10px #C29AD1",
              },
            },
          }}
        >
          {/*************
            ICONO COCTEL
          *************/}
          <IconCocktail />

          <Typography>{t("buttonsBanner.cocktails")}</Typography>
        </Box>

        <Box
          sx={{
            transition: "all 200ms ease-in-out",
            ":hover": {
              marginTop: "-30px",
              transition: "all 200ms ease-in-out",

              ">svg": {
                ">path": {
                  fill: "#FFF7EB",
                },
              },
              ">p": {
                color: "#FFF7EB",
                textShadow: "0px 0px 10px #C29AD1",
              },
            },
          }}
        >
          {/************************
            ICONO EVENTOS PRIVADOS
          ************************/}
          <IconPrivateEvents />

          <Typography>{t("buttonsBanner.privateEvents")}</Typography>
        </Box>

        <Box
          sx={{
            transition: "all 200ms ease-in-out",
            ":hover": {
              marginTop: "-30px",
              transition: "all 200ms ease-in-out",

              ">svg": {
                ">path": {
                  fill: "#FFF7EB",
                },
              },
              ">p": {
                color: "#FFF7EB",
                textShadow: "0px 0px 10px #C29AD1",
              },
            },
          }}
        >
          {/***************
            ICONO MUSICA
          *****************/}
          <IconMusic />

          <Typography>{t("buttonsBanner.liveMusic")}</Typography>
        </Box>

        <Box
          sx={{
            transition: "all 200ms ease-in-out",
            ":hover": {
              marginTop: "-30px",
              transition: "all 200ms ease-in-out",

              ">svg": {
                ">path": {
                  fill: "#FFF7EB",
                },
              },
              ">p": {
                color: "#FFF7EB",
                textShadow: "0px 0px 10px #C29AD1",
              },
            },
          }}
        >
          {/****************************
            ICONO GASTRONOMIA CARIBEÑA
          *****************************/}
          <IconGastronomy />

          <Typography>{t("buttonsBanner.caribbeanGastronomy")}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BannerButtons;
