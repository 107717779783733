import React from "react";

const IconPoint = ({ width = 10, height = 10, color = "#CD6440" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5" r="5" fill={color} />
    </svg>
  );
};

export default IconPoint;
