import React from "react";

const IconBorder = ({ width = 575, height = 399, color = "#F8D29A" }) => {
  return (
    <svg
      className="iconBorder"
      width={width}
      height={height}
      viewBox="0 0 575 399"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M574.537 11.5358C572.701 11.5492 569.858 11.5447 568.022 11.5358C567.64 11.5358 567.258 11.5492 566.88 11.6161C564.648 11.9911 563.452 13.893 564.154 16.0493C564.734 15.3752 565.422 14.9689 566.245 14.7903C566.74 14.6832 567.24 14.6966 567.744 14.6832C568.931 14.6564 571.131 14.7367 572.314 14.5939C573.623 14.4332 574.811 13.1876 574.906 11.942C574.928 11.6384 574.861 11.5358 574.532 11.5358H574.537Z"
        fill={color}
      />
      <path
        d="M567.15 18.7013C567.15 17.7995 567.15 16.8977 567.15 15.9914C567.15 15.5405 567.128 15.5271 566.673 15.603C565.117 15.8575 563.974 17.1834 563.996 18.7281C564.019 20.2995 565.193 21.6121 566.763 21.8264C567.056 21.8665 567.164 21.7906 567.159 21.4871C567.146 20.5585 567.155 19.6299 567.155 18.7013H567.15Z"
        fill={color}
      />
      <path
        d="M570.817 15.5672C570.407 15.5806 569.998 15.5672 569.593 15.5672C569.17 15.5672 568.747 15.5761 568.329 15.5672C568.118 15.5627 568.019 15.6208 568.019 15.8485C568.028 16.7101 568.028 17.5762 568.019 18.4378C568.019 18.6566 568.113 18.6923 568.311 18.6789C569.751 18.5717 571.006 17.3217 571.132 15.8931C571.159 15.6118 571.064 15.5538 570.817 15.5627V15.5672Z"
        fill={color}
      />
      <path
        d="M559.52 11.6161C559.142 11.5536 558.764 11.5358 558.377 11.5358C556.541 11.5447 553.698 11.5492 551.863 11.5358C551.534 11.5358 551.467 11.6384 551.489 11.942C551.584 13.1876 552.771 14.4376 554.081 14.5939C555.264 14.7367 557.464 14.6564 558.651 14.6832C559.151 14.6966 559.655 14.6832 560.149 14.7903C560.973 14.9689 561.666 15.3752 562.241 16.0493C562.943 13.893 561.751 11.9911 559.515 11.6161H559.52Z"
        fill={color}
      />
      <path
        d="M559.731 15.6029C559.276 15.527 559.254 15.5448 559.254 15.9913C559.254 16.8931 559.254 17.7949 559.254 18.7012C559.254 19.6298 559.263 20.5584 559.249 21.4869C559.245 21.795 559.353 21.8709 559.645 21.8262C561.215 21.6075 562.389 20.2994 562.412 18.7279C562.43 17.1833 561.292 15.8529 559.735 15.6029H559.731Z"
        fill={color}
      />
      <path
        d="M558.076 15.5673C557.653 15.5807 557.235 15.5673 556.812 15.5673C556.402 15.5673 555.993 15.5762 555.588 15.5673C555.336 15.5584 555.246 15.612 555.273 15.8977C555.399 17.3263 556.659 18.5719 558.094 18.6835C558.292 18.6969 558.386 18.6611 558.386 18.4424C558.382 17.5808 558.377 16.7147 558.386 15.853C558.386 15.6253 558.292 15.5628 558.076 15.5718V15.5673Z"
        fill={color}
      />
      <path
        d="M560.149 7.21416C559.655 7.32131 559.155 7.30791 558.651 7.32131C557.464 7.34809 555.264 7.26773 554.081 7.41059C552.771 7.57131 551.584 8.81688 551.489 10.0625C551.467 10.366 551.534 10.4687 551.863 10.4687C553.698 10.4553 556.541 10.4598 558.377 10.4687C558.759 10.4687 559.142 10.4553 559.52 10.3884C561.751 10.0133 562.948 8.11151 562.246 5.9552C561.666 6.62932 560.977 7.03559 560.154 7.21416H560.149Z"
        fill={color}
      />
      <path
        d="M559.254 3.30358C559.254 4.20538 559.254 5.10719 559.254 6.01347C559.254 6.46437 559.277 6.47777 559.731 6.40187C561.288 6.1474 562.43 4.82147 562.408 3.27679C562.385 1.70532 561.211 0.392785 559.641 0.178494C559.349 0.138314 559.241 0.214209 559.245 0.517788C559.259 1.44638 559.25 2.37498 559.25 3.30358H559.254Z"
        fill={color}
      />
      <path
        d="M555.588 6.43752C555.997 6.42413 556.407 6.43752 556.812 6.43752C557.234 6.43752 557.657 6.42859 558.076 6.43752C558.287 6.44199 558.386 6.38395 558.386 6.15627C558.377 5.29464 558.377 4.42854 558.386 3.56691C558.386 3.34816 558.292 3.31244 558.094 3.32583C556.654 3.43298 555.399 4.68301 555.273 6.11162C555.246 6.39288 555.34 6.45092 555.588 6.44199V6.43752Z"
        fill={color}
      />
      <path
        d="M566.88 10.3884C567.258 10.4509 567.635 10.4687 568.022 10.4687C569.858 10.4598 572.701 10.4553 574.537 10.4687C574.865 10.4687 574.932 10.366 574.91 10.0625C574.815 8.81688 573.628 7.56685 572.319 7.41059C571.135 7.26773 568.936 7.34809 567.748 7.32131C567.249 7.30791 566.745 7.32131 566.25 7.21416C565.427 7.03559 564.734 6.62932 564.158 5.9552C563.456 8.11151 564.648 10.0133 566.884 10.3884H566.88Z"
        fill={color}
      />
      <path
        d="M566.668 6.40177C567.122 6.47767 567.145 6.45981 567.145 6.01337C567.145 5.11156 567.145 4.20975 567.145 3.30348C567.145 2.37488 567.136 1.44629 567.149 0.517691C567.154 0.209647 567.046 0.133752 566.753 0.178396C565.183 0.397152 564.009 1.70522 563.987 3.27669C563.969 4.82138 565.107 6.15177 566.663 6.40177H566.668Z"
        fill={color}
      />
      <path
        d="M568.324 6.43747C568.747 6.42408 569.165 6.43747 569.588 6.43747C569.998 6.43747 570.407 6.42854 570.812 6.43747C571.064 6.4464 571.154 6.39283 571.127 6.1071C571.001 4.6785 569.741 3.43293 568.306 3.32132C568.108 3.30792 568.014 3.34364 568.014 3.5624C568.018 4.42402 568.023 5.29012 568.014 6.15175C568.014 6.37943 568.108 6.44193 568.324 6.43301V6.43747Z"
        fill={color}
      />
      <path
        d="M574.537 388.15C572.701 388.164 569.858 388.159 568.022 388.15C567.64 388.15 567.258 388.164 566.88 388.231C564.648 388.606 563.452 390.508 564.154 392.664C564.734 391.99 565.422 391.584 566.245 391.405C566.74 391.298 567.24 391.311 567.744 391.298C568.931 391.271 571.131 391.351 572.314 391.209C573.623 391.048 574.811 389.802 574.906 388.557C574.928 388.253 574.861 388.15 574.532 388.15H574.537Z"
        fill={color}
      />
      <path
        d="M567.15 395.316C567.15 394.414 567.15 393.512 567.15 392.606C567.15 392.155 567.128 392.142 566.673 392.217C565.117 392.472 563.974 393.798 563.996 395.343C564.019 396.914 565.193 398.227 566.763 398.441C567.056 398.481 567.164 398.405 567.159 398.102C567.146 397.173 567.155 396.244 567.155 395.316H567.15Z"
        fill={color}
      />
      <path
        d="M570.817 392.182C570.407 392.195 569.998 392.182 569.593 392.182C569.17 392.182 568.747 392.191 568.329 392.182C568.118 392.177 568.019 392.235 568.019 392.463C568.028 393.324 568.028 394.191 568.019 395.052C568.019 395.271 568.113 395.307 568.311 395.293C569.751 395.186 571.006 393.936 571.132 392.507C571.159 392.226 571.064 392.168 570.817 392.177V392.182Z"
        fill={color}
      />
      <path
        d="M559.52 388.231C559.142 388.168 558.764 388.15 558.377 388.15C556.541 388.159 553.698 388.164 551.863 388.15C551.534 388.15 551.467 388.253 551.489 388.557C551.584 389.802 552.771 391.052 554.081 391.209C555.264 391.351 557.464 391.271 558.651 391.298C559.151 391.311 559.655 391.298 560.149 391.405C560.973 391.584 561.666 391.99 562.241 392.664C562.943 390.508 561.751 388.606 559.515 388.231H559.52Z"
        fill={color}
      />
      <path
        d="M559.731 392.217C559.276 392.142 559.254 392.159 559.254 392.606C559.254 393.508 559.254 394.41 559.254 395.316C559.254 396.244 559.263 397.173 559.249 398.102C559.245 398.41 559.353 398.486 559.645 398.441C561.215 398.222 562.389 396.914 562.412 395.343C562.43 393.798 561.292 392.467 559.735 392.217H559.731Z"
        fill={color}
      />
      <path
        d="M558.076 392.182C557.653 392.195 557.235 392.182 556.812 392.182C556.402 392.182 555.993 392.191 555.588 392.182C555.336 392.173 555.246 392.226 555.273 392.512C555.399 393.941 556.659 395.186 558.094 395.298C558.292 395.311 558.386 395.276 558.386 395.057C558.382 394.195 558.377 393.329 558.386 392.468C558.386 392.24 558.292 392.177 558.076 392.186V392.182Z"
        fill={color}
      />
      <path
        d="M560.149 383.829C559.655 383.936 559.155 383.923 558.651 383.936C557.464 383.963 555.264 383.882 554.081 384.025C552.771 384.186 551.584 385.432 551.489 386.677C551.467 386.981 551.534 387.083 551.863 387.083C553.698 387.07 556.541 387.074 558.377 387.083C558.759 387.083 559.142 387.07 559.52 387.003C561.751 386.628 562.948 384.726 562.246 382.57C561.666 383.244 560.977 383.65 560.154 383.829H560.149Z"
        fill={color}
      />
      <path
        d="M559.254 379.918C559.254 380.82 559.254 381.722 559.254 382.628C559.254 383.079 559.277 383.092 559.731 383.016C561.288 382.762 562.43 381.436 562.408 379.891C562.385 378.32 561.211 377.007 559.641 376.793C559.349 376.753 559.241 376.829 559.245 377.132C559.259 378.061 559.25 378.989 559.25 379.918H559.254Z"
        fill={color}
      />
      <path
        d="M555.588 383.052C555.997 383.039 556.407 383.052 556.812 383.052C557.234 383.052 557.657 383.043 558.076 383.052C558.287 383.056 558.386 382.998 558.386 382.771C558.377 381.909 558.377 381.043 558.386 380.181C558.386 379.963 558.292 379.927 558.094 379.94C556.654 380.047 555.399 381.297 555.273 382.726C555.246 383.007 555.34 383.065 555.588 383.056V383.052Z"
        fill={color}
      />
      <path
        d="M566.88 387.003C567.258 387.065 567.635 387.083 568.022 387.083C569.858 387.074 572.701 387.07 574.537 387.083C574.865 387.083 574.932 386.981 574.91 386.677C574.815 385.432 573.628 384.181 572.319 384.025C571.135 383.882 568.936 383.963 567.748 383.936C567.249 383.923 566.745 383.936 566.25 383.829C565.427 383.65 564.734 383.244 564.158 382.57C563.456 384.726 564.648 386.628 566.884 387.003H566.88Z"
        fill={color}
      />
      <path
        d="M566.668 383.016C567.122 383.092 567.145 383.074 567.145 382.628C567.145 381.726 567.145 380.824 567.145 379.918C567.145 378.989 567.136 378.061 567.149 377.132C567.154 376.824 567.046 376.748 566.753 376.793C565.183 377.012 564.009 378.32 563.987 379.891C563.969 381.436 565.107 382.766 566.663 383.016H566.668Z"
        fill={color}
      />
      <path
        d="M568.324 383.052C568.747 383.039 569.165 383.052 569.588 383.052C569.998 383.052 570.407 383.043 570.812 383.052C571.064 383.061 571.154 383.007 571.127 382.722C571.001 381.293 569.741 380.047 568.306 379.936C568.108 379.922 568.014 379.958 568.014 380.177C568.018 381.039 568.023 381.905 568.014 382.766C568.014 382.994 568.108 383.056 568.324 383.048V383.052Z"
        fill={color}
      />
      <path
        d="M23.9655 11.5358C22.1299 11.5492 19.2867 11.5447 17.4512 11.5358C17.0688 11.5358 16.6864 11.5492 16.3085 11.6161C14.0771 11.9911 12.8804 13.893 13.5822 16.0493C14.1626 15.3752 14.8509 14.9689 15.6742 14.7903C16.169 14.6832 16.6684 14.6966 17.1723 14.6832C18.36 14.6564 20.5599 14.7367 21.7431 14.5939C23.0522 14.4332 24.2399 13.1876 24.3344 11.942C24.3569 11.6384 24.2894 11.5358 23.961 11.5358H23.9655Z"
        fill={color}
      />
      <path
        d="M16.5788 18.7013C16.5788 17.7995 16.5788 16.8977 16.5788 15.9914C16.5788 15.5405 16.5563 15.5271 16.1019 15.603C14.5453 15.8575 13.4026 17.1834 13.4251 18.7281C13.4476 20.2995 14.6218 21.6121 16.1919 21.8264C16.4843 21.8665 16.5923 21.7906 16.5878 21.4871C16.5743 20.5585 16.5833 19.6299 16.5833 18.7013H16.5788Z"
        fill={color}
      />
      <path
        d="M20.2455 15.5672C19.8361 15.5806 19.4267 15.5672 19.0218 15.5672C18.599 15.5672 18.1761 15.5761 17.7577 15.5672C17.5462 15.5627 17.4473 15.6208 17.4473 15.8485C17.4563 16.7101 17.4563 17.5762 17.4473 18.4378C17.4473 18.6566 17.5417 18.6923 17.7397 18.6789C19.1793 18.5717 20.4345 17.3217 20.5604 15.8931C20.5874 15.6118 20.4929 15.5538 20.2455 15.5627V15.5672Z"
        fill={color}
      />
      <path
        d="M8.94834 11.6161C8.57044 11.5536 8.19254 11.5358 7.80564 11.5358C5.97014 11.5447 3.1269 11.5492 1.29139 11.5358C0.96298 11.5358 0.895501 11.6384 0.917995 11.942C1.01247 13.1876 2.20015 14.4376 3.5093 14.5939C4.69248 14.7367 6.89239 14.6564 8.08007 14.6832C8.57944 14.6966 9.0833 14.6832 9.57817 14.7903C10.4014 14.9689 11.0943 15.3752 11.6701 16.0493C12.3719 13.893 11.1797 11.9911 8.94384 11.6161H8.94834Z"
        fill={color}
      />
      <path
        d="M9.15924 15.6029C8.70486 15.527 8.68237 15.5448 8.68237 15.9913C8.68237 16.8931 8.68237 17.7949 8.68237 18.7012C8.68237 19.6298 8.69137 20.5584 8.67787 21.4869C8.67337 21.795 8.78134 21.8709 9.07376 21.8262C10.6438 21.6075 11.818 20.2994 11.8405 18.7279C11.8585 17.1833 10.7203 15.8529 9.16374 15.6029H9.15924Z"
        fill={color}
      />
      <path
        d="M7.50462 15.5673C7.08173 15.5807 6.66334 15.5673 6.24046 15.5673C5.83107 15.5673 5.42168 15.5762 5.01678 15.5673C4.76485 15.5584 4.67488 15.612 4.70187 15.8977C4.82784 17.3263 6.0875 18.5719 7.52261 18.6835C7.72056 18.6969 7.81503 18.6611 7.81503 18.4424C7.81053 17.5808 7.80604 16.7147 7.81503 15.853C7.81503 15.6253 7.72056 15.5628 7.50462 15.5718V15.5673Z"
        fill={color}
      />
      <path
        d="M9.57817 7.21416C9.0833 7.32131 8.58393 7.30791 8.08007 7.32131C6.89239 7.34809 4.69248 7.26773 3.5093 7.41059C2.20015 7.57131 1.01247 8.81688 0.917995 10.0625C0.895501 10.366 0.96298 10.4687 1.29139 10.4687C3.1269 10.4553 5.97014 10.4598 7.80564 10.4687C8.18804 10.4687 8.57044 10.4553 8.94834 10.3884C11.1797 10.0133 12.3764 8.11151 11.6746 5.9552C11.0943 6.62932 10.4059 7.03559 9.58266 7.21416H9.57817Z"
        fill={color}
      />
      <path
        d="M8.68296 3.30358C8.68296 4.20538 8.68296 5.10719 8.68296 6.01347C8.68296 6.46437 8.70545 6.47777 9.15983 6.40187C10.7164 6.1474 11.8591 4.82147 11.8366 3.27679C11.8141 1.70532 10.6399 0.392785 9.06985 0.178494C8.77743 0.138314 8.66946 0.214209 8.67396 0.517788C8.68746 1.44638 8.67846 2.37498 8.67846 3.30358H8.68296Z"
        fill={color}
      />
      <path
        d="M5.01664 6.43752C5.42603 6.42413 5.83542 6.43752 6.24031 6.43752C6.66319 6.43752 7.08608 6.42859 7.50447 6.43752C7.71591 6.44199 7.81489 6.38395 7.81489 6.15627C7.80589 5.29464 7.80589 4.42854 7.81489 3.56691C7.81489 3.34816 7.72041 3.31244 7.52247 3.32583C6.08285 3.43298 4.82769 4.68301 4.70172 6.11162C4.67473 6.39288 4.7692 6.45092 5.01664 6.44199V6.43752Z"
        fill={color}
      />
      <path
        d="M16.3083 10.3884C16.6862 10.4509 17.0641 10.4687 17.451 10.4687C19.2865 10.4598 22.1298 10.4553 23.9653 10.4687C24.2937 10.4687 24.3612 10.366 24.3387 10.0625C24.2442 8.81688 23.0565 7.56685 21.7474 7.41059C20.5642 7.26773 18.3643 7.34809 17.1766 7.32131C16.6772 7.30791 16.1734 7.32131 15.6785 7.21416C14.8552 7.03559 14.1624 6.62932 13.5866 5.9552C12.8847 8.11151 14.0769 10.0133 16.3128 10.3884H16.3083Z"
        fill={color}
      />
      <path
        d="M16.0965 6.40177C16.5509 6.47767 16.5734 6.45981 16.5734 6.01337C16.5734 5.11156 16.5734 4.20975 16.5734 3.30348C16.5734 2.37488 16.5644 1.44629 16.5779 0.517691C16.5824 0.209647 16.4744 0.133752 16.182 0.178396C14.6119 0.397152 13.4377 1.70522 13.4152 3.27669C13.3973 4.82138 14.5354 6.15177 16.092 6.40177H16.0965Z"
        fill={color}
      />
      <path
        d="M17.7528 6.43747C18.1757 6.42408 18.5941 6.43747 19.017 6.43747C19.4263 6.43747 19.8357 6.42854 20.2406 6.43747C20.4926 6.4464 20.5825 6.39283 20.5555 6.1071C20.4296 4.6785 19.1699 3.43293 17.7348 3.32132C17.5369 3.30792 17.4424 3.34364 17.4424 3.5624C17.4469 4.42402 17.4514 5.29012 17.4424 6.15175C17.4424 6.37943 17.5369 6.44193 17.7528 6.43301V6.43747Z"
        fill={color}
      />
      <path
        d="M23.9655 388.15C22.1299 388.164 19.2867 388.159 17.4512 388.15C17.0688 388.15 16.6864 388.164 16.3085 388.231C14.0771 388.606 12.8804 390.508 13.5822 392.664C14.1626 391.99 14.8509 391.584 15.6742 391.405C16.169 391.298 16.6684 391.311 17.1723 391.298C18.36 391.271 20.5599 391.351 21.7431 391.209C23.0522 391.048 24.2399 389.802 24.3344 388.557C24.3569 388.253 24.2894 388.15 23.961 388.15H23.9655Z"
        fill={color}
      />
      <path
        d="M16.5788 395.316C16.5788 394.414 16.5788 393.512 16.5788 392.606C16.5788 392.155 16.5563 392.142 16.1019 392.217C14.5453 392.472 13.4026 393.798 13.4251 395.343C13.4476 396.914 14.6218 398.227 16.1919 398.441C16.4843 398.481 16.5923 398.405 16.5878 398.102C16.5743 397.173 16.5833 396.244 16.5833 395.316H16.5788Z"
        fill={color}
      />
      <path
        d="M20.2455 392.182C19.8361 392.195 19.4267 392.182 19.0218 392.182C18.599 392.182 18.1761 392.191 17.7577 392.182C17.5462 392.177 17.4473 392.235 17.4473 392.463C17.4563 393.324 17.4563 394.191 17.4473 395.052C17.4473 395.271 17.5417 395.307 17.7397 395.293C19.1793 395.186 20.4345 393.936 20.5604 392.507C20.5874 392.226 20.4929 392.168 20.2455 392.177V392.182Z"
        fill={color}
      />
      <path
        d="M8.94834 388.231C8.57044 388.168 8.19254 388.15 7.80564 388.15C5.97014 388.159 3.1269 388.164 1.29139 388.15C0.96298 388.15 0.895501 388.253 0.917995 388.557C1.01247 389.802 2.20015 391.052 3.5093 391.209C4.69248 391.351 6.89239 391.271 8.08007 391.298C8.57944 391.311 9.0833 391.298 9.57817 391.405C10.4014 391.584 11.0943 391.99 11.6701 392.664C12.3719 390.508 11.1797 388.606 8.94384 388.231H8.94834Z"
        fill={color}
      />
      <path
        d="M9.15924 392.217C8.70486 392.142 8.68237 392.159 8.68237 392.606C8.68237 393.508 8.68237 394.41 8.68237 395.316C8.68237 396.244 8.69137 397.173 8.67787 398.102C8.67337 398.41 8.78134 398.486 9.07376 398.441C10.6438 398.222 11.818 396.914 11.8405 395.343C11.8585 393.798 10.7203 392.467 9.16374 392.217H9.15924Z"
        fill={color}
      />
      <path
        d="M7.50462 392.182C7.08173 392.195 6.66334 392.182 6.24046 392.182C5.83107 392.182 5.42168 392.191 5.01678 392.182C4.76485 392.173 4.67488 392.226 4.70187 392.512C4.82784 393.941 6.0875 395.186 7.52261 395.298C7.72056 395.311 7.81503 395.276 7.81503 395.057C7.81053 394.195 7.80604 393.329 7.81503 392.468C7.81503 392.24 7.72056 392.177 7.50462 392.186V392.182Z"
        fill={color}
      />
      <path
        d="M9.57817 383.829C9.0833 383.936 8.58393 383.923 8.08007 383.936C6.89239 383.963 4.69248 383.882 3.5093 384.025C2.20015 384.186 1.01247 385.432 0.917995 386.677C0.895501 386.981 0.96298 387.083 1.29139 387.083C3.1269 387.07 5.97014 387.074 7.80564 387.083C8.18804 387.083 8.57044 387.07 8.94834 387.003C11.1797 386.628 12.3764 384.726 11.6746 382.57C11.0943 383.244 10.4059 383.65 9.58266 383.829H9.57817Z"
        fill={color}
      />
      <path
        d="M8.68296 379.918C8.68296 380.82 8.68296 381.722 8.68296 382.628C8.68296 383.079 8.70545 383.092 9.15983 383.016C10.7164 382.762 11.8591 381.436 11.8366 379.891C11.8141 378.32 10.6399 377.007 9.06985 376.793C8.77743 376.753 8.66946 376.829 8.67396 377.132C8.68746 378.061 8.67846 378.989 8.67846 379.918H8.68296Z"
        fill={color}
      />
      <path
        d="M5.01664 383.052C5.42603 383.039 5.83542 383.052 6.24031 383.052C6.66319 383.052 7.08608 383.043 7.50447 383.052C7.71591 383.056 7.81489 382.998 7.81489 382.771C7.80589 381.909 7.80589 381.043 7.81489 380.181C7.81489 379.963 7.72041 379.927 7.52247 379.94C6.08285 380.047 4.82769 381.297 4.70172 382.726C4.67473 383.007 4.7692 383.065 5.01664 383.056V383.052Z"
        fill={color}
      />
      <path
        d="M16.3083 387.003C16.6862 387.065 17.0641 387.083 17.451 387.083C19.2865 387.074 22.1298 387.07 23.9653 387.083C24.2937 387.083 24.3612 386.981 24.3387 386.677C24.2442 385.432 23.0565 384.181 21.7474 384.025C20.5642 383.882 18.3643 383.963 17.1766 383.936C16.6772 383.923 16.1734 383.936 15.6785 383.829C14.8552 383.65 14.1624 383.244 13.5866 382.57C12.8847 384.726 14.0769 386.628 16.3128 387.003H16.3083Z"
        fill={color}
      />
      <path
        d="M16.0965 383.016C16.5509 383.092 16.5734 383.074 16.5734 382.628C16.5734 381.726 16.5734 380.824 16.5734 379.918C16.5734 378.989 16.5644 378.061 16.5779 377.132C16.5824 376.824 16.4744 376.748 16.182 376.793C14.6119 377.012 13.4377 378.32 13.4152 379.891C13.3973 381.436 14.5354 382.766 16.092 383.016H16.0965Z"
        fill={color}
      />
      <path
        d="M17.7528 383.052C18.1757 383.039 18.5941 383.052 19.017 383.052C19.4263 383.052 19.8357 383.043 20.2406 383.052C20.4926 383.061 20.5825 383.007 20.5555 382.722C20.4296 381.293 19.1699 380.047 17.7348 379.936C17.5369 379.922 17.4424 379.958 17.4424 380.177C17.4469 381.039 17.4514 381.905 17.4424 382.766C17.4424 382.994 17.5369 383.056 17.7528 383.048V383.052Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M535.339 11.73H39.793V10.7968H535.339V11.73Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M535.339 388.756H39.793V386.889H535.339V388.756Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6633 37.8604L13.6633 360.759L11.7969 360.759L11.7969 37.8604L13.6633 37.8604Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M563.337 37.8604L563.337 360.759L562.403 360.759L562.403 37.8604L563.337 37.8604Z"
        fill={color}
      />
    </svg>
  );
};

export default IconBorder;
