import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

// IMPORTADOS
import "./VideoHome.scss";
import imgPuertaIngles1 from "../../../images/puertaIngles1.png";
import imgPuertaIngles2 from "../../../images/puertaIngles2.png";
import imgPuertaEspañol1 from "../../../images/puertaEspaniol1.png";
import imgPuertaEspañol2 from "../../../images/puertaEspaniol2.png";
import imgTexturaPuerta1 from "../../../images/texturaChoco1.png";
import imgTexturaPuerta2 from "../../../images/texturaChoco2.png";
import IconFama from "../../../components/icons/IconFama";
import { useTranslation } from "react-i18next";

const VideoHome = () => {
  const [openDoor, setOpenDoor] = useState(false);

  const [t, i18n] = useTranslation("home");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            backgroundColor: "#FFF7EB",
            height: "100vh",
            maxHeight: "1920px",
            marginTop: "-73px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className="iconLoading">
            <IconFama width={50} height={50} />
          </Box>
        </Box>
      ) : (
        <Box id="video" className="containerVideo">
          <Box className="contentVideo">
            <Box className="fondoImagen" onClick={() => setOpenDoor(true)}>
              {/***********
                PUERTA 1
              ***********/}
              <Box
                className="fondoescala"
                sx={{ marginLeft: openDoor === true ? "-160%" : "0" }}
              >
                <img
                  className="fondoPuerta"
                  loading="lazy"
                  src={imgTexturaPuerta1}
                  alt=""
                />
                <img
                  className="puerta1"
                  loading="lazy"
                  src={
                    i18n.language === "es"
                      ? imgPuertaEspañol1
                      : imgPuertaIngles1
                  }
                  alt=""
                />
              </Box>

              {/***********
                PUERTA 2
              ***********/}
              <Box
                className="fondoescala right"
                sx={{ marginRight: openDoor === true ? "-160%" : "0" }}
              >
                <img
                  className="fondoPuerta"
                  loading="lazy"
                  src={imgTexturaPuerta2}
                  alt=""
                />
                <img
                  className="puerta2"
                  src={
                    i18n.language === "es"
                      ? imgPuertaEspañol2
                      : imgPuertaIngles2
                  }
                  alt=""
                />
              </Box>
            </Box>

            {/**********************
              VIDEO YOUTUBE - 1:53
            **********************/}
            <iframe
              style={{
                zIndex: openDoor === true ? 3 : 1,
                transition: "all 4000ms ease-in-out",
              }}
              src="https://www.youtube.com/embed/NDl1jOH3-P0?mute=1&loop=1&list=PLyO3aXe6IwLi0hj65R38nS7ebCK--2ir6"
              // src="https://vimeo.com/839181214?share=copy"
              // src="https://www.vimeo.com/manage/videos/839181214/general"
              title="Fama - Gastrobar"
              width="100%"
              height="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              frameBorder="0"
              rel="0"
              loading="lazy"
            ></iframe>
          </Box>
        </Box>
      )}
    </>
  );
};

export default VideoHome;
