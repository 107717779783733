import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// IMPORTADOS
import "./EventReservation.scss";
import IconBorder from "../../../components/icons/IconBorder";
import IconFama from "../../../components/icons/IconFama";
import IconPoint from "../../../components/icons/IconPoint";
import FondoReservacion from "../../../images/fondoReservacion.png";

const EventReservation = () => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("home");

  return (
    <Box className="containerEventReservation">
      {/***********************
        CONTENEDOR IZQUIERDA
      ***********************/}
      <Box className="contentLeftRight">
        <Box className="contentImgLeft">
          <img
            className="imgLeft"
            src={FondoReservacion}
            alt=""
            loading="lazy"
          />
        </Box>

        {/*********************
          CONTENEDOR DERECHA
        *********************/}
        <Box className="contentImgRight">
          <Box className="imgRight">
            {/* <IconBorder width={606} height={421} color="#804C33" /> */}
            {/* <img src={Border} /> */}

            <Box /* sx={{ backgroundImage: `url(${Border})` }} */>
              <Typography>{t("eventReservation.paragraph")}</Typography>

              {/****************
                BOTON RESERVA
              ****************/}
              <Button
                disableTouchRipple
                href="/events"
                sx={{
                  ":hover": {
                    backgroundColor: "transparent !important",
                    color: "#804c33 !important",
                    border: "2px solid #804c33 !important",
                    transition: "all ease-in-out 0.6s",
                  },
                }}
              >
                {t("eventReservation.button")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="containerSlyderText">
        {/* <Box className={i18n.language === "es" ? "slyderTextEs" : "slyderTextEn"}> */}
        <Box
          className="slyderText"
          sx={{
            animation:
              i18n.language === "es"
                ? "moveEs linear 5000ms infinite"
                : "moveEn linear 22300ms infinite",
          }}
        >
          {/* PREGUNTA */}
          <Typography
            className={
              i18n.language === "es" ? "badFamaEs hideSlyderEs" : "badFamaEn"
            }
          >
            <IconPoint />
            {t("eventReservation.askFama")}
          </Typography>

          {/* BUENA FAMA 1 */}
          <Typography
            className={i18n.language === "es" ? "goodFamaEs" : "goodFamaEn"}
          >
            <IconFama width={16.22} height={15} />
            {t("eventReservation.goodFama")}
          </Typography>

          {/* BUENA MALA 2 */}
          <Typography
            className={i18n.language === "es" ? "badFamaEs" : "badFamaEn"}
          >
            <IconPoint />
            {t("eventReservation.badFama")}
          </Typography>

          {/* BUENA BUENA 3 */}
          <Typography
            className={i18n.language === "es" ? "goodFamaEs" : "goodFamaEn"}
          >
            <IconFama width={16.22} height={15} />
            {t("eventReservation.goodFama")}
          </Typography>

          {/* BUENA MALA 4 */}
          <Typography
            className={i18n.language === "es" ? "badFamaEs" : "badFamaEn"}
          >
            <IconPoint />
            {t("eventReservation.badFama")}
          </Typography>

          {/* BUENA BUENA 5 */}
          <Typography
            className={i18n.language === "es" ? "goodFamaEs" : "goodFamaEn"}
          >
            <IconFama width={16.22} height={15} />
            {t("eventReservation.goodFama")}
          </Typography>

          {/* BUENA BUENA 6 */}
          <Typography
            className={i18n.language === "es" ? "badFamaEs" : "badFamaEn"}
          >
            <IconPoint />
            {t("eventReservation.badFama")}
          </Typography>

          {/* BUENA BUENA 7 */}
          <Typography
            className={i18n.language === "es" ? "goodFamaEs" : "goodFamaEn"}
          >
            <IconFama width={16.22} height={15} />
            {t("eventReservation.goodFama")}
          </Typography>

          {/* PREGUNTA FAMA */}
          <Typography
            className={
              i18n.language === "es" ? "badFamaEs hideSlyderEs" : "badFamaEn"
            }
          >
            <IconPoint />
            {t("eventReservation.askFama")}
          </Typography>

          {/* BUENA BUENA 8 */}
          <Typography
            className={
              i18n.language === "es" ? "goodFamaEs hideSlyderEs" : "goodFamaEn"
            }
          >
            <IconFama width={16.22} height={15} />
            {t("eventReservation.goodFama")}
          </Typography>

          {/* BUENA BUENA 9 */}
          <Typography
            className={i18n.language === "es" ? "badFamaEs" : "badFamaEn"}
          >
            <IconPoint />
            {t("eventReservation.badFama")}
          </Typography>

          {/* BUENA BUENA 10 */}
          <Typography
            className={i18n.language === "es" ? "goodFamaEs" : "goodFamaEn"}
          >
            <IconFama width={16.22} height={15} />
            {t("eventReservation.goodFama")}
          </Typography>

          {/* BUENA BUENA 11 */}
          <Typography
            className={i18n.language === "es" ? "badFamaEs" : "badFamaEn"}
          >
            <IconPoint />
            {t("eventReservation.badFama")}
          </Typography>

          {/* BUENA BUENA 12 */}
          <Typography
            className={i18n.language === "es" ? "goodFamaEs" : "goodFamaEn"}
          >
            <IconFama width={16.22} height={15} />
            {t("eventReservation.goodFama")}
          </Typography>

          {/* BUENA BUENA 13 */}
          <Typography
            className={i18n.language === "es" ? "badFamaEs" : "badFamaEn"}
          >
            <IconPoint />
            {t("eventReservation.badFama")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EventReservation;
