import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const Card = ({
  id,
  img,
  icon = [],
  icon2 = [],
  titleCard,
  description,
  valor,
}) => {
  const { pathname } = useLocation();

  return (
    // CONTENIDO DE LAS CARTAS
    <Box
      className="contentCard"
      sx={{
        backgroundImage: `url(${img})`,
      }}
    >
      {/******************
        FONDO DEGRADADO
      ******************/}
      <Box
        className="degCard"
        sx={{
          ":hover": {
            backgroundColor:
              pathname === "/foods"
                ? "rgba(205, 100, 64, 0.50) !important"
                : "rgba(205, 100, 64, 0.50) !important" &&
                  pathname === "/drinks"
                ? "rgba(175, 73, 215, 0.50) !important"
                : "rgba(175, 73, 215, 0.50) !important" && pathname === "/night"
                ? "rgba(248, 210, 154, 0.35) !important"
                : "rgba(205, 100, 69, 0.5) !important",
          },
        }}
      >
        {/***********************
          CONTENEDOR DEL TEXTO
        ***********************/}
        <Box className="cardBottom">
          {/***********************
            CONTENIDO DEL TEXTO
          ***********************/}
          <Box className="contentText">
            {/**************
              TITLE TEXTO
            ***************/}
            {icon} {icon2}
            <Typography className="titleCard">{titleCard}</Typography>
            <Box>
              <Typography>{description}</Typography>

              <span>${valor}</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Card;
