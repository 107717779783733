import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// IMPORTADOS
import "./Header.scss";
import FamaLogo from "../../../images/logos/FamaLogo";
import IconFama from "../../icons/IconFama";
import IconX from "../../icons/IconX";
import FamaLogoMenuR from "../../../images/logos/FamaLogoMenuR";

const Header = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  const { pathname } = useLocation();

  // NAVEGACIÓN PORTAL
  const linkReservation = () => {
    window.open(
      "https://www.toasttab.com/fama-restaurant-new-3815-w-vine-street",
      "_blank"
    );
  };

  // NAVEGACIÓN PORTAL
  // const linkAboutUs = () => {
  //   window.location.href("home");
  // };

  // NAVEGACIÓN PORTAL
  const handleChange = () => {
    navigate("/");
  };

  // MENU
  const [menu, setMenu] = React.useState(null);
  const openMenu = Boolean(menu);
  // ABRE MENU
  const handleMenu = (event) => {
    setMenu(event.currentTarget);
  };
  // CIERRA MENU
  const handleCloseMenu = () => {
    setMenu(null);
  };

  // MENU COMIDAS
  const toFoods = () => {
    handleCloseMenu();
    navigate("/foods");
  };
  // MENU BEBIDAS
  const toDrinks = () => {
    handleCloseMenu();
    navigate("/drinks");
  };
  // MENU NOCHE
  const toNight = () => {
    handleCloseMenu();
    navigate("/night");
  };

  // MENU
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // MENU HAMBURGUESA
  const [openMenuHambur, setOpenMenuHambur] = useState(false);
  const handleMenuHamburguer = () => {
    setOpenMenuHambur(!openMenuHambur);
  };

  // MENU HAMBURGUESA COMIDAS
  // const toMenuFoods = (anchor) => {
  //   toggleDrawer(anchor, false);
  //   navigate("foods");
  // };

  // CONTENT MENU HAMBURGUESA
  const list = (anchor) => (
    <Box
      className="drawer"
      sx={{
        // FONDO
        backgroundColor:
          pathname === "/foods"
            ? "#FFF7EB"
            : "#FFF7EB" && pathname === "/drinks"
            ? "#6D228B"
            : "#6D228B" && pathname === "/night"
            ? "#CD6440"
            : "#804C33",
      }}
    >
      <Box className="buttonX">
        <Button disableTouchRipple onClick={toggleDrawer(anchor, false)}>
          <IconX />
        </Button>
      </Box>

      <Box
        sx={{ width: 250 }}
        className="listaHeader"
        role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        {/***************
          LOGO DE FAMA
        ***************/}
        <Button
          className="logoFamaMenu"
          // onClick={handleChange}
          href="home"
        >
          <FamaLogoMenuR width={128} />
        </Button>

        {/****************
          LISTA DE MENU
        ****************/}
        <List
          sx={{
            paddingY: "0px",

            ".listaMenu": {
              ">div, >a": {
                color:
                  pathname === "/home"
                    ? "#F8D29A !important"
                    : "#F8D29A" && pathname === "/foods"
                    ? "#804C33 !important"
                    : "#804C33" && pathname === "/drinks"
                    ? "#E6CAF0 !important"
                    : "#E6CAF0" && pathname === "/night"
                    ? "#FFF7EB !important"
                    : "#FFF7EB" && pathname === "/events"
                    ? "#F8D29A !important"
                    : "#F8D29A",
                ">div": {
                  ">span": {
                    color:
                      pathname === "/home"
                        ? "#F8D29A !important"
                        : "#F8D29A" && pathname === "/foods"
                        ? "#804C33 !important"
                        : "#804C33" && pathname === "/drinks"
                        ? "#E6CAF0 !important"
                        : "#E6CAF0" && pathname === "/night"
                        ? "#FFF7EB !important"
                        : "#FFF7EB" && pathname === "/events"
                        ? "#F8D29A !important"
                        : "#F8D29A",
                  },
                },
              },
            },
          }}
        >
          {/************
            BOTON HOME
          *************/}
          <ListItem /* key={text} */ disablePadding className="listaMenu">
            <ListItemButton
              /* onClick={() => navigate("#")} */ onClick={toggleDrawer(
                anchor,
                false
              )}
              onKeyDown={toggleDrawer(anchor, false)}
              href="home"
            >
              <ListItemText primary={t("header.house")} />
            </ListItemButton>
          </ListItem>

          {/***********************
            BOTON SOBRE NOSOTROS
          ***********************/}
          <ListItem /* key={text} */ disablePadding className="listaMenu">
            <ListItemButton
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
              href="home#aboutUs"
            >
              <ListItemText primary={t("header.aboutUs")} />
            </ListItemButton>
          </ListItem>

          {/*************
            BOTON MENU
          *************/}
          <ListItem
            /* key={text} */ disablePadding
            className="listaMenu"
            sx={{ paddingBottom: "10px !important" }}
          >
            <ListItemButton onClick={handleMenuHamburguer} disableTouchRipple>
              <ListItemText primary={t("header.menu")} />
              {/* {openMenuHambur ? <ExpandLess /> : <ExpandMore />} */}
            </ListItemButton>
          </ListItem>

          {/*****************
            CONTENIDO MENU
          *****************/}
          <Collapse
            in={openMenuHambur}
            timeout="auto"
            unmountOnExit
            sx={{
              borderTop:
                pathname === "/home"
                  ? "2px solid #F8D29A !important"
                  : "2px solid #F8D29A" && pathname === "/foods"
                  ? "2px solid #804C33 !important"
                  : "2px solid #804C33" && pathname === "/drinks"
                  ? "2px solid #E6CAF0 !important"
                  : "2px solid #E6CAF0" && pathname === "/night"
                  ? "2px solid #FFF7EB !important"
                  : "2px solid #FFF7EB" && pathname === "/events"
                  ? "2px solid #F8D29A !important"
                  : "2px solid #F8D29A",

              ">div": {
                ">div": {
                  ">.listaMenuAcordeon": {
                    ">.btnMenuAcordeon, >a": {
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      ">div": {
                        ">span": {
                          color:
                            pathname === "/home"
                              ? "#F8D29A !important"
                              : "#F8D29A" && pathname === "/foods"
                              ? "#804C33 !important"
                              : "#804C33" && pathname === "/drinks"
                              ? "#E6CAF0 !important"
                              : "#E6CAF0" && pathname === "/night"
                              ? "#FFF7EB !important"
                              : "#FFF7EB" && pathname === "/events"
                              ? "#F8D29A !important"
                              : "#F8D29A",
                        },
                      },
                    },
                  },
                },
              },
            }}
          >
            <List
              component="div"
              disablePadding
              className="listaMenuAcordeon"
              sx={{ paddingTop: "10px" }}
            >
              <ListItemButton
                sx={{ pl: 4 }}
                // onClick={toMenuFoods}
                href="foods"
                className="btnMenuAcordeon"
              >
                <ListItemText primary={t("header.menuFoods")} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
                href="drinks"
                className="btnMenuAcordeon"
              >
                <ListItemText primary={t("header.menuDrinks")} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
                href="night"
                className="btnMenuAcordeon"
              >
                <ListItemText primary={t("header.menuNight")} />
              </ListItemButton>
            </List>
          </Collapse>

          {/****************
            BOTON EVENTOS
          ****************/}
          <ListItem /* key={text} */ disablePadding className="listaMenu">
            <ListItemButton
              /* onClick={() => navigate("#")} */
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
              href="events"
            >
              <ListItemText primary={t("header.happening")} />
            </ListItemButton>
          </ListItem>
        </List>

        {/****************
          BOTON RESERVA
        ****************/}
        <List
          sx={{
            ".contentBtnMenuReservation": {
              ">div": {
                ">div": {
                  backgroundColor:
                    pathname === "/home"
                      ? "#F8D29A !important"
                      : "#F8D29A" && pathname === "/foods"
                      ? "#804C33 !important"
                      : "#804C33" && pathname === "/drinks"
                      ? "#E6CAF0 !important"
                      : "#E6CAF0" && pathname === "/night"
                      ? "#FFF7EB !important"
                      : "#FFF7EB" && pathname === "/events"
                      ? "#F8D29A !important"
                      : "#F8D29A",

                  ">span": {
                    color:
                      pathname === "/home"
                        ? "#804C33 !important"
                        : "#804C33" && pathname === "/foods"
                        ? "#F8D29A !important"
                        : "#F8D29A" && pathname === "/drinks"
                        ? "#6D228B !important"
                        : "#6D228B" && pathname === "/night"
                        ? "#CD6440 !important"
                        : "#CD6440" && pathname === "/events"
                        ? "#804C33 !important"
                        : "#F8D29A",
                  },
                },
              },
            },
          }}
        >
          <ListItem disablePadding className="contentBtnMenuReservation">
            <ListItemButton
              disableTouchRipple
              onClick={() => navigate("/home")}
              onKeyDown={toggleDrawer(anchor, false)}
              className="btnMenuReservation"
            >
              <ListItemText primary={t("header.reservation")} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box
      id="header"
      className="containerHeader" /* className={`containerHeader ${showNav && "fondoBlue"}`} */
    >
      <Box className="contentLogo">
        <Button onClick={handleChange}>
          <FamaLogo color="#F8D29A" />
        </Button>
      </Box>

      <Box
        className="contentMenu menuHeader"
        sx={{
          ">a": {
            color:
              pathname === "/drinks"
                ? "#E6CAF0"
                : "#E6CAF0" && pathname === "/night"
                ? "#FFF7EB"
                : "#F8D29A",

            // transition: "transform 0.5s ease-in-out",
            ":hover": {
              ">span": {
                transform: "scaleX(1)",
              },
            },

            ">span": {
              backgroundColor:
                pathname === "/drinks"
                  ? "#E6CAF0"
                  : "#E6CAF0" && pathname === "/night"
                  ? "#FFF7EB"
                  : "#F8D29A",
              height: "2px",
              display: "block",
              transform: "scaleX(0)",
              transformOrigin: "left",
              transition: "transform 0.5s ease-in-out",
            },
          },
        }}
      >
        {/***************
          BOTON INICIO
        ***************/}
        <Typography component={"a"} href="/home" className="menuR menuFull">
          {t("header.house")}
          <span></span>
        </Typography>

        {/***********************
          BOTON SOBRE NOSOTROS
        ***********************/}
        <Typography
          component={"a"}
          href="home#aboutUs"
          className="aboutUsId menuR menuFull"
        >
          {t("header.aboutUs")}
          <span></span>
        </Typography>

        {/*************
          BOTON MENU
        *************/}
        <Typography
          component={"a"}
          onMouseEnter={handleMenu}
          className="menu menuFull"
        >
          {t("header.menu")}
          <span></span>
        </Typography>

        {/*******
          MENU
        *******/}
        <Menu
          id="basic-menu"
          anchorEl={menu}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              marginTop: "20px",
              width: "168px",
              boxShadow: "none",
              borderTop:
                pathname === "/drinks"
                  ? "2px solid #E6CAF0"
                  : "2px solid #E6CAF0" && pathname === "/night"
                  ? "2px solid #FFF7EB"
                  : "2px solid #F8D29A",
              borderRadius: "0px",

              // LISTA
              ">ul": {
                paddingTop: "0px",
                paddingBottom: "0px",
                ">li": {
                  width: "100%",
                  textAlign: "center",
                  marginY: "6px",

                  // HOVER
                  ":hover": {
                    backgroundColor: "transparent",
                    ">p": {
                      color:
                        pathname === "/foods"
                          ? "#FFF7EB"
                          : "#FFF7EB" && pathname === "/drinks"
                          ? "#6D228B"
                          : "#6D228B" && pathname === "/night"
                          ? "#CD6440"
                          : "#F8D29A",
                      borderRadius: "7px",

                      // FONDO
                      background:
                        pathname === "/foods"
                          ? "rgba(248, 210, 154, 0.50)"
                          : "rgba(248, 210, 154, 0.50)" &&
                            pathname === "/drinks"
                          ? "#E6CAF080"
                          : "#E6CAF080" && pathname === "/night"
                          ? "#FFF7EB80"
                          : "rgba(248, 210, 154, 0.50)",
                    },
                  },

                  // TEXTO
                  ">p": {
                    color:
                      pathname === "/foods"
                        ? "#F8D29A"
                        : "#F8D29A" && pathname === "/drinks"
                        ? "#E6CAF0"
                        : "#E6CAF0" && pathname === "/night"
                        ? "#FFF7EB"
                        : "#F8D29A",
                    fontFamily: "Aleo",
                    fontSize: "16px",
                    lineHeight: "19px",
                    width: "fit-content",
                    // textAlign: "center",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    padding: "10px 20px",
                    margin: "auto",
                  },
                },
              },
            },
          }}
        >
          {/*********
            COMIDA
          *********/}
          <MenuItem
            className="menuFull"
            onClick={toFoods}
            disableTouchRipple
            autoFocus={false}
            selected={false}
            keepMounted
          >
            <Typography>{t("header.menuFoods")}</Typography>
          </MenuItem>

          {/*********
            BEBIDA
          *********/}
          <MenuItem className="menuFull" onClick={toDrinks} disableTouchRipple>
            <Typography>{t("header.menuDrinks")}</Typography>
          </MenuItem>

          {/*************
            EN LA NOCHE
          *************/}
          <MenuItem className="menuFull" onClick={toNight} disableTouchRipple>
            <Typography>{t("header.menuNight")}</Typography>
          </MenuItem>
        </Menu>

        {/**********
            EVENTOS
          **********/}
        <Typography className="menuFull" component={"a"} href="events">
          {t("header.happening")}
          <span></span>
        </Typography>

        {/****************
          BOTON RESERVA
        ****************/}
        <Button
          onClick={linkReservation}
          className="menuFull btnReserva"
          disableTouchRipple
          sx={{
            color:
              pathname === "/drinks"
                ? "#E6CAF0"
                : "#E6CAF0" && pathname === "/night"
                ? "#FFF7EB"
                : "#F8D29A",
            border:
              pathname === "/drinks"
                ? "2px solid #E6CAF0"
                : "2px solid #E6CAF0" && pathname === "/night"
                ? "2px solid #FFF7EB"
                : "2px solid #F8D29A",

            ":hover": {
              backgroundColor:
                pathname === "/drinks"
                  ? "#E6CAF0"
                  : "#E6CAF0" && pathname === "/night"
                  ? "#FFF7EB"
                  : "#F8D29A",
              color:
                pathname === "/drinks"
                  ? "#6D228B"
                  : "#6D228B" && pathname === "/night"
                  ? "#CD6440"
                  : "#804C33",
              border: "0px solid transparent !important",
              transition: "all ease-in-out 0.6s",
            },
          }}
        >
          {t("header.reservation")}
        </Button>

        {/* BOTON MENU HAMBURGUESA */}
        <>
          {["right"].map((anchor) => (
            <Box key={anchor} className="iconMenu">
              <IconButton
                className="btnMenu"
                disableTouchRipple
                onClick={toggleDrawer(anchor, true)}
                sx={{
                  ">svg": {
                    ">path": {
                      color:
                        pathname === "/foods"
                          ? "#F8D29A"
                          : "#F8D29A" && pathname === "/drinks"
                          ? "#E6CAF0"
                          : "#E6CAF0" && pathname === "/night"
                          ? "#FFF7EB"
                          : "#F8D29A",
                    },
                  },
                }}
              >
                <MenuIcon />
              </IconButton>

              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </Box>
          ))}
        </>
      </Box>
    </Box>
  );
};

export default Header;
