import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

// IMPORTADO
import List from "./List";
import { useLocation } from "react-router-dom";

const SeccionList = ({
  id,
  img,
  title = "Titulo",
  display = "",
  titleList,
  api = [],
}) => {
  const [effectColor, setEffectColor] = useState(false);
  const { pathname } = useLocation();

  const controlTheme = () => {
    if (window.scrollY > 5100) {
      setEffectColor(true);
    } else {
      setEffectColor(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlTheme);
    return () => {
      window.removeEventListener("scroll", controlTheme);
    };
  }, []);

  return (
    // CONTENEDOR SECCION
    <Box className="contentSection" id={id}>
      {/***********************
        BANNER DE LA SECCION
      ***********************/}
      <Box
        className="bannerSection"
        sx={{ backgroundImage: `url(${img})`, display: { display } }}
      >
        {/******************
          FONDO DEGRADADO
        ******************/}
        <Box
          className="degBanner"
          sx={{
            backgroundColor:
              pathname === "/foods"
                ? "rgba(205, 100, 64, 0.50) !important"
                : "rgba(205, 100, 64, 0.50)" && pathname === "/drinks"
                ? "rgba(175, 73, 215, 0.50) !important"
                : "rgba(175, 73, 215, 0.50)" && pathname === "/night"
                ? "rgba(248, 210, 154, 0.35) !important"
                : "rgba(205, 100, 69, 0.5)",
          }}
        >
          <div>
            {/**********
            TITULO
          **********/}
            <Typography>{title}</Typography>

            {/*********
            RAYITA
          *********/}
            <span></span>
          </div>
        </Box>
      </Box>

      {/***************************
        CONTENEDOR DE LAS CARTAS
      ***************************/}
      <Box className="containerList">
        {/**************
          TITLE LISTA
        ***************/}
        <Box
          className="contentTitle"
          color={effectColor === true ? "#E6CAF0" : "#6D228B"}
          // sx={{ display: pathname === "/foods" && "none" }}
        >
          <Typography>{titleList}</Typography>
        </Box>

        {/***************************
          CONTENIDO DE LAS CARTAS
        ***************************/}
        {api.map((apiSeccion) => (
          <List
            title={apiSeccion.title}
            titleList={apiSeccion.titleList}
            valor={apiSeccion.valor}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SeccionList;
