import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

// IMPORTADOS
import "../Menu.scss";
import BannerBebidas from "../../../images/menu/bebidas/bannerBebidas.png";
import fondoChocolate from "../../../images/menu/fondoChocolate.png";
import SeccionCard from "../foods/seccion/SeccionCard";
import SeccionList from "../foods/seccion/SeccionList";
import IconFama from "../../../components/icons/IconFama";
import imgBannerCocteles from "../../../images/menu/bebidas/cocteles/cocteles.png";
import imgBannerCoctelesClasicos from "../../../images/menu/bebidas/cocteles/coctelesClasicos.png";
import imgBannerLicores from "../../../images/menu/bebidas/licores/licores.png";

// API
import {
  apiCoctelesEs,
  apiChampagneEs,
  apiCognacEs,
  apiWhiskeyEs,
  apiVodkaEs,
  apiTequilaEs,
  apiImportadosEs,
  apiWineEs,
  apiCoctelesClasicosEs,
} from "../../../components/data/dataApiEs";
import { useTranslation } from "react-i18next";
import {
  apiChampagneEn,
  apiCoctelesClasicosEn,
  apiCoctelesEn,
  apiCognacEn,
  apiImportadosEn,
  apiTequilaEn,
  apiVodkaEn,
  apiWhiskeyEn,
  apiWineEn,
} from "../../../components/data/dataApiEn";

const Drinks = () => {
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("menu");

  const [effectColor, setEffectColor] = useState(false);

  const controlTheme = () => {
    if (window.scrollY > 5100) {
      setEffectColor(true);
    } else {
      setEffectColor(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlTheme);
    return () => {
      window.removeEventListener("scroll", controlTheme);
    };
  }, []);

  return (
    // CONTENEDOR COMIDA
    <Box
      // className="containerFood"
      id="drinks"
      className={`containerDrink ${effectColor && "containerDrinksDrinks"}`}
      sx={{
        paddingBottom: pathname === "/drinks" ? "200px" : null,
      }}
    >
      {/*************
        FONDO IMAGEN
      *************/}
      <Box
        id="up"
        className="backgroundImage"
        sx={{
          backgroundImage: `url(${BannerBebidas})`,
        }}
      >
        {/*************
          DEGRADADO
        *************/}
        <Box className="degradado">
          <Box
            className="title"
            sx={{ /* maxWidth: "1460px", */ maxWidth: "1460px", width: "80%" }}
          >
            <Typography component={"span"}>
              {t("bannerDrinks.subtitle")}
            </Typography>

            <Typography>{t("bannerDrinks.title")}</Typography>
          </Box>
        </Box>
      </Box>

      {/***********
        BAR MENU
      ***********/}
      <Box className="barMenu" sx={{ backgroundColor: "#6D228B" }}>
        <Box
          className="contentBarMenu"
          sx={{
            // marginLeft: "320px",
            // marginRight: "320px",
            marginLeft: "20%",
            marginRight: "20%",
            // ">a": { color: "#F8D29A !important" },
            ">a": { color: "#E6CAF0 !important" },
          }}
        >
          <Typography component={"a"} href="#seccionCocteles">
            {t("linkBannerDrinks.cocktails")}
          </Typography>

          <Typography component={"a"} href="#seccionChampaña">
            {t("linkBannerDrinks.champagne")}
          </Typography>

          <Typography component={"a"} href="#seccionCognac">
            {t("linkBannerDrinks.cognac")}
          </Typography>

          <Typography component={"a"} href="#seccionWhiskey">
            {t("linkBannerDrinks.whiskey")}
          </Typography>

          <Typography component={"a"} href="#seccionVodka">
            {t("linkBannerDrinks.vodka")}
          </Typography>

          <Typography component={"a"} href="#seccionTequila">
            {t("linkBannerDrinks.tequila")}
          </Typography>

          <Typography component={"a"} href="#seccionImportados">
            {t("linkBannerDrinks.import")}
          </Typography>

          <Typography component={"a"} href="#seccionWine">
            {t("linkBannerDrinks.wine")}
          </Typography>
        </Box>
      </Box>

      {/**********
        CONTENT
      **********/}
      <Box className="contentFood">
        {/*****************************
          SECCION DE COCTELES CLASICOS
        *******************************/}
        <SeccionList
          id={"seccionCoctelesClasicos"}
          title={t("linkBannerDrinks.classicCocktails")}
          api={
            i18n.language === "es"
              ? apiCoctelesClasicosEs
              : apiCoctelesClasicosEn
          }
          img={imgBannerCoctelesClasicos}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#AF49D7" : "#6D228B"}
            // color={"#F8D29A"}
          />
        </Box>

        {/********************
          SECCION DE COCTELES
        **********************/}
        <SeccionCard
          id={"seccionCocteles"}
          title={t("linkBannerDrinks.cocktails")}
          api={i18n.language === "es" ? apiCoctelesEs : apiCoctelesEn}
          img={imgBannerCocteles}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#AF49D7" : "#6D228B"}
            // color={"#F8D29A"}
          />
        </Box>

        {/********************
          SECCION DE LICORES
        **********************/}
        {/*********
          CHAMPAÑA
        ***********/}
        <SeccionList
          id={"seccionChampaña"}
          title={t("linkBannerDrinks.liquors")}
          titleList={t("linkBannerDrinks.champagne")}
          api={i18n.language === "es" ? apiChampagneEs : apiChampagneEn}
          img={imgBannerLicores}
        />

        {/*********
          COGNAC
        ***********/}
        <SeccionList
          id={"seccionCognac"}
          title=""
          display="none"
          titleList={t("linkBannerDrinks.cognac")}
          api={i18n.language === "es" ? apiCognacEs : apiCognacEn}
          img={imgBannerLicores}
        />

        {/*********
          WHYSKEY
        ***********/}
        <SeccionList
          id={"seccionWhiskey"}
          title=""
          display="none"
          titleList={t("linkBannerDrinks.whiskey")}
          api={i18n.language === "es" ? apiWhiskeyEs : apiWhiskeyEn}
          img={imgBannerLicores}
        />

        {/*********
          VODKA
        ***********/}
        <SeccionList
          id={"seccionVodka"}
          title=""
          display="none"
          titleList={t("linkBannerDrinks.vodka")}
          api={i18n.language === "es" ? apiVodkaEs : apiVodkaEn}
          img={imgBannerLicores}
        />

        {/*********
          TEQUILA
        ***********/}
        <SeccionList
          id={"seccionTequila"}
          title=""
          display="none"
          titleList={t("linkBannerDrinks.tequila")}
          api={i18n.language === "es" ? apiTequilaEs : apiTequilaEn}
          img={imgBannerLicores}
        />

        {/***********
          IMPORTADOS
        *************/}
        <SeccionList
          id={"seccionImportados"}
          title=""
          display="none"
          titleList={t("linkBannerDrinks.import")}
          api={i18n.language === "es" ? apiImportadosEs : apiImportadosEn}
          img={imgBannerLicores}
        />

        {/******
          WINE
        ********/}
        <SeccionList
          id={"seccionWine"}
          title=""
          display="none"
          titleList={t("linkBannerDrinks.wine")}
          api={i18n.language === "es" ? apiWineEs : apiWineEn}
          img={imgBannerLicores}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle" sx={{ paddingBottom: "0px" }}>
          {/*************
            ICONO FAMA
          *************/}
          <IconFama
            width={55}
            height={50}
            color={effectColor === true ? "#AF49D7" : "#F8D29A"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Drinks;
