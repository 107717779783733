import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

// IMPORTADOS
import "../Menu.scss";
import IconFama from "../../../components/icons/IconFama";
import SeccionCard from "../foods/seccion/SeccionCard";
import fondoChocolate from "../../../images/menu/fondoChocolate.png";
import imgBannerNoches from "../../../images/menu/noche/bannerNoche.png";
import imgBannerTatakis from "../../../images/menu/noche/tatakis/bannerTatakis.png";
import imgBannerTiraditos from "../../../images/menu/noche/tiraditos/bannerTiraditos.png";
import imgBannerRolls from "../../../images/menu/noche/rolls/bannerRolls.png";
import imgBannerMixtos from "../../../images/menu/noche/mixtos/bannerMixtos.png";

// API
import {
  apiTatakisEs,
  apiTiraditosEs,
  apiRollsEs,
  apiMixtosEs,
} from "../../../components/data/dataApiEs";
import { useTranslation } from "react-i18next";
import {
  apiMixtosEn,
  apiRollsEn,
  apiTatakisEn,
  apiTiraditosEn,
} from "../../../components/data/dataApiEn";
import SeccionCardList from "../foods/seccion/SeccionCardList";

const Night = () => {
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("menu");

  // ESTADO DE TEMA
  const [effectColor, setEffectColor] = useState(false);

  // CONTROL TEMA
  const controlTheme = () => {
    if (window.scrollY > 3400) {
      setEffectColor(true);
    } else {
      setEffectColor(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlTheme);
    return () => {
      window.removeEventListener("scroll", controlTheme);
    };
  }, []);

  return (
    // CONTENEDOR NOCHE
    <Box
      // className="containerFood"
      className={`containerNight ${effectColor && "containerNightNight"}`}
      sx={{ paddingBottom: pathname === "/night" ? "200px" : null }}
    >
      {/*************
        FONDO IMAGEN
      *************/}
      <Box
        id="up"
        className="backgroundImage"
        sx={{
          backgroundImage: `url(${imgBannerNoches})`,
        }}
      >
        {/*************
          DEGRADADO
        *************/}
        <Box className="degradado">
          <Box className="title" sx={{ maxWidth: "1430px", width: "80%" }}>
            <Typography component={"span"}>
              {t("bannerNight.subtitle")}
            </Typography>

            <Typography>{t("bannerNight.title")}</Typography>
          </Box>
        </Box>
      </Box>

      {/***********
        BAR MENU
      ***********/}
      <Box
        className="barMenu"
        sx={{ backgroundImage: `url(${fondoChocolate})` }}
      >
        <Box
          className="contentBarMenu"
          sx={{
            width: "500px",
            margin: "auto !important",
            marginLeft: "15%",
            marginRight: "15%",
            // ">a": { color: "#E6CAF0 !important" },
            ">a": { color: "#F8D29A !important" },

            "@media (max-width: 660px)": {
              width: "auto !important",
            },
          }}
        >
          <Typography component={"a"} href="#seccionMixtos">
            {t("linkBannerNight.mixed")}
          </Typography>

          <Typography component={"a"} href="#seccionTatakis">
            {t("linkBannerNight.tatakis")}
          </Typography>

          <Typography component={"a"} href="#seccionTiraditos">
            {t("linkBannerNight.tiraditos")}
          </Typography>

          <Typography component={"a"} href="#seccionRolls">
            {t("linkBannerNight.rolls")}
          </Typography>
        </Box>
      </Box>

      {/****************
        CONTENT NOCHE
      ****************/}
      <Box className="contentFood">
        {/******************
          SECCION DE MIXED
        ********************/}
        <SeccionCard
          id={"seccionMixtos"}
          title={t("linkBannerNight.mixed")}
          api={i18n.language === "es" ? apiMixtosEs : apiMixtosEn}
          img={imgBannerMixtos}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama width={55} height={50} color={"#F8D29A"} />
        </Box>

        {/**********************
          SECCION DE TATAKIS
        ************************/}
        <SeccionCard
          id={"seccionTatakis"}
          title={t("linkBannerNight.tatakis")}
          api={i18n.language === "es" ? apiTatakisEs : apiTatakisEn}
          img={imgBannerTatakis}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama width={55} height={50} color={"#F8D29A"} />
        </Box>

        {/**********************
          SECCION DE TIRADITOS
        ************************/}
        <SeccionCard
          id={"seccionTiraditos"}
          title={t("linkBannerNight.tiraditos")}
          api={i18n.language === "es" ? apiTiraditosEs : apiTiraditosEn}
          img={imgBannerTiraditos}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box className="titleMiddle">
          {/*************
            ICONO FAMA
          *************/}
          <IconFama width={55} height={50} color={"#F8D29A"} />
        </Box>

        {/**********************
          SECCION DE ROLLS
        ************************/}
        <SeccionCardList
          id={"seccionRolls"}
          title={t("linkBannerNight.rolls")}
          api={i18n.language === "es" ? apiRollsEs : apiRollsEn}
          img={imgBannerRolls}
        />

        {/********************
          TITULO INTERMEDIO
        **********************/}
        <Box
          className="titleMiddle"
          sx={{ paddingTop: "200px", paddingBottom: "0px" }}
        >
          {/*************
            ICONO FAMA
          *************/}
          <IconFama width={55} height={50} color={"#F8D29A"} />
        </Box>
      </Box>
    </Box>
  );
};

export default Night;
