import React from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const Map = () => {
  const { pathname } = useLocation();

  if (pathname === "/foods") return null;
  if (pathname === "/drinks") return null;
  if (pathname === "/night") return null;

  return (
    <Box
      sx={{
        height: "560px",

        ".map-container": {
          height: "100%",
          width: "99.8%",
        },
      }}
    >
      <iframe
        className="map-container"
        title="mapa"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3512.7819307518694!2d-81.44484472482019!3d28.304929275845126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd83e479ff02b1%3A0x428d15024b420b4a!2s3815%20W%20Vine%20St%2C%20Kissimmee%2C%20FL%2034741%2C%20EE.%20UU.!5e0!3m2!1ses!2sco!4v1684697856811!5m2!1ses!2sco"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
};

export default Map;
