import React from "react";

const IconWhatsapp = ({ width = 25, height = 25, color = "#FFFFFF" }) => {
  return (
    <svg
      className="iconWhatsapp"
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2499 3.63756C20.1039 2.47997 18.7389 1.56215 17.2345 0.937635C15.7301 0.313116 14.1164 -0.00560013 12.4875 7.44618e-05C5.66248 7.44618e-05 0.0999999 5.56256 0.0999999 12.3875C0.0999999 14.575 0.674998 16.7 1.74999 18.575L0 25L6.56248 23.275C8.37498 24.2625 10.4125 24.7875 12.4875 24.7875C19.3124 24.7875 24.8749 19.225 24.8749 12.4C24.8749 9.08755 23.5874 5.97506 21.2499 3.63756ZM12.4875 22.6875C10.6375 22.6875 8.82497 22.1875 7.23748 21.25L6.86248 21.025L2.96249 22.05L3.99999 18.25L3.74999 17.8625C2.72217 16.2212 2.17642 14.3241 2.17499 12.3875C2.17499 6.71255 6.79998 2.08757 12.475 2.08757C15.225 2.08757 17.8124 3.16257 19.7499 5.11256C20.7093 6.06751 21.4696 7.20338 21.9867 8.45433C22.5038 9.70528 22.7675 11.0464 22.7624 12.4C22.7874 18.075 18.1624 22.6875 12.4875 22.6875ZM18.1374 14.9875C17.8249 14.8375 16.3 14.0875 16.025 13.975C15.7375 13.875 15.5375 13.825 15.325 14.125C15.1125 14.4375 14.525 15.1375 14.35 15.3375C14.175 15.55 13.9875 15.575 13.675 15.4125C13.3625 15.2625 12.3625 14.925 11.1875 13.875C10.2625 13.05 9.64997 12.0375 9.46247 11.725C9.28747 11.4125 9.43747 11.25 9.59997 11.0875C9.73747 10.95 9.91247 10.725 10.0625 10.55C10.2125 10.375 10.275 10.2375 10.375 10.0375C10.475 9.82504 10.425 9.65005 10.35 9.50005C10.275 9.35005 9.64997 7.82505 9.39997 7.20005C9.14997 6.60006 8.88747 6.67505 8.69997 6.66255H8.09998C7.88748 6.66255 7.56248 6.73755 7.27498 7.05005C6.99998 7.36255 6.19998 8.11255 6.19998 9.63755C6.19998 11.1625 7.31248 12.6375 7.46248 12.8375C7.61248 13.05 9.64997 16.175 12.75 17.5125C13.4875 17.8375 14.0625 18.025 14.5125 18.1625C15.25 18.4 15.925 18.3625 16.4625 18.2875C17.0624 18.2 18.2999 17.5375 18.5499 16.8125C18.8124 16.0875 18.8124 15.475 18.7249 15.3375C18.6374 15.2 18.4499 15.1375 18.1374 14.9875Z"
        fill={color}
      />
    </svg>
  );
};

export default IconWhatsapp;
