import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// IMPORTADOS
import "./Portal.scss";
import FamaLogo from "../../images/logos/FamaLogo";
import IconInstagram from "../../components/icons/IconInstagram";
import IconFama from "../../components/icons/IconFama";
import IconWhatsapp from "../../components/icons/IconWhatsapp";
import IconFacebook from "../../components/icons/IconFacebook";

const Portal = () => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");
  const [activeHover, setActiveHover] = useState(false);
  const [activeHoverEn, setActiveHoverEn] = useState(false);
  const [activeHoverEs, setActiveHoverEs] = useState(false);

  // IDIOMAS / NAVEGACIÓN HOME / GUARDADO EN STORAGE
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };
  // HOVER INGLES
  const lngHoverEn = () => {
    setActiveHoverEn(true);
    i18n.changeLanguage("en");
  };

  // HOVER ESPAÑOL
  const lngHoverEs = () => {
    setActiveHoverEs(true);
    i18n.changeLanguage("es");
  };

  // HOVER POR DEFECTO ESPAÑOL
  const lngHoverTrue = (lng) => {
    setActiveHoverEn(false);
    setActiveHoverEs(false);

    if (i18n.logger.options.lng === "es") {
      i18n.changeLanguage("es");
    } else {
      i18n.changeLanguage("en");
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#FFF7EB",
          }}
        >
          <Box className="iconLoading">
            <IconFama width={50} height={50} />
          </Box>
        </Box>
      ) : (
        // CONTENEDOR PORTAL / IMG FONDO
        <Box className="containerPortal">
          {/**************************
            CONTENIDO DEL PORTAL
          **************************/}
          <Box
            className="contentPortal"
            sx={{
              ":hover": {
                backgroundColor:
                  activeHover === true
                    ? "#8e1ebb80"
                    : "none" && activeHoverEn === true
                    ? "#8e1ebb80"
                    : "none" && activeHoverEs
                    ? "#8e1ebb80"
                    : "none",
                transition:
                  activeHover === true
                    ? "all ease-in-out 0.6s"
                    : "all ease-in-out 0.6s" && activeHoverEn === true
                    ? "all ease-in-out 0.6s"
                    : "all ease-in-out 0.6s" && activeHoverEs === true
                    ? "all ease-in-out 0.6s"
                    : "all ease-in-out 0.6s",
              },
            }}
          >
            {/*************************
              HEADER / ENCABEZADO
            *************************/}
            <Box className="contentPortalHeader btnResvFalse">
              {/******************
                BOTON RESERVA
              ******************/}
              <Button
                className="botonReserva"
                disableTouchRipple
                onMouseEnter={() => setActiveHover(true)}
                onMouseLeave={() => setActiveHover(false)}
                sx={{
                  // marginRight: "10px",
                  ":hover": {
                    backgroundColor: activeHover === true && "#6D228B",
                    border:
                      activeHover === true &&
                      "0px solid transparent !important",
                    transition: activeHover === true && "all ease-in-out 0.6s",
                  },
                }}
              >
                {t("portal.reservation")}
              </Button>

              {/*************
                LOGO FAMA
              *************/}
              <FamaLogo />
            </Box>

            {/*****************************
              CONTENEDOR BOTONES - 400px
            *****************************/}
            <Box className="contentButtons btnLngTrue">
              {/****************
                BOTON INGLES
              ****************/}
              <Button
                className="btnsLenguaje"
                disableTouchRipple
                href="/home"
                onClick={() => handleChangeLng("en")}
                // onMouseEnter={lngHover}
                onMouseEnter={lngHoverEn}
                onMouseLeave={lngHoverTrue}
                sx={{
                  marginRight: "10px",
                  ":hover": {
                    backgroundColor: activeHoverEn === true && "#6D228B",
                    boxShadow: activeHoverEn === true && "0px 0px 15px #AF49D7",
                    border:
                      activeHoverEn === true &&
                      "0px solid transparent !important",
                    transition:
                      activeHoverEn === true && "all ease-in-out 0.6s",
                  },
                }}
              >
                Inglés
              </Button>

              {/***************
                BOTON ESPAÑOL
              ***************/}
              <Button
                className="btnsLenguaje"
                disableTouchRipple
                href="/home"
                onClick={() => handleChangeLng("es")}
                onMouseEnter={lngHoverEs}
                onMouseLeave={lngHoverTrue}
                sx={{
                  marginLeft: "10px",
                  ":hover": {
                    backgroundColor: activeHoverEs === true && "#6D228B",
                    boxShadow: activeHoverEs === true && "0px 0px 15px #AF49D7",
                    border:
                      activeHoverEs === true &&
                      "0px solid transparent !important",
                    transition:
                      activeHoverEs === true && "all ease-in-out 0.6s",
                  },
                }}
              >
                Español
              </Button>
            </Box>

            {/****************
              TITULO CENTRAL
            ****************/}
            <Typography
              variant="h1"
              sx={{
                // ">span": {
                transition: "all ease-in-out 0.6s",
                ":hover": { transition: "all ease-in-out 0.6s" },
                // },
              }}
            >
              <span className="small" content="hola">
                {t("portal.titleSmall1")}
              </span>{" "}
              <span className="mayus big">{t("portal.titleBig")}</span>{" "}
              <span className="small">{t("portal.titleSmall2En")}</span> <br />
              <span className="small">{t("portal.titleSmall2Es")}</span>{" "}
              <span className="mayus big">{t("portal.titleBigEn")}</span>
              <span className="big">{t("portal.titleBigEs")}</span>
            </Typography>

            {/*********************
              CONTENEDOR BOTONES
            *********************/}
            <Box className="contentButtons btnLngFalse">
              {/****************
                BOTON INGLES
              ****************/}
              <Button
                className="btnsLenguaje"
                disableTouchRipple
                href="/home"
                onClick={() => handleChangeLng("en")}
                // onMouseEnter={lngHover}
                onMouseEnter={lngHoverEn}
                onMouseLeave={lngHoverTrue}
                sx={{
                  marginRight: "10px",
                  ":hover": {
                    backgroundColor: activeHoverEn === true && "#6D228B",
                    boxShadow: activeHoverEn === true && "0px 0px 15px #AF49D7",
                    border:
                      activeHoverEn === true &&
                      "0px solid transparent !important",
                    transition:
                      activeHoverEn === true && "all ease-in-out 0.6s",
                  },
                }}
              >
                Inglés
              </Button>

              {/***************
                BOTON ESPAÑOL
              ***************/}
              <Button
                className="btnsLenguaje"
                disableTouchRipple
                href="/home"
                onClick={() => handleChangeLng("es")}
                onMouseEnter={lngHoverEs}
                onMouseLeave={lngHoverTrue}
                sx={{
                  marginLeft: "10px",
                  ":hover": {
                    backgroundColor: activeHoverEs === true && "#6D228B",
                    boxShadow: activeHoverEs === true && "0px 0px 15px #AF49D7",
                    border:
                      activeHoverEs === true &&
                      "0px solid transparent !important",
                    transition:
                      activeHoverEs === true && "all ease-in-out 0.6s",
                  },
                }}
              >
                Español
              </Button>
            </Box>

            {/*************************
              HEADER / ENCABEZADO
            *************************/}
            <Box className="contentPortalHeader btnResvTrue">
              {/******************
                BOTON RESERVA
              ******************/}
              <Button
                className="botonReserva"
                disableTouchRipple
                onMouseEnter={() => setActiveHover(true)}
                onMouseLeave={() => setActiveHover(false)}
                sx={{
                  // marginRight: "10px",
                  ":hover": {
                    backgroundColor: activeHover === true && "#6D228B",
                    border:
                      activeHover === true &&
                      "0px solid transparent !important",
                    transition: activeHover === true && "all ease-in-out 0.6s",
                  },
                }}
              >
                {t("portal.reservation")}
              </Button>

              {/*************
                LOGO FAMA
              *************/}
              <FamaLogo />
            </Box>

            {/*************************
              FOOTER / PIE DE PAGINA
            *************************/}
            <Box className="footerPortal">
              {/***********
                ICONOS
              ***********/}
              <Box>
                {/***********
                  WHATSAPP
                ***********/}
                <Button
                  href="https://wa.me/14074584631?text=Bienvenido%20a%20Fama%20-%20Gastrobar"
                  target="_blank"
                >
                  <IconWhatsapp />
                </Button>

                {/***********
                  INSTAGRAM
                ***********/}
                <Button
                  href="https://www.instagram.com/fama_gastrobar/"
                  target="_blank"
                >
                  <IconInstagram />
                </Button>

                {/***********
                  FACEBOOK
                ***********/}
                <Button
                  href="https://www.facebook.com/profile.php?id=100093488720199"
                  target="_blank"
                >
                  <IconFacebook />
                </Button>
              </Box>

              {/***************
                COPYRIGHT
              ***************/}
              <Typography>FAMA © 2023</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Portal;
