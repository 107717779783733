import React from "react";

const IconFacebook = ({ width = 25, height = 25, color = "#ffffff" }) => {
  return (
    <svg
      className="iconFacebook"
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 0H1C0.446875 0 0 0.446875 0 1V24C0 24.5531 0.446875 25 1 25H24C24.5531 25 25 24.5531 25 24V1C25 0.446875 24.5531 0 24 0ZM23 23H17.2469V15.3188H20.4969L20.9844 11.5469H17.2469V9.1375C17.2469 8.04375 17.55 7.3 19.1156 7.3H21.1125V3.925C20.7656 3.87813 19.5813 3.775 18.2 3.775C15.3188 3.775 13.3469 5.53437 13.3469 8.7625V11.5437H10.0906V15.3156H13.35V23H2V2H23V23Z"
        fill={color}
      />
    </svg>
  );
};

export default IconFacebook;
