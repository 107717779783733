import React from "react";
import { useLocation } from "react-router-dom";

const FamaLogoMenuR = ({ width = 156, height = 114, color = "#ffffff" }) => {
  const { pathname } = useLocation();

  if (pathname === "/home") {
    color = "#F8D29A";
  } else if (pathname === "/foods") {
    color = "#804C33";
  } else if (pathname === "/drinks") {
    color = "#E6CAF0";
  } else if (pathname === "/night") {
    color = "#FFF7EB";
  } else if (pathname === "/events") {
    color = "#F8D29A";
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 156 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1158_708)">
        <path
          d="M149.596 90.5166C147.631 90.5166 145.669 90.5082 143.705 90.525C143.326 90.5278 143.141 90.4126 142.969 90.0615C142.234 88.5331 141.676 86.912 140.73 85.496C138.403 82.015 135.117 80.2001 130.933 80.3687C126.653 80.54 123.465 82.6528 121.427 86.4288C120.807 87.5779 120.305 88.7916 119.749 89.9772C119.519 90.4688 119.011 90.6936 118.517 90.5082C118.155 90.3705 118.438 90.1542 118.497 90.0193C119.058 88.7691 119.639 87.5301 120.218 86.2883C123.787 78.6155 127.36 70.9428 130.933 63.27C132.717 59.4322 134.511 55.6001 136.273 51.7539C136.498 51.2622 136.787 51.147 137.298 51.1386C137.822 51.1302 137.94 51.4448 138.106 51.7988C142.332 60.8791 146.565 69.9595 150.791 79.0398C152.483 82.6725 154.164 86.308 155.876 89.9322C156.123 90.4548 156.006 90.5335 155.489 90.5278C153.525 90.5082 151.563 90.5194 149.598 90.5194L149.596 90.5166ZM131.337 65.0681C128.685 70.7714 126.091 76.3455 123.453 82.015C128.718 78.284 133.879 78.2643 139.212 81.9757C136.554 76.2724 133.975 70.7321 131.334 65.0653L131.337 65.0681Z"
          fill={color}
        />
        <path
          d="M66.9512 53.92V54.5774C66.9512 66.3324 66.9512 78.0874 66.9512 89.8423C66.9512 90.2244 67.0073 90.5531 66.4123 90.5363C65.9156 90.5222 65.8118 90.3677 65.8118 89.9013C65.823 77.2052 65.8258 64.5062 65.8062 51.8101C65.8062 51.2454 65.9745 51.1414 66.4965 51.1442C70.3835 51.1667 74.2704 51.1639 78.1574 51.1442C78.612 51.1442 78.845 51.2791 79.0358 51.6921C82.7852 59.7778 86.5515 67.858 90.315 75.9409C90.3992 76.1207 90.503 76.2921 90.6546 76.5702C91.2018 75.3987 91.707 74.317 92.2122 73.2354C95.5518 66.0627 98.8915 58.89 102.217 51.7117C102.405 51.3044 102.61 51.1442 103.076 51.147C106.573 51.1695 110.072 51.1695 113.569 51.147C114.089 51.1442 114.212 51.2959 114.212 51.7988C114.198 64.495 114.198 77.1939 114.212 89.8901C114.212 90.4127 114.058 90.5307 113.558 90.5278C110.12 90.5082 106.685 90.5026 103.247 90.5307C102.644 90.5363 102.464 90.4042 102.467 89.7665C102.489 78.1127 102.484 66.4616 102.484 54.8078C102.484 54.5943 102.484 54.378 102.484 54.1644C102.439 54.1532 102.394 54.1448 102.349 54.1335C101.937 55.0101 101.521 55.8839 101.111 56.7632C95.9476 67.8664 90.7809 78.9695 85.631 90.0811C85.4374 90.4969 85.1735 90.5278 84.8087 90.5166C84.4439 90.5082 84.1548 90.5363 83.9387 90.0727C79.8244 81.1862 75.6821 72.311 71.5454 63.4358C70.0944 60.3229 68.6435 57.2099 67.1925 54.097C67.1589 54.0268 67.114 53.9622 67.0719 53.8947C67.0298 53.9032 66.9849 53.9116 66.9428 53.9172L66.9512 53.92Z"
          fill={color}
        />
        <path
          d="M55.2818 90.5166C53.3594 90.5166 51.4342 90.4941 49.5118 90.5306C48.9869 90.5391 48.7793 90.334 48.566 89.8929C47.7689 88.2381 47.1571 86.4878 46.0654 84.9819C41.0896 78.1098 30.6355 79.051 26.9534 86.7041C26.4118 87.8279 25.8954 88.9629 25.3678 90.0924C25.2022 90.4435 24.4529 90.6936 24.1301 90.4969C23.9056 90.3621 24.0796 90.2075 24.1385 90.0811C24.8991 88.4291 25.6652 86.7771 26.4342 85.128C29.6701 78.1772 32.9059 71.2265 36.139 64.273C38.1147 60.0279 40.0989 55.7855 42.0522 51.5319C42.2599 51.0824 42.5742 51.1751 42.9053 51.1554C43.2477 51.1358 43.5003 51.1723 43.6799 51.5628C46.1917 57.002 48.7259 62.43 51.2546 67.8607C54.569 74.9772 57.8834 82.0965 61.2063 89.2102C61.8209 90.525 61.8349 90.5166 60.3756 90.5166C58.6777 90.5166 56.9797 90.5166 55.2818 90.5166ZM44.8951 81.9841C42.2458 76.2977 39.6555 70.7405 37.0146 65.0681C34.3681 70.7545 31.7833 76.3146 29.1368 82.0066C34.4467 78.27 39.6077 78.2812 44.8951 81.9869V81.9841Z"
          fill={color}
        />
        <path
          d="M0.0140656 70.8474C0.0140656 64.5204 0.0196785 58.1934 0.00283978 51.8664C0.00283978 51.3326 0.101066 51.1388 0.693229 51.1416C9.65146 51.1612 18.6097 51.1556 27.5679 51.1556C28.2779 51.1556 28.3565 51.2624 28.2639 51.9816C28.2387 52.1811 28.1797 52.285 27.9692 52.2794C27.8065 52.2738 27.6409 52.2794 27.4781 52.2794C22.4658 52.2794 17.4562 52.2878 12.4439 52.2682C11.9107 52.2682 11.7114 52.3665 11.7142 52.9621C11.7395 58.2243 11.7395 63.4865 11.7142 68.7487C11.7142 69.3359 11.8994 69.4539 12.4411 69.4483C15.1409 69.423 17.8407 69.4483 20.5405 69.4258C21.0008 69.423 21.1327 69.5522 21.1299 70.013C21.1271 70.4597 20.9699 70.5552 20.5574 70.5524C17.8379 70.5356 15.1156 70.5609 12.3962 70.53C11.8517 70.5243 11.7142 70.6732 11.717 71.2127C11.7367 77.3964 11.717 83.5801 11.7451 89.7638C11.7451 90.4016 11.5655 90.5336 10.9621 90.528C7.54661 90.4971 4.13115 90.5027 0.715681 90.528C0.171227 90.5308 -0.00277315 90.4072 3.33141e-05 89.8284C0.022485 83.5014 0.0140656 77.1744 0.0140656 70.8474Z"
          fill={color}
        />
        <path
          d="M91.0443 26.6229C90.3455 26.6481 89.6439 26.6285 88.9451 26.6313C88.2238 26.6313 87.4998 26.6453 86.7785 26.6257C86.4137 26.6144 86.2453 26.7184 86.2481 27.1145C86.2621 28.6064 86.2621 30.0982 86.2481 31.5901C86.2453 31.9693 86.4137 32.0312 86.7504 32.0031C89.2145 31.8148 91.3671 29.6571 91.586 27.1904C91.6281 26.7015 91.4737 26.606 91.0443 26.6229Z"
          fill={color}
        />
        <path
          d="M84.7635 32.0396C84.7635 30.4803 84.7635 28.9182 84.7635 27.359C84.7635 26.5835 84.7242 26.5554 83.944 26.6847C81.275 27.1201 79.3217 29.4183 79.3554 32.0873C79.3919 34.8041 81.4041 37.0714 84.0927 37.4451C84.5951 37.5153 84.7803 37.3833 84.7719 36.8551C84.7466 35.2508 84.7635 33.6438 84.7635 32.0396Z"
          fill={color}
        />
        <path
          d="M97.4236 19.6525C94.2803 19.6806 89.4055 19.6722 86.2623 19.6609C85.6056 19.6609 84.9545 19.6862 84.3062 19.7958C80.481 20.442 78.4323 23.7291 79.639 27.4573C80.6325 26.2914 81.814 25.589 83.2257 25.2799C84.0704 25.0945 84.932 25.1142 85.7908 25.0945C87.8227 25.0439 91.5974 25.1872 93.6208 24.9372C95.866 24.6618 97.9007 22.507 98.0635 20.3493C98.1028 19.8239 97.9849 19.6469 97.4236 19.6497V19.6525Z"
          fill={color}
        />
        <path
          d="M72.0557 26.6847C71.2755 26.5583 71.239 26.5835 71.2362 27.359C71.2334 28.9182 71.2362 30.4803 71.2362 32.0396C71.2362 33.6438 71.2531 35.2509 71.2278 36.8551C71.2194 37.3833 71.4046 37.5153 71.907 37.4451C74.5956 37.0714 76.6106 34.8041 76.6443 32.0874C76.678 29.4211 74.7275 27.1202 72.0557 26.6847Z"
          fill={color}
        />
        <path
          d="M69.2187 26.6229C68.4974 26.6425 67.7733 26.6285 67.0521 26.6285C66.3533 26.6285 65.6517 26.6453 64.9528 26.62C64.5235 26.6032 64.3663 26.6987 64.4112 27.1876C64.6301 29.6543 66.7827 31.812 69.2467 32.0002C69.5835 32.0255 69.7519 31.9637 69.7491 31.5873C69.7379 30.0954 69.7351 28.6036 69.7491 27.1117C69.7519 26.7156 69.5835 26.6116 69.2187 26.6229Z"
          fill={color}
        />
        <path
          d="M71.6939 19.7958C71.0456 19.6862 70.3945 19.6581 69.7377 19.6609C66.5945 19.6722 61.7197 19.6806 58.5764 19.6525C58.0152 19.6469 57.8973 19.8267 57.9366 20.3521C58.0993 22.507 60.134 24.6618 62.3792 24.94C64.4027 25.19 68.1774 25.0467 70.2092 25.0973C71.068 25.117 71.9296 25.0973 72.7743 25.2827C74.186 25.5918 75.3675 26.2942 76.361 27.4601C77.5678 23.7319 75.5191 20.4448 71.6939 19.7986V19.7958Z"
          fill={color}
        />
        <path
          d="M84.3062 17.669C84.9545 17.7785 85.6056 17.8066 86.2623 17.8038C89.4055 17.7926 94.2803 17.7842 97.4236 17.8122C97.9849 17.8179 98.1028 17.6381 98.0635 17.1127C97.9007 14.9578 95.866 12.8029 93.6208 12.5248C91.5974 12.2747 87.8227 12.418 85.7908 12.3674C84.932 12.3478 84.0704 12.3674 83.2257 12.182C81.814 11.873 80.6325 11.1706 79.639 10.0046C78.4323 13.7329 80.481 17.02 84.3062 17.6662V17.669Z"
          fill={color}
        />
        <path
          d="M86.7785 10.8391C87.4998 10.8194 88.2238 10.8335 88.9451 10.8335C89.6439 10.8335 90.3455 10.8166 91.0443 10.8419C91.4737 10.8587 91.6309 10.7632 91.586 10.2744C91.3671 7.80762 89.2145 5.64992 86.7504 5.46168C86.4137 5.4364 86.2453 5.4982 86.2481 5.87468C86.2593 7.36652 86.2621 8.85837 86.2481 10.3502C86.2453 10.7464 86.4137 10.8503 86.7785 10.8391Z"
          fill={color}
        />
        <path
          d="M83.941 10.7773C84.7212 10.9037 84.7577 10.8784 84.7605 10.103C84.7633 8.54371 84.7605 6.98163 84.7605 5.42235C84.7605 3.81812 84.7437 2.21109 84.7689 0.606863C84.7774 0.0786758 84.5921 -0.0533709 84.0898 0.0168667C81.4012 0.390531 79.3862 2.6578 79.3525 5.37459C79.3188 8.04081 81.2693 10.3418 83.941 10.7773Z"
          fill={color}
        />
        <path
          d="M71.2362 5.42235C71.2362 6.98163 71.2362 8.54371 71.2362 10.103C71.2362 10.8784 71.2755 10.9065 72.0557 10.7773C74.7247 10.3418 76.678 8.04362 76.6443 5.37459C76.6078 2.6578 74.5956 0.390531 71.907 0.0168667C71.4046 -0.0533709 71.2194 0.0786758 71.2278 0.606862C71.2531 2.21109 71.2362 3.81812 71.2362 5.42235Z"
          fill={color}
        />
        <path
          d="M64.9526 10.8419C65.6514 10.8166 66.353 10.8363 67.0519 10.8335C67.7731 10.8335 68.4972 10.8194 69.2184 10.8391C69.5833 10.8503 69.7517 10.7464 69.7489 10.3502C69.7348 8.85837 69.7348 7.36653 69.7489 5.87468C69.7517 5.4954 69.5833 5.43359 69.2465 5.46168C66.7824 5.64992 64.6299 7.80762 64.411 10.2744C64.3689 10.7632 64.5232 10.8587 64.9526 10.8419Z"
          fill={color}
        />
        <path
          d="M72.7743 12.1848C71.9296 12.3703 71.068 12.3506 70.2092 12.3703C68.1774 12.4208 64.4027 12.2776 62.3792 12.5276C60.134 12.8029 58.0993 14.9578 57.9366 17.1155C57.8973 17.6409 58.0152 17.8179 58.5764 17.8151C61.7197 17.787 66.5945 17.7954 69.7377 17.8067C70.3945 17.8067 71.0456 17.7814 71.6939 17.6718C75.5191 17.0256 77.5678 13.7385 76.361 10.0103C75.3675 11.1762 74.186 11.8786 72.7743 12.1876V12.1848Z"
          fill={color}
        />
        <path
          d="M39.9528 108.492H43.5731V108.781C43.5731 109.441 43.4946 110.023 43.3402 110.531C43.1886 111.001 42.9333 111.442 42.574 111.849C41.7602 112.765 40.7246 113.223 39.4701 113.223C38.2156 113.223 37.194 112.779 36.3184 111.894C35.4456 111.003 35.0078 109.936 35.0078 108.688C35.0078 107.441 35.4512 106.337 36.3409 105.449C37.2305 104.558 38.3138 104.115 39.588 104.115C40.2727 104.115 40.9126 104.255 41.5076 104.536C42.0745 104.817 42.633 105.272 43.183 105.899L42.2401 106.803C41.5216 105.845 40.646 105.365 39.6132 105.365C38.6871 105.365 37.9097 105.685 37.281 106.326C36.6524 106.955 36.3381 107.742 36.3381 108.688C36.3381 109.635 36.6889 110.47 37.3877 111.105C38.0416 111.692 38.7516 111.984 39.515 111.984C40.1661 111.984 40.7498 111.765 41.269 111.324C41.7882 110.88 42.0773 110.349 42.1362 109.734H39.95V108.489L39.9528 108.492Z"
          fill={color}
        />
        <path
          d="M52.1272 110.928H48.375L47.3983 113.057H45.9727L50.3086 103.732L54.4931 113.057H53.0449L52.1244 110.928H52.1272ZM51.5884 109.683L50.289 106.699L48.9279 109.683H51.5912H51.5884Z"
          fill={color}
        />
        <path
          d="M62.6852 105.592L61.6131 106.23C61.4138 105.882 61.2202 105.654 61.0406 105.547C60.8525 105.427 60.6084 105.365 60.3081 105.365C59.9404 105.365 59.6373 105.469 59.3932 105.677C59.1518 105.882 59.0311 106.14 59.0311 106.449C59.0311 106.876 59.3483 107.222 59.9853 107.483L60.8582 107.84C61.5682 108.127 62.0902 108.481 62.4185 108.894C62.7469 109.309 62.9125 109.818 62.9125 110.419C62.9125 111.225 62.6431 111.894 62.107 112.419C61.5654 112.95 60.8946 113.215 60.092 113.215C59.3314 113.215 58.7028 112.99 58.2089 112.537C57.7205 112.088 57.4174 111.453 57.2939 110.633L58.6326 110.338C58.6944 110.852 58.7982 111.209 58.9498 111.405C59.222 111.785 59.6205 111.973 60.1425 111.973C60.5551 111.973 60.8974 111.835 61.1697 111.557C61.4419 111.279 61.5794 110.93 61.5794 110.506C61.5794 110.335 61.557 110.18 61.5093 110.037C61.4615 109.894 61.3886 109.764 61.2875 109.644C61.1865 109.523 61.0574 109.413 60.8974 109.309C60.7375 109.205 60.5494 109.107 60.3305 109.012L59.4858 108.66C58.2874 108.152 57.6869 107.41 57.6869 106.432C57.6869 105.772 57.9394 105.222 58.4418 104.778C58.9441 104.331 59.57 104.106 60.3193 104.106C61.3296 104.106 62.1183 104.598 62.6852 105.584V105.592Z"
          fill={color}
        />
        <path
          d="M69.227 105.531V113.06H67.9052V105.531H65.8901V104.286H71.2364V105.531H69.227Z"
          fill={color}
        />
        <path
          d="M77.5255 109.321L80.2394 113.06H78.6228L76.1195 109.469H75.8809V113.06H74.5591V104.286H76.1082C77.2673 104.286 78.1036 104.505 78.6172 104.941C79.1841 105.427 79.4676 106.064 79.4676 106.862C79.4676 107.483 79.2908 108.017 78.9344 108.464C78.5779 108.91 78.1093 109.197 77.5255 109.321ZM75.8781 108.315H76.2991C77.5508 108.315 78.1766 107.834 78.1766 106.876C78.1766 105.977 77.5676 105.531 76.3496 105.531H75.8781V108.315Z"
          fill={color}
        />
        <path
          d="M83.0571 108.632C83.0571 107.396 83.509 106.337 84.4126 105.449C85.3135 104.561 86.3968 104.12 87.6597 104.12C88.9226 104.12 89.9807 104.567 90.8731 105.46C91.7712 106.354 92.2174 107.427 92.2174 108.683C92.2174 109.939 91.7684 111.015 90.8675 111.894C89.9638 112.776 88.8721 113.217 87.5924 113.217C86.4614 113.217 85.4454 112.824 84.5446 112.04C83.5539 111.172 83.0571 110.037 83.0571 108.629V108.632ZM84.3902 108.649C84.3902 109.618 84.7157 110.416 85.3668 111.04C86.0151 111.664 86.7617 111.978 87.6092 111.978C88.5297 111.978 89.3043 111.661 89.9358 111.023C90.5672 110.38 90.8843 109.596 90.8843 108.672C90.8843 107.747 90.5728 106.952 89.947 106.32C89.3268 105.682 88.5578 105.365 87.6429 105.365C86.728 105.365 85.9618 105.682 85.3332 106.32C84.7045 106.949 84.3902 107.725 84.3902 108.649Z"
          fill={color}
        />
        <path
          d="M96.0229 113.06V104.286H97.3392C97.9257 104.286 98.3944 104.331 98.7424 104.424C99.0932 104.514 99.3907 104.668 99.6349 104.884C99.8818 105.109 100.075 105.387 100.219 105.719C100.367 106.056 100.44 106.396 100.44 106.741C100.44 107.371 100.199 107.902 99.7191 108.337C100.185 108.497 100.553 108.776 100.819 109.172C101.091 109.565 101.229 110.023 101.229 110.546C101.229 111.231 100.988 111.81 100.502 112.285C100.21 112.577 99.8846 112.779 99.5198 112.891C99.1213 113.001 98.6245 113.057 98.0268 113.057H96.0229V113.06ZM97.3448 108.053H97.7601C98.2513 108.053 98.6133 107.944 98.8406 107.728C99.068 107.511 99.183 107.188 99.183 106.764C99.183 106.34 99.0652 106.036 98.8322 105.823C98.5965 105.609 98.2569 105.502 97.8107 105.502H97.3448V108.053ZM97.3448 111.815H98.1615C98.7592 111.815 99.1971 111.697 99.4777 111.464C99.7724 111.211 99.9211 110.888 99.9211 110.498C99.9211 110.107 99.778 109.801 99.4945 109.543C99.2195 109.29 98.7256 109.16 98.0127 109.16H97.342V111.815H97.3448Z"
          fill={color}
        />
        <path
          d="M110.053 110.928H106.3L105.324 113.057H103.898L108.234 103.732L112.418 113.057H110.97L110.05 110.928H110.053ZM109.514 109.683L108.214 106.699L106.853 109.683H109.516H109.514Z"
          fill={color}
        />
        <path
          d="M118.624 109.321L121.338 113.06H119.721L117.218 109.469H116.979V113.06H115.657V104.286H117.206C118.365 104.286 119.202 104.505 119.715 104.941C120.282 105.427 120.566 106.064 120.566 106.862C120.566 107.483 120.389 108.017 120.033 108.464C119.676 108.91 119.207 109.197 118.624 109.321ZM116.976 108.315H117.397C118.649 108.315 119.275 107.834 119.275 106.876C119.275 105.977 118.666 105.531 117.448 105.531H116.976V108.315Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1158_708">
          <rect width="156" height="113.226" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FamaLogoMenuR;
