// IMG DE ENTRADA
import entrada1 from "../../images/menu/comidas/entradas/piqueoLaFamaDelCaribe.jpg";
import entrada2 from "../../images/menu/comidas/entradas/elFamosoPiqueoMarYTierra.png";
import entrada3 from "../../images/menu/comidas/entradas/divinoTiradito.png";
import entrada4 from "../../images/menu/comidas/entradas/crocanteAtunTriunfo.jpg";
import entrada5 from "../../images/menu/comidas/entradas/losFamososNachos.jpg";
import entrada6 from "../../images/menu/comidas/entradas/divinoCarpacciodeLomo.png";
import entrada7 from "../../images/menu/comidas/entradas/croquetasBuenaVidaVida.jpg";
import entrada8 from "../../images/carrucel/imgC1.png";

// IMG DE ARROZ
import arroz1 from "../../images/menu/comidas/arroces/elApoteosicoCaldoso.png";
import arroz2 from "../../images/menu/comidas/arroces/elCelebreArrozConCoco.png";
import arroz3 from "../../images/menu/comidas/arroces/losSecretosEscondidosDeLos4Quesos.png";
import arroz4 from "../../images/menu/comidas/arroces/ilustreChaufa.png";

// IMG DE CARNES Y AVES
import carnesYaves1 from "../../images/menu/comidas/carnesYaves/elLomo.png";
import carnesYaves2 from "../../images/menu/comidas/carnesYaves/suMagestadElRibEye.png";
import carnesYaves3 from "../../images/menu/comidas/carnesYaves/elGloriosoLomo.png";
import carnesYaves4 from "../../images/menu/comidas/carnesYaves/elTalentoDeNewYork.png";
import carnesYaves5 from "../../images/menu/comidas/carnesYaves/elCelebrePolloConAji.png";

// IMG DE PASTAS Y RISOTTOS
import pastasYrisottos1 from "../../images/menu/comidas/pastasYrisottos/enUnPedestalAlPesto.png";
import pastasYrisottos2 from "../../images/menu/comidas/pastasYrisottos/elCaribeEnPasta.png";
import pastasYrisottos3 from "../../images/menu/comidas/pastasYrisottos/famosoRisotto.png";

// IMG DE PESCADOS
import pescados1 from "../../images/menu/comidas/pescado/laFamaDelCaribe.png";
import pescados2 from "../../images/menu/comidas/pescado/elRumorDelSalmon.png";
import pescados3 from "../../images/menu/comidas/pescado/secretosEscondidosLos4Quesos.png";

// IMG DE SOPAS
import sopas1 from "../../images/menu/comidas/sopas/sopaLaAlegria.png";
import sopas2 from "../../images/menu/comidas/sopas/cazuelas.png";

// IMG DE KIDS
import kids1 from "../../images/menu/comidas/kids/polloBBQ.png";
import kids2 from "../../images/carrucel/imgC1.png";

// IMG DE POSTRES
import postres1 from "../../images/menu/comidas/postres/suspiro3Luces.png";
import postres2 from "../../images/menu/comidas/postres/volcanDeDesiciones.png";
import postres3 from "../../images/menu/comidas/postres/tresLechesDeFrutosRojos.png";
import postres4 from "../../images/menu/comidas/postres/postreEspecialDeLaChef.png";

// IMG DE COCTELES
import cocteles1 from "../../images/menu/bebidas/cocteles/unPasoAlaFama.png";
import cocteles2 from "../../images/menu/bebidas/cocteles/unMartiniDeFama.png";
import cocteles3 from "../../images/menu/bebidas/cocteles/enBocaAjena.png";
import cocteles4 from "../../images/menu/bebidas/cocteles/aLaSeguraConRumPunch.png";
import cocteles5 from "../../images/menu/bebidas/cocteles/elInfame.png";
import cocteles6 from "../../images/menu/bebidas/cocteles/laFamosaCatrina.png";
import cocteles7 from "../../images/menu/bebidas/cocteles/elGolpeDeFama.png";
import cocteles8 from "../../images/menu/bebidas/cocteles/mojito.png";
import cocteles9 from "../../images/menu/bebidas/cocteles/carajillo.png";
import cocteles10 from "../../images/menu/bebidas/cocteles/queHablenDeTi.png";
import cocteles11 from "../../images/menu/bebidas/cocteles/laMision.png";

// IMG DE TATAKIS
import tatakis1 from "../../images/menu/noche/tatakis/tunaTataki.png";
import tatakis2 from "../../images/menu/noche/tatakis/salmonTataki.png";

// IMG DE TIRADITOS
import tiraditos1 from "../../images/menu/noche/tiraditos/tiraditoPescaYaji.png";
import tiraditos2 from "../../images/menu/noche/tiraditos/tiraditoDeSalmon.png";
import tiraditos3 from "../../images/menu/noche/tiraditos/tiraditoDeAtun.png";

// IMG DE ROLLS
// import rolls1 from "../../images/menu/noche/rolls/tradicionales.png";
// import rolls2 from "../../images/menu/noche/rolls/volcanRoll.png";
// import rolls3 from "../../images/menu/noche/rolls/dinamitaRoll.png";
// import rolls4 from "../../images/menu/noche/rolls/kiotoRoll.png";
// import rolls5 from "../../images/menu/noche/rolls/osakaRoll.png";
// import rolls6 from "../../images/menu/noche/rolls/samuraiRoll.png";
// import rolls7 from "../../images/menu/noche/rolls/osakaRoll.png";
// import rolls8 from "../../images/menu/noche/rolls/tamagoCrabRoll.png";

// IMG DE MIXTOS
import mixto1 from "../../images/menu/noche/mixtos/platoMixtoSushi.png";

// ICONOS
import IconFreeGlutten from "../icons/IconFreeGlutten";
import IconVegetarian from "../icons/IconVegetarian";
import IconSpicy from "../icons/IconSpicy";

// SECCION DE ENTRADA
export const apiEntradasEn = [
  {
    id: 1,
    img: entrada1,
    titleCard: "Piqueo la Fama del Caribe",
    description:
      "Fish ceviche, octopus ceviche with pesto, pork ceviche, rocotto ceviche.",
    valor: 75,
  },
  {
    id: 2,
    img: entrada2,
    icon: <IconFreeGlutten />,
    titleCard: "El famoso Piqueo Mar y Tierra",
    description: "Pork rinds, squid rrinds, fish rinds and ribeye taco.",
    valor: 73,
  },
  {
    id: 3,
    img: entrada3,
    titleCard: "Divino Tiradito",
    description: "Fine cut of salmon in passion fruit sauce.",
    valor: 20,
  },
  {
    id: 4,
    img: entrada4,
    icon: <IconFreeGlutten />,
    titleCard: "Crocante Atún triunfo",
    description:
      "Tuna cubes marinated in a teriyaki base with crispy leek on a bed of guacamole, on toasted wontons.",
    valor: 13,
  },
  {
    id: 5,
    img: entrada5,
    titleCard: "Los famosos Nachos",
    description: "Crispy corn tortillas with crab in gratin cheese sauce.",
    valor: 18,
  },
  {
    id: 6,
    img: entrada6,
    titleCard: "Divino Carpaccio de Lomo",
    description:
      "Fine cuts of beef tenderloin with pesto and Parmesan, accompanied by arugula and tomato salad.",
    valor: 18,
  },
  {
    id: 7,
    img: entrada7,
    titleCard: "Croquetas buena vida vida",
    description: "Croquettes stuffed with serrano ham and ricotta cheese.",
    valor: 18,
  },
  {
    id: 8,
    img: entrada8,
    titleCard: "Tacos Ribeyes",
    description:
      "Tortilla made of wonton with pieces of ribeye meat on a bed of guacamole accompanied with pico de gallo and cilantro. (Can be ordered for children).",
    valor: 16,
  },
];

// PLATOS FUERTES
// SECCION DE ARROCES
export const apiArrocesEn = [
  {
    id: 1,
    img: arroz1,
    titleCard: "El APOTEOSICO Caldoso",
    description:
      "Soupy rice with shrimp, squid, cuttlefish, prawns, mussels, clams, with a biscuit base and paprika.",
    valor: 35,
  },
  {
    id: 2,
    img: arroz2,
    titleCard: "El Célebre Arroz con Coco",
    description:
      "Creamy coconut rice accompanied with calamari, shrimp and garlic octopus.",
    valor: 32,
  },
  {
    id: 3,
    img: arroz3,
    titleCard: "El afamado arroz Roquenfort",
    description:
      "Rice with New York cut in wine and smoked pork belly with mushrooms and blue cheese.",
    valor: 32,
  },
  {
    id: 4,
    img: arroz4,
    icon: <IconFreeGlutten />,
    icon2: <IconVegetarian />,
    titleCard: "Ilustre Chaufa",
    description:
      "Wok stir-fried rice with vegetables with protein of your choice.",
    valor: 28,
  },
];

// SECCION DE CARNES Y AVES
export const apiCarnesYavesEn = [
  {
    id: 1,
    img: carnesYaves1,
    titleCard: "El Glorioso Lomo",
    description:
      "Beef tenderloin in Iberian shrimp and chorizo sauce, accompanied with rustic mashed potatoes.",
    valor: 32,
  },
  {
    id: 2,
    img: carnesYaves2,
    icon: <IconFreeGlutten />,
    titleCard: "Su Majestad el Rib Eye",
    description:
      "Rib Eye angust cut on a hot plate accompanied with a rustic mashed potato, hot vegetables and miso sauce.",
    valor: 35,
  },
  {
    id: 3,
    img: carnesYaves3,
    titleCard: "No cualquiera tiene FAMA att: El Lomo",
    description:
      "Loin medallions in 4 cheese sauce accompanied with rustic mashed potatoes.",
    valor: 32,
  },
  {
    id: 4,
    img: carnesYaves4,
    titleCard: "El talento de New York",
    description:
      "Risotto with mushrooms topped with grilled tenderloin medallions or choice of meat: shrimp, chicken, beef.",
    valor: 35,
  },
  {
    id: 5,
    img: carnesYaves5,
    icon: <IconSpicy />,
    titleCard: "El Celebre Pollo con ají",
    description:
      "Chicken in chili sauce on a bed of crispy plantains with avocado and house sauce.",
    valor: 26,
  },
];

// SECCION DE CARNES Y AVES
export const apiPastasYrisottosEn = [
  {
    id: 1,
    img: pastasYrisottos1,
    titleCard: "En un pedestal al PESTO",
    description:
      "Pesto pasta topped with julienned beef tenderloin, (choice of meat: chicken, shrimp or beef)",
    valor: 28,
  },
  {
    id: 2,
    img: pastasYrisottos2,
    titleCard: "El caribe en Pasta",
    description:
      "Pasta with shrimp, squid, octopus, clams and mussels in a seafood sauce, accompanied with garlic butter bread.",
    valor: 35,
  },
  {
    id: 3,
    img: pastasYrisottos3,
    icon: <IconSpicy />,
    titleCard: "¿Exitoso o Famoso? Risotto",
    description:
      "Mellow risotto with peach and aji amarillo accompanied with a garlic fish fillet and crispy leek.",
    valor: 26,
  },
];

// SECCION DE PESCADOS
export const apiPescadosEn = [
  {
    id: 1,
    img: pescados1,
    icon: <IconFreeGlutten />,
    titleCard: "Pescado LA FAMA DEL CARIBE",
    description:
      "Panko breaded fish fillet in shrimp sauce with rocotto chili cream.",
    valor: 33,
  },
  {
    id: 2,
    img: pescados2,
    titleCard: "El Rumor del Salmón",
    description:
      "Salmon with a bed of mashed potatoes accompanied With a creamy chili of shrimp, squid and octopus in wine and basil.",
    valor: 32,
  },
  {
    id: 3,
    img: pescados3,
    titleCard: "Los secretos Escondidos de los 4 Quesos",
    description:
      "Fish fillet with shrimp in 4 cheese sauce accompanied with sides of your choice.",
    valor: 32,
  },
];

// SECCION DE SOPAS
export const apiSopasEn = [
  {
    id: 1,
    img: sopas1,
    titleCard: "Sopa LA ALEGRÍA",
    description: "Tomato soup with shrimp. (Cup or bowl)",
    valor: 20,
  },
  {
    id: 2,
    img: sopas2,
    titleCard: "Bienvenidos a la Fama de las Cazuelas",
    description: "Casserole with a variety of seafood.",
    valor: 32,
  },
];

// SECCION DE POSTRES
export const apiKidsEn = [
  {
    id: 1,
    img: kids1,
    titleCard: "Pollo BBQ",
    description: "Chicken julienne served with french fries.",
    valor: 16,
  },
  {
    id: 2,
    img: kids2,
    titleCard: "Tacos Ribeyes",
    description:
      "Tortilla made of wonton with pieces of ribeye meat on a bed of guacamole accompanied with pico de gallo and cilantro. (Can be ordered for children).",
    valor: 16,
  },
];

// SECCION DE POSTRES
export const apiPostresEn = [
  {
    id: 1,
    img: postres1,
    titleCard: "Suspiro 3 luces",
    description:
      "3 different flavors of cheesecakes: strawberry, peach and passion fruit.",
    valor: 17,
  },
  {
    id: 2,
    img: postres2,
    titleCard: "Volcán de Decisiones",
    description: "Chocolate cake volcano with pistachio ice cream.",
    valor: 16,
  },
  {
    id: 3,
    img: postres3,
    titleCard: "Tres leches de Frutos Rojos",
    description: " ",
    valor: 15,
  },
  {
    id: 4,
    img: postres4,
    titleCard: "Postre Especial de la Chef",
    description: "Red velvet with flan and caramel or carrot cake.",
    valor: 18,
  },
];

// SECCION DE LADOS
export const apiSidesEn = [
  {
    id: 1,
    titleList: "Rustic mash potatoes",
    valor: 5,
  },
  {
    id: 2,
    titleList: "French fries",
    valor: 5,
  },
  {
    id: 3,
    titleList: "Hot vegetables",
    valor: 4,
  },
  {
    id: 4,
    titleList: "Fresh house salad",
    valor: 4,
  },
  {
    id: 5,
    titleList: "Coconut rice",
    valor: 6,
  },
  {
    id: 6,
    titleList: "Grilled rice",
    valor: 6,
  },
  {
    id: 7,
    titleList: "Fries Plantains",
    valor: 5,
  },
];

// LICORES
// SECCION DE COCTELES CLASICOS
export const apiCoctelesClasicosEn = [
  {
    id: 1,
    titleList: "Mojito",
    valor: 12,
  },
  {
    id: 2,
    titleList: "Margarita",
    valor: 12,
  },
  {
    id: 3,
    titleList: "Gin Tonic",
    valor: 13,
  },
  {
    id: 4,
    titleList: "Martini",
    valor: 12,
  },
  {
    id: 5,
    titleList: "Old Fashion",
    valor: 14,
  },
];

// SECCION DE COCTELES
export const apiCoctelesEn = [
  {
    id: 1,
    img: cocteles1,
    titleCard: "UN PASO A LA FAMA",
    description:
      "Blend, LuLo, Simple Syrup, Lemon, Chipotle Chili Syrup, Roasted Lemon Liqueur, and Worm Salt.",
    valor: 18,
  },
  {
    id: 2,
    img: cocteles2,
    titleCard: "EL MARTINI DE FAMA",
    description:
      "Frangelico, short coffee, vodka, Amaretto with drops of Angostura bitters.",
    valor: 16,
  },
  {
    id: 3,
    img: cocteles3,
    titleCard: "EN BOCA AJENA",
    description:
      "Bourbon whiskey, lemon, cuanrtro of the house, ginger syrup Drops of Angostura bitters, nitro macerated mint Finished with ginger beer.",
    valor: 17,
  },
  {
    id: 4,
    img: cocteles4,
    titleCard: "A LA SEGURA CON RUM PUNCH",
    description:
      "White Rum, Dark Rum, Simple Syrup, Lemon Liqueur, Orange, Pineapple, House Grenadine, Grapefruit, Orange Pineapple.",
    valor: 16,
  },
  {
    id: 5,
    img: cocteles5,
    titleCard: "EL INFAME",
    description:
      "Gin, roasted lemon, lemon, ginger syrup Drops of Angostura bitters Nitro macerated basil.",
    valor: 17,
  },
  {
    id: 6,
    img: cocteles6,
    titleCard: "LA FAMOSA CATRINA",
    description:
      "Tequila, cuantro of the house, passion fruit liqueur, Orgeat mango liqueur, lemon juice and tequila chile drops.",
    valor: 16,
  },
  {
    id: 7,
    img: cocteles7,
    titleCard: "EL GOLPE DE FAMA",
    description:
      "Vodka, Watermelon Juice, House Grenadine, Roasted Lemon Liqueur, Lemon Juice and Tequila Chili Drops.",
    valor: 18,
  },
  {
    id: 8,
    img: cocteles8,
    titleCard: "BUENA VIBRA",
    description:
      "Gin, fermented pineapple, roasted lemon liqueur, lemon, Angostura bitter drops.",
    valor: 17,
  },
  {
    id: 9,
    img: cocteles9,
    titleCard: "AL CARAJILLO LO QUE PIENSEN",
    description: "Licor 43, short coffee and drops of Angostura bitters.",
    valor: 16,
  },
  {
    id: 10,
    img: cocteles10,
    titleCard: "QUE HABLEN DE TI",
    description:
      "Aguardiente, lemon liqueur, passion fruit juice and grenadine.",
    valor: 16,
  },

  {
    id: 11,
    img: cocteles11,
    titleCard: "LA MISIÓN",
    description: "Shots of aguardiente or rum viejo de caldas.",
    valor: 17,
  },
];

// SECCION DE CHAMPAÑA
export const apiChampagneEn = [
  {
    id: 1,
    titleList: "Moet Ice Imperial",
    valor: 200,
  },
  {
    id: 2,
    titleList: "Moet Imperial",
    valor: 150,
  },
];

// SECCION DE COGNAC
export const apiCognacEn = [
  {
    id: 1,
    titleList: "Hennessy",
    valor: 175,
  },
  {
    id: 2,
    titleList: "Hennessy V.S.O.P",
    valor: 230,
  },
  {
    id: 3,
    titleList: "Remy",
    valor: 175,
  },
  {
    id: 4,
    titleList: "Remy 1738",
    valor: 230,
  },
  {
    id: 5,
    titleList: "D’ussé",
    valor: 230,
  },
];

// SECCION DE WHISKEY
export const apiWhiskeyEn = [
  {
    id: 1,
    titleList: "Buchanan’s 12",
    valor: 170,
  },
  {
    id: 2,
    titleList: "Buchanan’s 18",
    valor: 230,
  },
  {
    id: 3,
    titleList: "Buchanan’s Masters",
    valor: 210,
  },
  {
    id: 4,
    titleList: "Buchanan’s Red Seal",
    valor: 400,
  },
  {
    id: 5,
    titleList: "Black Label",
    valor: 170,
  },
  {
    id: 6,
    titleList: "Gold Label",
    valor: 220,
  },
  {
    id: 7,
    titleList: "Blue Label",
    valor: 600,
  },
  {
    id: 8,
    titleList: "Crown Royal",
    valor: 170,
  },
  {
    id: 9,
    titleList: "Chivas Regal 12",
    valor: 170,
  },
  {
    id: 10,
    titleList: "Chivas Regal 18",
    valor: 230,
  },
  {
    id: 11,
    titleList: "Chivas Regal 21",
    valor: 600,
  },
  {
    id: 12,
    titleList: "Jack Daniel",
    valor: 200,
  },
  {
    id: 13,
    titleList: "Jack Daniel Honey",
    valor: 200,
  },
];

// SECCION DE VODKA
export const apiVodkaEn = [
  {
    id: 1,
    titleList: "Ciroc",
    valor: 170,
  },
  {
    id: 2,
    titleList: "Titos",
    valor: 170,
  },
  {
    id: 3,
    titleList: "Grey Goose",
    valor: 170,
  },
  {
    id: 4,
    titleList: "Belvedere",
    valor: 170,
  },
  {
    id: 5,
    titleList: "Absolut",
    valor: 170,
  },
  {
    id: 6,
    titleList: "Ketel One",
    valor: 170,
  },
  {
    id: 7,
    titleList: "Pier 1",
    valor: 170,
  },
];

// SECCION DE TEQUILA
export const apiTequilaEn = [
  {
    id: 1,
    titleList: "Clase Azul",
    valor: 550,
  },
  {
    id: 2,
    titleList: "Don Julio 1942",
    valor: 600,
  },
  {
    id: 3,
    titleList: "Don Julio Añejo",
    valor: 260,
  },
  {
    id: 4,
    titleList: "Don Julio Reposado",
    valor: 230,
  },
  {
    id: 5,
    titleList: "Don Julio Silver",
    valor: 190,
  },
  {
    id: 6,
    titleList: "Patron Platinium",
    valor: 500,
  },
  {
    id: 7,
    titleList: "Patron",
    valor: 190,
  },
  {
    id: 8,
    titleList: "Patron Reposado",
    valor: 230,
  },
  {
    id: 9,
    titleList: "Casa Amigos",
    valor: 190,
  },
  {
    id: 10,
    titleList: "Casa Amigos Reposado",
    valor: 230,
  },
];

// SECCION DE IMPORTADOS
export const apiImportadosEn = [
  {
    id: 1,
    titleList: "Malibu",
    valor: 170,
  },
  {
    id: 2,
    titleList: "Bacardi",
    valor: 200,
  },
  {
    id: 3,
    titleList: "Santa Teresa",
    valor: 150,
  },
  {
    id: 4,
    titleList: "Diplomatico",
    valor: 170,
  },
  {
    id: 5,
    titleList: "Brugal",
    valor: 170,
  },
  {
    id: 6,
    titleList: "Aguardiente Antioqueño",
    valor: 150,
  },
  {
    id: 7,
    titleList: "Ron Viejo de Caldas",
    valor: 150,
  },
];

// SECCION DE WINE
export const apiWineEn = [
  {
    id: 1,
    titleList: "Bartenura Moscato",
    valor: 120,
  },
  {
    id: 2,
    titleList: "Bartenura Vino Rosso",
    valor: 130,
  },
  {
    id: 3,
    titleList: "Bartenura Pino Grigio",
    valor: 120,
  },
  {
    id: 4,
    titleList: "Altos del Plata Malbec",
    valor: 130,
  },
];

//NOCHE
// SECCION DE MIXTOS
export const apiMixtosEn = [
  {
    id: 1,
    img: mixto1,
    titleCard: "MIXTOS",
    description:
      "Two salmon niguiris, two tuna niguiris, mixed sashimi of 3 fish of your choice, half a roll of your choice, tiradito of the catch of the day in aji amarillo sauce.",
    valor: 23,
  },
];

// SECCION DE TATAKIS
export const apiTatakisEn = [
  {
    id: 1,
    img: tatakis1,
    titleCard: "Tuna tataki",
    description: "Tuna loin flamed with the sesame, scallions and ponzu.",
    valor: 15,
  },
  {
    id: 2,
    img: tatakis2,
    titleCard: "Salmon tataki",
    description: "Salmon strip flamed over a bed of rice seeds in eel sauce.",
    valor: 15,
  },
];

// SECCION DE TIRADITOS
export const apiTiraditosEn = [
  {
    id: 1,
    img: tiraditos1,
    titleCard: "EL DELICIOSO DE AJÍ",
    description:
      "Fine white fish strips in yellow aji sauce onion and cilantro.",
    valor: 16,
  },
  {
    id: 2,
    img: tiraditos2,
    titleCard: "DIVINO TIRADITO",
    description:
      "Fine salmon strips over nekkei sauce, wakame topping and massago.",
    valor: 16,
  },
  {
    id: 3,
    img: tiraditos3,
    titleCard: "EL MISIONERO DE ATÚN",
    description: "Fine tuna strips flamed sesame oil ginger and soy sauce.",
    valor: 16,
  },
];

// SECCION DE ROLLS
export const apiRollsEn = [
  {
    id: 1,
    slogan: "EL INFLUENCER",
    titleCard: "Volcán Roll",
    description:
      "Salmon roll without seaweed with topping of cream cheese and flamed eel, chives, eel sauce and tanuki.",
    valor: 15,
  },
  {
    id: 2,
    slogan: "TÓXICA",
    titleCard: "Dinamita Roll",
    description:
      "Stuffed with prawns and avocado, covered with masago bathed in eel sauce, sesame seeds and scallions.",
    valor: 15,
  },
  {
    id: 3,
    slogan: "EL STALKER ROLL",
    titleCard: "Kioto Roll",
    description:
      "Stuffed with crab hearts, prawns and avocado, covered in sesame seeds and salmon tartare topping.",
    valor: 16,
  },
  {
    id: 4,
    slogan: "FAMA ROLL",
    titleCard: "Osaka Roll",
    description:
      "Stuffed with prawns, salmon and avocado, covered with cream cheese and flamed chimichurri, bathed in eel sauce.",
    valor: 18,
  },
  {
    id: 5,
    slogan: "EXITO",
    titleCard: "Salmurai Roll",
    description:
      "Salmon Filled with chives and cream cheese, covered with salmon and avocado, bathed in kimushi sauce and eel sauce.",
    valor: 17,
  },
  {
    id: 6,
    slogan: "EL INTENSO",
    titleCard: "Rocktopus",
    description:
      "Octopus Stuffed with asparagus and avocado, lined with flamed tuna with chimichurri, srirasha, eel sauce and crispy rice pearls.",
    valor: 17,
  },
  {
    id: 7,
    slogan: "DEL MONTON",
    titleCard: "Tamago Crab Roll",
    description:
      "Maki stuffed with omelett, crabmeat, scallion and srirasha lined in",
    valor: 16,
  },
];
