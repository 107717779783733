import React from "react";
import ReactDOM from "react-dom/client";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/aleo";
import "@fontsource/petit-formal-script";

// IMPORTADOS
import App from "./App";
import "./index.css";

// ESPAÑOL
import global_es from "./components/translations/es/global.json";
import home_es from "./components/translations/es/home.json";
import menu_es from "./components/translations/es/menu.json";

// INGLES
import global_en from "./components/translations/en/global.json";
import home_en from "./components/translations/en/home.json";
import menu_en from "./components/translations/en/menu.json";

// IDIOMA
i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("lng") || "es",
  resources: {
    es: {
      global: global_es,
      home: home_es,
      menu: menu_es,
    },
    en: {
      global: global_en,
      home: home_en,
      menu: menu_en,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
