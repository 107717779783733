import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import jsonp from "jsonp";

// IMPORTADOS
import butterfly from "../../../../images/butterfly.png";

const EmailSuscribe = () => {
  const { pathname } = useLocation();
  const [t] = useTranslation("global");

  if (pathname === "/foods") return null;
  if (pathname === "/drinks") return null;
  if (pathname === "/night") return null;
  if (pathname === "/events") return null;

  // ENVIAR CORREO
  const onSubmit = (e) => {
    e.preventDefault();
    const url =
      "https://famagastrobar.us14.list-manage.com/subscribe/post-json?u=087dc16f7a3cdd7eb15004c65&amp;id=0ae8f5d6e8&amp;f_id=004786e0f0";
    const emailInput = document.getElementById("mc-embedded-subscribe-form");
    const email = emailInput.value;
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, data) => {
      const { msg, result } = data;

      if (result === "success") {
        alert("Gracias por suscribirte a Fama!");
      } else {
        alert(msg);
      }
    });
  };

  return (
    <Box className="containerEmailSuscribe">
      <Box className="content">
        <img src={butterfly} alt="" loading="lazy" className="butterfly" />
        <Typography className="title">{t("emailSuscribe.title")}</Typography>

        <Typography className="subtitle">
          {t("emailSuscribe.subtitle")}
        </Typography>

        {/*************
          FORMULARIO
        *************/}
        <Box component="form" noValidate autoComplete="off">
          <TextField
            className="textfield required email"
            id="mc-embedded-subscribe-form"
            label={t("emailSuscribe.placeholder")}
            variant="outlined"
            type="email"
            name="mc-embedded-subscribe-form"
            required
          />

          <Button disableTouchRipple onClick={onSubmit}>
            {t("emailSuscribe.button")}
          </Button>
        </Box>
      </Box>

      <Box className="grafismoInverso"></Box>
    </Box>
  );
};

export default EmailSuscribe;
