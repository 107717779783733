import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InfoFooter = () => {
  const { pathname } = useLocation();
  const [t] = useTranslation("global");

  if (pathname === "/foods") return null;
  if (pathname === "/drinks") return null;
  if (pathname === "/night") return null;

  return (
    // INFO DEL FOOTER
    <Box className="infoFooter">
      {/************
        COLUMNA 1
      ************/}
      <Box className="colum1">
        {/************
          UBICACIÓN
        ************/}
        <Box>
          <Typography>{t("infoFooter.location.title")}</Typography>

          <Typography component={"span"}>
            {t("infoFooter.location.direction")}
          </Typography>
        </Box>

        {/************
          CONTACTO
        ************/}
        <Box>
          <Typography>{t("infoFooter.contact.title")}</Typography>

          <Typography component={"span"}>
            {t("infoFooter.contact.info")}
          </Typography>
        </Box>
      </Box>

      {/************
          COLUMNA 2
        ************/}
      <Box className="colum2">
        {/**********************
          HORARIO DE ATENCION
        **********************/}
        <Box>
          <Typography>{t("infoFooter.openingHours.title")}</Typography>

          <Typography component={"span"}>
            {t("infoFooter.openingHours.info1")}
            <br />
            {t("infoFooter.openingHours.info2")}
            <br />
            {t("infoFooter.openingHours.info3")}
            <br />
            {t("infoFooter.openingHours.info4")}
          </Typography>
        </Box>
      </Box>

      {/************
        COLUMNA 3
      ************/}
      <Box className="colum3">
        {/**********************
          CODIGO DE VESTIMENTA
        **********************/}
        <Box>
          <Typography>{t("infoFooter.dresscode.title")}</Typography>

          <Typography component={"span"}>
            {t("infoFooter.dresscode.paragraph1")}
            <br />
            <br />
            {t("infoFooter.dresscode.paragraph2")}
            <br />
            <br />
            {t("infoFooter.dresscode.paragraph3")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoFooter;
