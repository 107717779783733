import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InstaGrid from "./InstaGrid";

const FeedInstagram = () => {
  const { pathname } = useLocation();
  const [t] = useTranslation("global");

  if (pathname === "/foods") return null;
  if (pathname === "/drinks") return null;
  if (pathname === "/night") return null;
  if (pathname === "/events") return null;

  // useEffect(() => {
  //   const head = document.querySelector("head");
  //   // const classs = document.getElementsByClassName('powr-instagram-feed');
  //   const script = document.createElement("script");
  //   script.setAttribute("src", "https://www.powr.io/powr.js?platform=html");
  //   head.appendChild(script);
  // }, []);

  // TOKEN INSTAGRAM TESTER - FAMA DESARROLLADOR
  // IGQVJYaVR4amE1Y2ExUXM1OENsZAHN6Tm5McnlaUVE0Wmo3VmlkbTFvRDBUd2pxQXl6VWFRSDd4Qm1WN1laR3BXSTNuSjRtNjhBRFFEdXJmajlGM3A5WDZACdk5FSEl5VXJCRzlBZA3lFbHY2eDFVWGVCSQZDZD

  // TOKEN INSTAGRAM TESTER - FAMA GASTROBAR
  // IGQVJVcjRLYUlDZAURtejBITHVyZA2xQMEJsZAVFkR0tXeE1IakJzS2ZAJZAUl2Vkhzc2YtR3dxZAmttMG44YUNKaUJTR1FRSDAzd245QUJKODhNX01SaWxWMmhJWW4wd0FRZAnNnY1RqYnBXT2MzdTVoQkJCagZDZD

  // TOKEN USUARIO
  // EABeOMXdZBRs4BAHxjpylck1Nr185b6xykcl2GCOhZAYxTMYYMrCbZApUnti0ZBbiOx87yZAIdGZCLxkmACtJ4VhMQbahZBUiI0ZC5lT6P2iIZAkR2CbprKA8EbNHc1wP8XGPhX9XfTZASA0HWWpwDTAcDUvdMPrUnr77x3icaPEkZCbfJzGZAYlCcm2xRs33pO7FxY151GEd5dbs6rOyn5bZACve9SDpprcl6YSBkcghMzZCa7fRdTcW9WV5TnmgyXohVB2IMZD

  // LINK DE LA API
  // https://graph.instagram.com/v17.0/7040731222621133/media?access_token=IGQVJYaVR4amE1Y2ExUXM1OENsZAHN6Tm5McnlaUVE0Wmo3VmlkbTFvRDBUd2pxQXl6VWFRSDd4Qm1WN1laR3BXSTNuSjRtNjhBRFFEdXJmajlGM3A5WDZACdk5FSEl5VXJCRzlBZA3lFbHY2eDFVWGVCSQZDZD

  return (
    <>
      {/**********
        SIGUENOS
      *************/}
      <Box className="follow">
        <Typography
          component={"a"}
          href="https://www.instagram.com/fama_gastrobar/"
          target="_blank"
        >
          {t("instagram.title")} <b> @FAMA_GASTROBAR</b>
        </Typography>
      </Box>

      {/****************
        FONDO PERSONAS
      ******************/}
      {/* <Box className="people"></Box> */}
      <InstaGrid />
    </>
  );
};

export default FeedInstagram;
