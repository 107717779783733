import React from "react";

const IconGastronomy = ({ width = 61, height = 50, color = "#F8D29A" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 61 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4113 0C31.6665 0 32.684 1.01753 32.684 2.27273V8.75715C38.7685 9.28913 44.5014 11.9445 48.8542 16.2973C53.7201 21.1632 56.4648 27.7537 56.4933 34.632H58.5498C59.2679 34.632 59.9437 34.9714 60.3726 35.5473C60.8015 36.1232 60.933 36.868 60.7272 37.556L59.343 42.1845C59.3429 42.1848 59.3428 42.185 59.3428 42.1853C58.6706 44.4453 57.2857 46.4273 55.3948 47.8357C53.5042 49.2438 51.2092 50.0029 48.852 50C48.8512 50 48.8503 50 48.8495 50H11.9731C11.9722 50 11.9714 50 11.9705 50C9.61331 50.0029 7.31829 49.2438 5.42779 47.8357C3.53709 46.4275 2.15234 44.4459 1.48009 42.1863C1.47991 42.1857 1.47973 42.1851 1.47956 42.1845L0.0953093 37.556C-0.11044 36.868 0.0210445 36.1232 0.449935 35.5473C0.878826 34.9714 1.55467 34.632 2.27275 34.632H4.32925C4.35775 27.7537 7.10248 21.1632 11.9683 16.2973C16.3211 11.9445 22.054 9.28913 28.1385 8.75715V2.27273C28.1385 1.01753 29.1561 0 30.4113 0ZM5.32463 39.1775H55.4979L54.986 40.8891C54.5935 42.2094 53.7844 43.3675 52.6796 44.1903C51.5749 45.0131 50.2337 45.4566 48.8562 45.4545L48.8528 45.4545L11.9697 45.4545L11.9664 45.4545C10.5889 45.4566 9.24765 45.0131 8.14289 44.1903C7.03814 43.3675 6.22909 42.2094 5.8365 40.8891L5.32463 39.1775ZM51.9478 34.632H8.87475C8.90322 28.9592 11.169 23.5249 15.1825 19.5114C19.2214 15.4725 24.6994 13.2035 30.4113 13.2035C36.1232 13.2035 41.6012 15.4725 45.6401 19.5114C49.6535 23.5249 51.9193 28.9592 51.9478 34.632Z"
        fill={color}
      />
    </svg>
  );
};

export default IconGastronomy;
