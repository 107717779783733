import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// IMPORTADOS
import "./AboutUs.scss";
import IconFama from "../../../components/icons/IconFama";
import IconBorder from "../../../components/icons/IconBorder";
import ImgCarousel from "./imgCarousel/ImgCarousel";

const AboutUs = () => {
  const [t, i18n] = useTranslation("home");

  return (
    <Box className="containerAboutUs" id="aboutUs">
      {/************************
        CONTENEDOR IZQUIERDA
      ************************/}
      <Box className="contentLeft">
        {/*************
          ICONO FAMA
        *************/}
        <IconFama />

        {/********
          TITULO
        ********/}
        <Typography component={"h1"}>{t("aboutUs.title")}</Typography>

        <Typography>{t("aboutUs.paragraph")}</Typography>

        {/****************
          BOTON RESERVA
        ****************/}
        <Button
          href="https://www.toasttab.com/fama-restaurant-new-3815-w-vine-street"
          target="_blank"
          disableTouchRipple
          sx={{
            ":hover": {
              backgroundColor: "transparent !important",
              color: "#cd6445 !important",
              border: "2px solid #cd6445 !important",
              transition: "all 200ms ease-in-out",
            },
          }}
        >
          {t("aboutUs.button")}
        </Button>
      </Box>

      {/*********************
        CONTENEDOR DERECHA
      *********************/}
      <Box className="contentRight">
        <Box className="contentCarrucel">
          {/********
            BORDE
          ********/}
          <ImgCarousel />

          {/***********
            CARRUSEL
          ***********/}
          <IconBorder />
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
