import React from "react";

const IconFama = ({ width = 38, height = 35, color = "#CD6445" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.2294 18.3617C34.265 18.3833 29.673 18.3761 26.7086 18.3617C26.0911 18.3617 25.4735 18.3833 24.8632 18.4915C21.2594 19.0971 19.3267 22.1687 20.4601 25.6512C21.3974 24.5624 22.5091 23.9063 23.8387 23.6179C24.6379 23.4449 25.4444 23.4665 26.2582 23.4449C28.1763 23.4016 31.7292 23.5314 33.6401 23.3006C35.7544 23.0411 37.6726 21.0295 37.8251 19.0178C37.8615 18.5275 37.7525 18.3617 37.2221 18.3617H37.2294Z"
        fill={color}
      />
      <path
        d="M25.2982 29.9338C25.2982 28.4773 25.2982 27.0209 25.2982 25.5572C25.2982 24.829 25.2619 24.8074 24.5281 24.9299C22.0142 25.3409 20.1687 27.4823 20.205 29.977C20.2413 32.515 22.1377 34.6348 24.6734 34.9809C25.1457 35.0458 25.32 34.9232 25.3128 34.4329C25.291 32.9332 25.3055 31.4335 25.3055 29.9338H25.2982Z"
        fill={color}
      />
      <path
        d="M31.2199 24.8725C30.5588 24.8941 29.8976 24.8725 29.2437 24.8725C28.5607 24.8725 27.8777 24.8869 27.202 24.8725C26.8605 24.8653 26.7007 24.959 26.7007 25.3267C26.7152 26.7183 26.7152 28.117 26.7007 29.5086C26.7007 29.8619 26.8533 29.9196 27.173 29.898C29.498 29.7249 31.5251 27.7061 31.7285 25.3988C31.7721 24.9446 31.6196 24.8508 31.2199 24.8653V24.8725Z"
        fill={color}
      />
      <path
        d="M12.9756 18.4915C12.3653 18.3905 11.755 18.3617 11.1301 18.3617C8.16572 18.3761 3.5738 18.3833 0.609399 18.3617C0.0790032 18.3617 -0.0299779 18.5275 0.00635059 19.0178C0.15893 21.0295 2.07707 23.0483 4.19138 23.3006C6.10226 23.5314 9.65519 23.4016 11.5733 23.4449C12.3798 23.4665 13.1936 23.4449 13.9928 23.6179C15.3224 23.9063 16.4413 24.5624 17.3713 25.6512C18.5048 22.1687 16.5794 19.0971 12.9683 18.4915H12.9756Z"
        fill={color}
      />
      <path
        d="M13.3166 24.9299C12.5828 24.8073 12.5464 24.8361 12.5464 25.5571C12.5464 27.0136 12.5464 28.47 12.5464 29.9337C12.5464 31.4334 12.561 32.9331 12.5392 34.4328C12.5319 34.9303 12.7063 35.0529 13.1785 34.9808C15.7143 34.6275 17.6106 32.5149 17.6469 29.977C17.676 27.4823 15.8378 25.3336 13.3238 24.9299H13.3166Z"
        fill={color}
      />
      <path
        d="M10.6432 24.8724C9.96024 24.8941 9.28453 24.8724 8.60155 24.8724C7.94038 24.8724 7.2792 24.8868 6.62529 24.8724C6.21841 24.858 6.0731 24.9445 6.11669 25.406C6.32013 27.7132 8.35453 29.7249 10.6723 29.9051C10.992 29.9267 11.1445 29.8691 11.1445 29.5158C11.1373 28.1242 11.13 26.7254 11.1445 25.3339C11.1445 24.9662 10.992 24.8652 10.6432 24.8796V24.8724Z"
        fill={color}
      />
      <path
        d="M13.9928 11.3824C13.1936 11.5554 12.3871 11.5338 11.5733 11.5554C9.65519 11.5987 6.10226 11.4689 4.19138 11.6996C2.07707 11.9592 0.15893 13.9708 0.00635059 15.9825C-0.0299779 16.4728 0.0790032 16.6386 0.609399 16.6386C3.5738 16.617 8.16572 16.6242 11.1301 16.6386C11.7477 16.6386 12.3653 16.617 12.9756 16.5088C16.5794 15.9031 18.5121 12.8316 17.3786 9.34912C16.4413 10.4379 15.3297 11.094 14.0001 11.3824H13.9928Z"
        fill={color}
      />
      <path
        d="M12.547 5.06611C12.547 6.52256 12.547 7.97901 12.547 9.44267C12.547 10.1709 12.5833 10.1925 13.3171 10.07C15.8311 9.65898 17.6766 7.51756 17.6402 5.02285C17.6039 2.48488 15.7075 0.365091 13.1718 0.0190033C12.6996 -0.0458881 12.5252 0.0766846 12.5324 0.566975C12.5542 2.06669 12.5397 3.5664 12.5397 5.06611H12.547Z"
        fill={color}
      />
      <path
        d="M6.62578 10.1279C7.28696 10.1062 7.94814 10.1279 8.60205 10.1279C9.28502 10.1279 9.968 10.1134 10.6437 10.1279C10.9852 10.1351 11.145 10.0413 11.145 9.67361C11.1305 8.28205 11.1305 6.88328 11.145 5.49173C11.145 5.13843 10.9925 5.08075 10.6728 5.10238C8.34775 5.27542 6.32062 7.29426 6.11718 9.60151C6.07359 10.0558 6.22617 10.1495 6.62578 10.1351V10.1279Z"
        fill={color}
      />
      <path
        d="M24.8625 16.5088C25.4728 16.6097 26.0832 16.6386 26.708 16.6386C29.6724 16.6242 34.2643 16.617 37.2287 16.6386C37.7591 16.6386 37.8681 16.4728 37.8318 15.9825C37.6792 13.9708 35.7611 11.952 33.6467 11.6996C31.7359 11.4689 28.1829 11.5987 26.2648 11.5554C25.4583 11.5338 24.6446 11.5554 23.8453 11.3824C22.5157 11.094 21.3968 10.4379 20.4668 9.34912C19.3333 12.8316 21.2587 15.9031 24.8698 16.5088H24.8625Z"
        fill={color}
      />
      <path
        d="M24.521 10.07C25.2548 10.1925 25.2912 10.1637 25.2912 9.44268C25.2912 7.98623 25.2912 6.52978 25.2912 5.06612C25.2912 3.5664 25.2766 2.06669 25.2984 0.566981C25.3057 0.0694807 25.1313 -0.0530919 24.659 0.0190096C22.1233 0.372307 20.227 2.48488 20.1906 5.02285C20.1616 7.51757 21.9998 9.66619 24.5137 10.07H24.521Z"
        fill={color}
      />
      <path
        d="M27.1953 10.1276C27.8783 10.1059 28.554 10.1276 29.237 10.1276C29.8981 10.1276 30.5593 10.1131 31.2132 10.1276C31.6201 10.142 31.7654 10.0555 31.7218 9.594C31.5184 7.28676 29.484 5.27512 27.1662 5.09487C26.8466 5.07324 26.694 5.13092 26.694 5.48422C26.7012 6.87578 26.7085 8.27455 26.694 9.66611C26.694 10.0338 26.8466 10.1348 27.1953 10.1203V10.1276Z"
        fill={color}
      />
    </svg>
  );
};

export default IconFama;
