import React from "react";
import { Box } from "@mui/material";

// IMPORTADOS
import VideoHome from "./videoHome/VideoHome";
import AboutUs from "./aboutUs/AboutUs";
import BannerButtons from "./bannerButtons/BannerButtons";
import EventReservation from "./EventReservation/EventReservation";

const Home = () => {
  return (
    <Box sx={{ position: "relative" }}>
      {/*********
        VIDEO
      *********/}
      <VideoHome />

      {/*****************
        SOBRE NOSOTROS
      *****************/}
      <AboutUs />

      {/*****************
        BANNER DE BOTONES
      *****************/}
      <BannerButtons />

      {/*************************
        EVENTO DE RESERVACION
      *************************/}
      <EventReservation />
    </Box>
  );
};

export default Home;
