import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// IMPORTADOS
import "./Footer.scss";
import Map from "./map/Map";
import InfoFooter from "./infoFooter/InfoFooter";
import IconWhatsapp from "../../icons/IconWhatsapp";
import IconFacebook from "../../icons/IconFacebook";
import IconInstagram from "../../icons/IconInstagram";
import EmailSuscribe from "./emailSuscribe/EmailSuscribe";
import FeedInstagram from "./feedInstagram/FeedInstagram";
import FamaLogoFooter from "../../../images/logos/FamaLogoFooter";
import { useLocation } from "react-router-dom";

const Footer = (anchor) => {
  const [t] = useTranslation("global");

  const { pathname } = useLocation();

  // MENU
  const [menu, setMenu] = React.useState(null);
  // const openMenu = Boolean(menu);
  // ABRE MENU
  const handleMenu = (event) => {
    setMenu(event.currentTarget);
  };
  // CIERRA MENU
  // const handleCloseMenu = () => {
  //   setMenu(null);
  // };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [openMenuHambur, setOpenMenuHambur] = useState(false);
  const handleMenuHamburguer = () => {
    setOpenMenuHambur(!openMenuHambur);
  };

  // MENU COMIDAS
  const aboutUs = () => {
    window.location.href = "/home#aboutUs";
  };

  // MENU COMIDAS
  // const toFoods = () => {
  //   handleCloseMenu();
  //   window.location.href = "/foods";
  // };
  // // MENU BEBIDAS
  // const toDrinks = () => {
  //   handleCloseMenu();
  //   window.location.href = "/drinks";
  // };
  // // MENU NOCHE
  // const toNight = () => {
  //   handleCloseMenu();
  //   window.location.href = "/night";
  // };

  return (
    <Box>
      {/**************
        INFO FOOTER
      **************/}
      <InfoFooter />

      {/*******
        MAPA
      *******/}
      <Map />

      {/***********************
        EMAIL DE SUSCRIPCION
      ***********************/}
      <EmailSuscribe />

      {/***************************
        SIGUENOS - FEED INSTAGRAM
      ****************************/}
      <FeedInstagram />

      {/********************
        FOOTER DEL FOOTER
      ********************/}
      <Box className="footer">
        <Box className="contentFooter">
          {/***********
            LOGO FAMA
          ***********/}
          <FamaLogoFooter color="#F8D29A" />

          {/********
            LISTA
          ********/}
          <Box
            className="contentMenu"
            sx={{
              "&": {
                justifyContent: "center",

                "@media (max-width: 1400px)": {
                  justifyContent: "center",
                  ">a": {
                    fontSize: "14px",
                  },
                },

                "@media (max-width: 1076px)": {
                  flexDirection: "column",

                  ">a": {
                    marginTop: "15px !important",
                    marginRight: "0px",
                    fontWeight: "500 !important",
                  },
                },

                ".menu": {
                  cursor: "pointer",
                },

                ">a": {
                  color: "#f8d29a",
                  cursor: "pointer",
                  marginLeft: "25px",
                  marginRight: "25px",

                  // transition: "transform 0.5s ease-in-out",
                  ":hover": {
                    ">span": {
                      transform: "scaleX(1)",
                    },
                  },

                  ">span": {
                    backgroundColor: "#f8d29a",
                    height: "2px",
                    display: "block",
                    transform: "scaleX(0)",
                    transformOrigin: "left",
                    transition: "transform 0.5s ease-in-out",
                  },
                },
              },
            }}
          >
            {/* HOME */}
            <Typography component={"a"} href="home">
              {t("header.house")}
              <span></span>
            </Typography>

            {/* SOBRE NOSOTROS */}
            <Typography component={"a"} onClick={aboutUs}>
              {t("header.aboutUs")}
              <span></span>
            </Typography>

            {/* COMIDAS */}
            <Typography component={"a"} href="foods">
              {t("header.menuFoods")}
              <span></span>
            </Typography>

            {/* BEBIDAS */}
            <Typography component={"a"} href="drinks">
              {t("header.menuDrinks")}
              <span></span>
            </Typography>

            {/* NOCHE */}
            <Typography component={"a"} href="night">
              {t("header.menuNight")}
              <span></span>
            </Typography>

            {/* EVENTOS */}
            <Typography component={"a"} href="events">
              {t("header.happening")}
              <span></span>
            </Typography>

            {/* RESERVACION */}
            <Typography
              component={"a"}
              href="https://www.toasttab.com/fama-restaurant-new-3815-w-vine-street"
              target="_blank"
            >
              {t("header.reservation")}
              <span></span>
            </Typography>
          </Box>

          {/*********
            DIVISOR
          *********/}
          <Divider />

          {/*************************
            FOOTER / PIE DE PAGINA
          *************************/}
          <Box className="footerPortal">
            {/***********
              ICONOS
            ***********/}
            <Box>
              {/***********
                WHATSAPP
              ***********/}
              <Button
                href="https://wa.me/14074584631?text=Bienvenido%20a%20Fama%20-%20Gastrobar"
                // href="https://wa.link/9q23oh"
                target="_blank"
              >
                <IconWhatsapp color="#F8D29A" />
              </Button>

              {/***********
                INSTAGRAM
              ***********/}
              <Button
                href="https://www.instagram.com/fama_gastrobar/"
                target="_blank"
              >
                <IconInstagram color="#F8D29A" />
              </Button>

              {/***********
                FACEBOOK
              ***********/}
              <Button
                href="https://www.instagram.com/fama_gastrobar/"
                target="_blank"
              >
                <IconFacebook color="#F8D29A" />
              </Button>
            </Box>

            {/***************
              COPYRIGHT
            ***************/}
            <Typography>FAMA © 2023</Typography>

            {/* <Box className="privacyPolicy">
              <Typography component="a" href="privacyPolicy">
                Politica de privacidad
              </Typography>{" "}
              <Typography component={"a"} href="conditionService">
                Condiciones del servicio
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
