import { Box, Button, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";

// IMPORTADOS
import img1 from "../../../../images/carrucel/imgC1.png";
import img2 from "../../../../images/carrucel/imgC2.png";
import img3 from "../../../../images/carrucel/imgC3.png";
import img4 from "../../../../images/carrucel/imgC4.png";
import img5 from "../../../../images/carrucel/imgC5.png";
import IconBorder from "../../../../components/icons/IconBorder";
import Carousel from "react-material-ui-carousel";

// API IMAGENES
const items = [
  {
    id: 1,
    img: `${img1}`,
    title: "img1",
  },
  {
    id: 2,
    img: `${img2}`,
    title: "img2",
  },
  {
    id: 3,
    img: `${img3}`,
    title: "img3",
  },
  {
    id: 4,
    img: `${img4}`,
    title: "img4",
  },
  {
    id: 5,
    img: `${img5}`,
    title: "img5",
  },
];

// FAMA DESARROLLADOR
// const userId = "7040731222621133";
// const access_token =
//   "IGQVJYaVR4amE1Y2ExUXM1OENsZAHN6Tm5McnlaUVE0Wmo3VmlkbTFvRDBUd2pxQXl6VWFRSDd4Qm1WN1laR3BXSTNuSjRtNjhBRFFEdXJmajlGM3A5WDZACdk5FSEl5VXJCRzlBZA3lFbHY2eDFVWGVCSQZDZD";

// FAMA GASTROBAR
const userId = "6787904047921529";
const access_token =
  "IGQVJVcjRLYUlDZAURtejBITHVyZA2xQMEJsZAVFkR0tXeE1IakJzS2ZAJZAUl2Vkhzc2YtR3dxZAmttMG44YUNKaUJTR1FRSDAzd245QUJKODhNX01SaWxWMmhJWW4wd0FRZAnNnY1RqYnBXT2MzdTVoQkJCagZDZD";

const instaUrl = `https://graph.instagram.com/v17.0/${userId}/media?access_token=${access_token}`;

const InstaGrid = () => {
  const [instaItems, setInstaItems] = useState([]);
  const [instaCell, setInstaCell] = useState(true);

  useEffect(() => {
    doFetch();
  }, [userId, access_token, instaUrl]);

  const fetchMedia = async (id) => {
    const mediaUrl = `https://graph.instagram.com/${id}?access_token=${access_token}&fields=media_url,permalink`;

    const res = await fetch(mediaUrl);
    const json = await res.json();
    console.log(json);

    const instaItem = {
      permalink: json.permalink,
      mediaUrl: json.media_url,
    };

    return instaItem;
  };

  const doFetch = async () => {
    if (!userId || !access_token) {
      console.log("userId or accessToken is undefined: ", {
        userId,
        access_token,
      });
      return;
    }

    const res = await fetch(instaUrl);
    const jsonFeeds = (await res.json()).data?.slice(0, 5);

    const fetchedItems = [];

    for (let i = 0; i < jsonFeeds?.length && i < 9; i++) {
      const item = jsonFeeds[i];
      const itemId = item.id;
      const instaItem = await fetchMedia(itemId);
      fetchedItems.push(instaItem);
    }

    setInstaItems(fetchedItems);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f8d29a",
        display: "flex",
        justifyContent: "center",
        // overflow: "hidden",
        transition: "all ease-in-out 0.3s",

        ">.carrusel": {
          display: "none",
          overflow: "visible",
        },

        ">.insta": {
          display: "block",
          ">a": {
            height: 425,
            transition: "all ease-in-out 0.3s",

            ":hover": {
              transform: "scale(1.1, 1.1)",
              zIndex: 1,
              transition: "all ease-in-out 0.3s",
            },
          },
        },

        "@media (max-width: 700px)": {
          display: "block",
          justifyContent: "initial",

          ">.carrusel": {
            display: "block",
          },

          ">.insta": {
            display: "none",
          },
        },
      }}
    >
      {/**********************
        CARRUCEL RESPONSIVE
      **********************/}
      {/* <Box className="people"></Box> */}

      <Carousel
        className="carrusel"
        autoPlay={false}
        animation="slide"
        // OCULTA BOTONES FLECHA
        navButtonsProps={{
          style: {
            display: "none",
          },
        }}
        // OCULTA BOTONES INFERIORES
        indicatorContainerProps={{
          style: {
            display: "none",
          },
        }}
      >
        {instaItems.map((instaFeed) => (
          <a href={instaFeed.permalink} target="_blank" rel="noreferrer">
            <img src={instaFeed.mediaUrl} alt="" loading="lazy" height={425} />
          </a>
        ))}
      </Carousel>

      {/***********************
        CARRUCEL COMPUTADOR
      ***********************/}
      <Box className="insta">
        {instaItems.map((instaFeed) => (
          <a href={instaFeed.permalink} target="_blank" rel="noreferrer">
            <img src={instaFeed.mediaUrl} alt="" loading="lazy" height={425} />
          </a>
        ))}
      </Box>
    </Box>
  );
};

export default InstaGrid;
