import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "../pages/home/Home";
import Portal from "../pages/portal/Portal";
import Layout from "../components/layout/Layout";
import Foods from "../pages/menu/foods/Foods";
import Drinks from "../pages/menu/drinks/Drinks";
import Night from "../pages/menu/night/Night";
import Events from "../pages/menu/events/Events";
import PrivacyPolicy from "../components/layout/footer/PrivacyPolicy";
import ConditionService from "../components/layout/footer/ConditionService";

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: "",
      element: <Portal />,
    },
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "foods",
          element: <Foods />,
        },
        {
          path: "drinks",
          element: <Drinks />,
        },
        {
          path: "night",
          element: <Night />,
        },
        {
          path: "events",
          element: <Events />,
        },

        {
          path: "privacyPolicy",
          element: <PrivacyPolicy />,
        },
        {
          path: "conditionService",
          element: <ConditionService />,
        },
      ],
    },
  ]);
  return routes;
};

export default AppRoutes;
