// IMG DE ENTRADA
import entrada1 from "../../images/menu/comidas/entradas/piqueoLaFamaDelCaribe.jpg";
import entrada2 from "../../images/menu/comidas/entradas/elFamosoPiqueoMarYTierra.png";
import entrada3 from "../../images/menu/comidas/entradas/divinoTiradito.png";
import entrada4 from "../../images/menu/comidas/entradas/crocanteAtunTriunfo.jpg";
import entrada5 from "../../images/menu/comidas/entradas/losFamososNachos.jpg";
import entrada6 from "../../images/menu/comidas/entradas/divinoCarpacciodeLomo.png";
import entrada7 from "../../images/menu/comidas/entradas/croquetasBuenaVidaVida.jpg";
import entrada8 from "../../images/carrucel/imgC1.png";

// IMG DE ARROZ
import arroz1 from "../../images/menu/comidas/arroces/elApoteosicoCaldoso.png";
import arroz2 from "../../images/menu/comidas/arroces/elCelebreArrozConCoco.png";
import arroz3 from "../../images/menu/comidas/arroces/losSecretosEscondidosDeLos4Quesos.png";
import arroz4 from "../../images/menu/comidas/arroces/ilustreChaufa.png";

// IMG DE CARNES Y AVES
import carnesYaves1 from "../../images/menu/comidas/carnesYaves/elLomo.png";
import carnesYaves2 from "../../images/menu/comidas/carnesYaves/suMagestadElRibEye.png";
import carnesYaves3 from "../../images/menu/comidas/carnesYaves/elGloriosoLomo.png";
import carnesYaves4 from "../../images/menu/comidas/carnesYaves/elTalentoDeNewYork.png";
import carnesYaves5 from "../../images/menu/comidas/carnesYaves/elCelebrePolloConAji.png";

// IMG DE PASTAS Y RISOTTOS
import pastasYrisottos1 from "../../images/menu/comidas/pastasYrisottos/enUnPedestalAlPesto.png";
import pastasYrisottos2 from "../../images/menu/comidas/pastasYrisottos/elCaribeEnPasta.png";
import pastasYrisottos3 from "../../images/menu/comidas/pastasYrisottos/famosoRisotto.png";

// IMG DE PESCADOS
import pescados1 from "../../images/menu/comidas/pescado/laFamaDelCaribe.png";
import pescados2 from "../../images/menu/comidas/pescado/elRumorDelSalmon.png";
import pescados3 from "../../images/menu/comidas/pescado/secretosEscondidosLos4Quesos.png";

// IMG DE SOPAS
import sopas1 from "../../images/menu/comidas/sopas/sopaLaAlegria.png";
import sopas2 from "../../images/menu/comidas/sopas/cazuelas.png";

// IMG DE POSTRES
import postres1 from "../../images/menu/comidas/postres/suspiro3Luces.png";
import postres2 from "../../images/menu/comidas/postres/volcanDeDesiciones.png";
import postres3 from "../../images/menu/comidas/postres/tresLechesDeFrutosRojos.png";
import postres4 from "../../images/menu/comidas/postres/postreEspecialDeLaChef.png";

// IMG DE KIDS
import kids1 from "../../images/menu/comidas/kids/polloBBQ.png";
import kids2 from "../../images/carrucel/imgC1.png";

// IMG DE COCTELES
import cocteles1 from "../../images/menu/bebidas/cocteles/unPasoAlaFama.png";
import cocteles2 from "../../images/menu/bebidas/cocteles/unMartiniDeFama.png";
import cocteles3 from "../../images/menu/bebidas/cocteles/enBocaAjena.png";
import cocteles4 from "../../images/menu/bebidas/cocteles/aLaSeguraConRumPunch.png";
import cocteles5 from "../../images/menu/bebidas/cocteles/elInfame.png";
import cocteles6 from "../../images/menu/bebidas/cocteles/laFamosaCatrina.png";
import cocteles7 from "../../images/menu/bebidas/cocteles/elGolpeDeFama.png";
import cocteles8 from "../../images/menu/bebidas/cocteles/mojito.png";
import cocteles9 from "../../images/menu/bebidas/cocteles/carajillo.png";
import cocteles10 from "../../images/menu/bebidas/cocteles/queHablenDeTi.png";
import cocteles11 from "../../images/menu/bebidas/cocteles/laMision.png";

// IMG DE MIXTOS
import mixto1 from "../../images/menu/noche/mixtos/platoMixtoSushi.png";

// IMG DE TATAKIS
import tatakis1 from "../../images/menu/noche/tatakis/tunaTataki.png";
import tatakis2 from "../../images/menu/noche/tatakis/salmonTataki.png";

// IMG DE TIRADITOS
import tiraditos1 from "../../images/menu/noche/tiraditos/tiraditoPescaYaji.png";
import tiraditos2 from "../../images/menu/noche/tiraditos/tiraditoDeSalmon.png";
import tiraditos3 from "../../images/menu/noche/tiraditos/tiraditoDeAtun.png";

// IMG DE ROLLS
import rolls1 from "../../images/menu/noche/rolls/tradicionales.png";
import rolls2 from "../../images/menu/noche/rolls/volcanRoll.png";
import rolls3 from "../../images/menu/noche/rolls/dinamitaRoll.png";
import rolls4 from "../../images/menu/noche/rolls/kiotoRoll.png";
import rolls5 from "../../images/menu/noche/rolls/osakaRoll.png";
import rolls6 from "../../images/menu/noche/rolls/samuraiRoll.png";
import rolls7 from "../../images/menu/noche/rolls/osakaRoll.png";
import rolls8 from "../../images/menu/noche/rolls/tamagoCrabRoll.png";

// ICONOS
import IconFreeGlutten from "../icons/IconFreeGlutten";
import IconVegetarian from "../icons/IconVegetarian";
import IconSpicy from "../icons/IconSpicy";

// SECCION DE ENTRADA
export const apiEntradasEs = [
  {
    id: 1,
    img: entrada1,
    titleCard: "Piqueo la Fama del Caribe",
    description:
      "Ceviche de pescado, Ceviche de pulpo al pesto, ceviche de chicharrón, ceviche de rocotto.",
    valor: 75,
  },
  {
    id: 2,
    img: entrada2,
    icon: <IconFreeGlutten />,
    titleCard: "El famoso Piqueo Mar y Tierra",
    description:
      "Chicharrón de cerdo, chicharrón de calamar, chicharrón de pescado y taco rib eye.",
    valor: 73,
  },
  {
    id: 3,
    img: entrada3,
    titleCard: "Divino Tiradito",
    description: "Fino corte de salmón en salsa de maracuyá.",
    valor: 20,
  },
  {
    id: 4,
    img: entrada4,
    icon: <IconFreeGlutten />,
    titleCard: "Crocante Atún triunfo",
    description:
      "Cubos de atún marinados en base teriyaki con crocante de puerro y cama de guacamole, sobre tostada de wonton.",
    valor: 13,
  },
  {
    id: 5,
    img: entrada5,
    titleCard: "Los famosos Nachos",
    description:
      "Tortillas de maíz crocante con cangrejo en salsa de queso gratinado.",
    valor: 18,
  },
  {
    id: 6,
    img: entrada6,
    titleCard: "Divino Carpaccio de Lomo",
    description:
      "Finos cortes de lomo de res con pesto y parmesano , acompañado de ensalada de rugula y tomate.",
    valor: 18,
  },
  {
    id: 7,
    img: entrada7,
    titleCard: "Croquetas buena vida vida",
    description: "Croquetas rellenas de jamón serrano y queso ricotta.",
    valor: 18,
  },
  {
    id: 8,
    img: entrada8,
    titleCard: "Tacos Ribeyes",
    description: "(Este plato puede ser pedido para niños)",
    valor: 16,
  },
];

// PLATOS FUERTES
// SECCION DE ARROCES
export const apiArrocesEs = [
  {
    id: 1,
    img: arroz1,
    titleCard: "El APOTEOSICO Caldoso",
    description:
      "Arroz caldoso con camarones, calamar, chipirones, gambas, mejillones, almejas, con base de biscuit y pimentón de la vera.",
    valor: 35,
  },
  {
    id: 2,
    img: arroz2,
    titleCard: "El Célebre Arroz con Coco",
    description:
      "Titote de coco cremoso coronado con calamar, langostino y pulpo al ajillo.",
    valor: 32,
  },
  {
    id: 3,
    img: arroz3,
    titleCard: "El afamado arroz Roquenfort",
    description:
      "Arroz cremoso de queso azul con champiñones, panceta de cerdo ahumado y camarones.",
    valor: 32,
  },
  {
    id: 4,
    img: arroz4,
    icon: <IconFreeGlutten />,
    icon2: <IconVegetarian />,
    titleCard: "Ilustre Chaufa",
    description: "Arroz salteado al wok con vegetales con proteína a elección.",
    valor: 28,
  },
];

// SECCION DE CARNES Y AVES
export const apiCarnesYavesEs = [
  {
    id: 1,
    img: carnesYaves1,
    titleCard: "El Glorioso Lomo",
    description:
      "Lomo de res en salsa ibérica de camarones y chorizo, acompañado con puré rústico de papa.",
    valor: 32,
  },
  {
    id: 2,
    img: carnesYaves2,
    icon: <IconFreeGlutten />,
    titleCard: "Su Majestad el Rib Eye",
    description:
      "Corte Rib Eye angust en plancha caliente acompañado con un puré de papa rústico vegetales calientes y salsa miso.",
    valor: 35,
  },
  {
    id: 3,
    img: carnesYaves3,
    titleCard: "No cualquiera tiene FAMA att: El Lomo",
    description:
      "Medallones de lomo en salsa 4 quesos acompañado con puré rústico.",
    valor: 32,
  },
  {
    id: 4,
    img: carnesYaves4,
    titleCard: "El talento de New York",
    description:
      "Risotto con champiñones coronado de medallones de lomo al grill (opción de pollo o camarones).",
    valor: 35,
  },
  {
    id: 5,
    img: carnesYaves5,
    icon: <IconSpicy />,
    titleCard: "El Celebre Pollo con ají",
    description:
      "Pollo en salsa de ají en una cama de tostón crocante con aguacate y salsa ade la casa.",
    valor: 26,
  },
];

// SECCION DE CARNES Y AVES
export const apiPastasYrisottosEs = [
  {
    id: 1,
    img: pastasYrisottos1,
    titleCard: "En un pedestal al PESTO",
    description:
      "Pasta al pesto coronada con julianas de lomo de res, (opción de pollo, o camarones).",
    valor: 28,
  },
  {
    id: 2,
    img: pastasYrisottos2,
    titleCard: "El caribe en Pasta",
    description:
      "Pasta con camarón, calamar, pulpo, langostino, almejas y mejillones  en salsa marinera, acompañada con pan de mantequilla de ajo.",
    valor: 35,
  },
  {
    id: 3,
    img: pastasYrisottos3,
    icon: <IconSpicy />,
    titleCard: "¿Exitoso o Famoso? Risotto",
    description:
      "Risotto meloso con durazno y aji amarillo corondo con un filete de pescado al ajillo y puerro crocante.",
    valor: 26,
  },
];

// SECCION DE PESCADOS
export const apiPescadosEs = [
  {
    id: 1,
    img: pescados1,
    icon: <IconFreeGlutten />,
    titleCard: "Pescado LA FAMA DEL CARIBE",
    description:
      "Filete de pescado apanado al panko en salsa de camarones con crema de ají rocotto.",
    valor: 33,
  },
  {
    id: 2,
    img: pescados2,
    titleCard: "El Rumor del Salmón",
    description:
      "Salmón con cama de puré de papas acompañado Con un ají cremoso de camarón calamar y pulpo en vino y albahaca.",
    valor: 32,
  },
  {
    id: 3,
    img: pescados3,
    titleCard: "Los secretos Escondidos de los 4 Quesos",
    description:
      "Filete de pescado con camarones en salsa 4 quesos acompañado con (1 acompañamiento a su elección).",
    valor: 32,
  },
];

// SECCION DE SOPAS
export const apiSopasEs = [
  {
    id: 1,
    img: sopas1,
    titleCard: "Sopa LA ALEGRÍA",
    description: "Sopa de tomate con camarones.",
    valor: 20,
  },
  {
    id: 2,
    img: sopas2,
    titleCard: "Bienvenidos a la Fama de las Cazuelas",
    description: "Cazuela con variedad de mariscos.",
    valor: 32,
  },
];

// SECCION DE POSTRES
export const apiPostresEs = [
  {
    id: 1,
    img: postres1,
    titleCard: "Suspiro 3 luces",
    description: "3 sabores diferentes de cheesecakes.",
    valor: 17,
  },
  {
    id: 2,
    img: postres2,
    titleCard: "Volcán de Decisiones",
    description: "Volcán de chocolate con helado de pistacho",
    valor: 16,
  },
  {
    id: 3,
    img: postres3,
    titleCard: "Tres leches de Frutos Rojos",
    description: " ",
    valor: 15,
  },
  {
    id: 4,
    img: postres4,
    titleCard: "Postre Especial de la Chef",
    description: "(red velvet con flan y caramelo o torta de zanahoria)",
    valor: 18,
  },
];

// SECCION DE POSTRES
export const apiKidsEs = [
  {
    id: 1,
    img: kids1,
    titleCard: "Pollo BBQ",
    description: "Julianas de pollo acompañado con papas francesas.",
    valor: 16,
  },
  {
    id: 2,
    img: kids2,
    titleCard: "Tacos Ribeyes",
    description: "(Este plato puede ser pedido como entrada)",
    valor: 16,
  },
];

// SECCION DE LADOS
export const apiSidesEs = [
  {
    id: 1,
    titleList: "Puré rustico",
    valor: 5,
  },
  {
    id: 2,
    titleList: "Papas francesas",
    valor: 5,
  },
  {
    id: 3,
    titleList: "Vegetales calientes",
    valor: 4,
  },
  {
    id: 4,
    titleList: "Ensalada fresca DE LA CASA",
    valor: 4,
  },
  {
    id: 5,
    titleList: "Arroz de coco",
    valor: 6,
  },
  {
    id: 6,
    titleList: "Arroz plancha",
    valor: 6,
  },
  {
    id: 7,
    titleList: "Patacones",
    valor: 5,
  },
];

// LICORES
// SECCION DE COCTELES CLASICOS
export const apiCoctelesClasicosEs = [
  {
    id: 1,
    titleList: "Mojito",
    valor: 12,
  },
  {
    id: 2,
    titleList: "Margarita",
    valor: 12,
  },
  {
    id: 3,
    titleList: "Gin Tonic",
    valor: 13,
  },
  {
    id: 4,
    titleList: "Martini",
    valor: 12,
  },
  {
    id: 5,
    titleList: "Old Fashion",
    valor: 14,
  },
];

// SECCION DE COCTELES
export const apiCoctelesEs = [
  {
    id: 1,
    img: cocteles1,
    titleCard: "UN PASO A LA FAMA",
    description:
      "Mezcla,Lujo, Sirope simple, Limón,Sirope de chile chipotle, Licor de limón asado y Sal de gusano.",
    valor: 18,
  },
  {
    id: 2,
    img: cocteles2,
    titleCard: "EL MARTINI DE FAMA",
    description:
      "Frangelico, Café corto, vodka, Amaretto con Gotas de amargo angostura.",
    valor: 16,
  },
  {
    id: 3,
    img: cocteles3,
    titleCard: "EN BOCA AJENA",
    description:
      "Whisky bourbon, limón, cuantro de la casa, almíbar jengibre Gotas de amargo de angostura, Hierbabuena nitro macerada Finalizado con ginger beer.",
    valor: 17,
  },
  {
    id: 4,
    img: cocteles4,
    titleCard: "A LA SEGURA CON RUM PUNCH",
    description:
      "Ron blanco, ron oscuro, almíbar, Licor de limón, naranja, piña, Granadina de la casa, toronja, naranja 1 onz piña",
    valor: 16,
  },
  {
    id: 5,
    img: cocteles5,
    titleCard: "EL INFAME",
    description:
      "Ginebra, limón asado, Limón, almíbar de jengibre, gotas amargos de angostura, Albahaca nitro macerada.",
    valor: 17,
  },
  {
    id: 6,
    img: cocteles6,
    titleCard: "LA FAMOSA CATRINA",
    description:
      "Tequila, cuantro de la casa, Licor de maracuya, Licor de mango, orgeat, Zumo de limón y Gotas de chile tequila",
    valor: 16,
  },
  {
    id: 7,
    img: cocteles7,
    titleCard: "EL GOLPE DE FAMA",
    description:
      "Vodka, zumo Sandia, Granadina de la casa, Licor de limón asado, Zumo de limón y gotas de chile tequila.",
    valor: 18,
  },
  {
    id: 8,
    img: cocteles8,
    titleCard: "BUENA VIBRA",
    description:
      "Ginebra, piña fermentada, Licor de limón asado, limón, gotas amargos de angostura.",
    valor: 17,
  },
  {
    id: 9,
    img: cocteles9,
    titleCard: "AL CARAJILLO LO QUE PIENSEN",
    description: "Licor 43, Café corto y Gotas de amargo de angostura.",
    valor: 16,
  },
  {
    id: 10,
    img: cocteles10,
    titleCard: "QUE HABLEN DE TI",
    description:
      "Tequila, Mezcal, Licor de limón asado, Puré de tamarindo, Almíbar de chipotle, Zumo de limón, Zumo de naranja, Gotas de chile y tequila.",
    valor: 16,
  },

  {
    id: 11,
    img: cocteles11,
    titleCard: "LA MISIÓN",
    description:
      "Tequila, Licor de limón, Licor de limón asado, Zumo de limón, Gotas de chile y tequila, Hielo de coco, Sal de gusano.",
    valor: 17,
  },
];

// SECCION DE CHAMPAÑA
export const apiChampagneEs = [
  {
    id: 1,
    titleList: "Moet Ice Imperial",
    valor: 200,
  },
  {
    id: 2,
    titleList: "Moet Imperial",
    valor: 150,
  },
];

// SECCION DE COGNAC
export const apiCognacEs = [
  {
    id: 1,
    titleList: "Hennessy",
    valor: 175,
  },
  {
    id: 2,
    titleList: "Hennessy V.S.O.P",
    valor: 230,
  },
  {
    id: 3,
    titleList: "Remy",
    valor: 175,
  },
  {
    id: 4,
    titleList: "Remy 1738",
    valor: 230,
  },
  {
    id: 5,
    titleList: "D’ussé",
    valor: 230,
  },
];

// SECCION DE WHISKEY
export const apiWhiskeyEs = [
  {
    id: 1,
    titleList: "Buchanan’s 12",
    valor: 170,
  },
  {
    id: 2,
    titleList: "Buchanan’s 18",
    valor: 230,
  },
  {
    id: 3,
    titleList: "Buchanan’s Masters",
    valor: 210,
  },
  {
    id: 4,
    titleList: "Buchanan’s Red Seal",
    valor: 400,
  },
  {
    id: 5,
    titleList: "Black Label",
    valor: 170,
  },
  {
    id: 6,
    titleList: "Gold Label",
    valor: 220,
  },
  {
    id: 7,
    titleList: "Blue Label",
    valor: 600,
  },
  {
    id: 8,
    titleList: "Crown Royal",
    valor: 170,
  },
  {
    id: 9,
    titleList: "Chivas Regal 12",
    valor: 170,
  },
  {
    id: 10,
    titleList: "Chivas Regal 18",
    valor: 230,
  },
  {
    id: 11,
    titleList: "Chivas Regal 21",
    valor: 600,
  },
  {
    id: 12,
    titleList: "Jack Daniel",
    valor: 200,
  },
  {
    id: 13,
    titleList: "Jack Daniel Honey",
    valor: 200,
  },
];

// SECCION DE VODKA
export const apiVodkaEs = [
  {
    id: 1,
    titleList: "Ciroc",
    valor: 170,
  },
  {
    id: 2,
    titleList: "Titos",
    valor: 170,
  },
  {
    id: 3,
    titleList: "Grey Goose",
    valor: 170,
  },
  {
    id: 4,
    titleList: "Belvedere",
    valor: 170,
  },
  {
    id: 5,
    titleList: "Absolut",
    valor: 170,
  },
  {
    id: 6,
    titleList: "Ketel One",
    valor: 170,
  },
  {
    id: 7,
    titleList: "Pier 1",
    valor: 170,
  },
];

// SECCION DE TEQUILA
export const apiTequilaEs = [
  {
    id: 1,
    titleList: "Clase Azul",
    valor: 550,
  },
  {
    id: 2,
    titleList: "Don Julio 1942",
    valor: 600,
  },
  {
    id: 3,
    titleList: "Don Julio Añejo",
    valor: 260,
  },
  {
    id: 4,
    titleList: "Don Julio Reposado",
    valor: 230,
  },
  {
    id: 5,
    titleList: "Don Julio Silver",
    valor: 190,
  },
  {
    id: 6,
    titleList: "Patron Platinium",
    valor: 500,
  },
  {
    id: 7,
    titleList: "Patron",
    valor: 190,
  },
  {
    id: 8,
    titleList: "Patron Reposado",
    valor: 230,
  },
  {
    id: 9,
    titleList: "Casa Amigos",
    valor: 190,
  },
  {
    id: 10,
    titleList: "Casa Amigos Reposado",
    valor: 230,
  },
];

// SECCION DE IMPORTADOS
export const apiImportadosEs = [
  {
    id: 1,
    titleList: "Malibu",
    valor: 170,
  },
  {
    id: 2,
    titleList: "Bacardi",
    valor: 200,
  },
  {
    id: 3,
    titleList: "Santa Teresa",
    valor: 150,
  },
  {
    id: 4,
    titleList: "Diplomatico",
    valor: 170,
  },
  {
    id: 5,
    titleList: "Brugal",
    valor: 170,
  },
  {
    id: 6,
    titleList: "Aguardiente Antioqueño",
    valor: 150,
  },
  {
    id: 7,
    titleList: "Ron Viejo de Caldas",
    valor: 150,
  },
];

// SECCION DE WINE
export const apiWineEs = [
  {
    id: 1,
    titleList: "Bartenura Moscato",
    valor: 120,
  },
  {
    id: 2,
    titleList: "Bartenura Vino Rosso",
    valor: 130,
  },
  {
    id: 3,
    titleList: "Bartenura Pino Grigio",
    valor: 120,
  },
  {
    id: 4,
    titleList: "Altos del Plata Malbec",
    valor: 130,
  },
];

//NOCHE
// SECCION DE ROLLS
export const apiMixtosEs = [
  {
    id: 1,
    img: mixto1,
    titleCard: "LOS INFLUENCERS",
    description:
      "Dos niguiris de salmon, dos niguiris de atún, sashimi mixto de 3 Pescados a elección, medio rollo a elección, tiradito de pesca del día en salsa de ají amarillo.",
    valor: 23,
  },
];

// SECCION DE TATAKIS
export const apiTatakisEs = [
  {
    id: 1,
    img: tatakis1,
    titleCard: "Tuna tataki",
    description: "Lomo de atún flameado con ajonjolí, cebollin y ponzu.",
    valor: 15,
  },
  {
    id: 2,
    img: tatakis2,
    titleCard: "Salmon tataki",
    description:
      "Cortes de salmón, flameado sobre cama de arroz gojan y germinados ballado en salsa anguila.",
    valor: 15,
  },
];

// SECCION DE TIRADITOS
export const apiTiraditosEs = [
  {
    id: 1,
    img: tiraditos1,
    titleCard: "EL DELICIOSO DE AJÍ",
    description:
      "Finos cortes de pescado blanco bañados con salsa de ají amarillo y criolla de cebolla y cilindro.",
    valor: 16,
  },
  {
    id: 2,
    img: tiraditos2,
    titleCard: "DIVINO TIRADITO",
    description:
      "Finos cortes de salmon sobre salsa nikkei y toppin de wakame y massago.",
    valor: 16,
  },
  {
    id: 3,
    img: tiraditos3,
    titleCard: "EL MISIONERO DE ATÚN",
    description:
      "Finos cortes de atún flameados con aceite de sésamo gengibre y soya.",
    valor: 16,
  },
];

// SECCION DE ROLLS
export const apiRollsEs = [
  // {
  //   id: 1,
  //   img: rolls1,
  //   titleCard: "Tradicionales",
  //   description: "Philadelphia, California, Ebi roll y Spicy Tuna.",
  //   valor: 75,
  // },
  {
    id: 1,
    // img: rolls2,
    slogan: "EL INFLUENCER",
    titleCard: "Volcán roll",
    description:
      "Rollo de salmon sin alga con topping de queso crema y anguila flameado, cebollin, salsa de anguila y tanuki.",
    valor: 15,
  },
  {
    id: 2,
    // img: rolls3,
    slogan: "TÓXICA",
    titleCard: "Dinamita roll",
    description:
      "Relleno de langostinos y aguacate , cubierto de massago bañado en salsa de anguila, ajonjolí y cebollín.",
    valor: 15,
  },
  {
    id: 3,
    // img: rolls4,
    slogan: "EL STALKER ROLL",
    titleCard: "Kioto roll",
    description:
      "Relleno de palmito de cangrejo, langostinos y aguacate, forrado en ajonjolí y topping de tartar de salmón.",
    valor: 16,
  },
  {
    id: 4,
    // img: rolls5,
    slogan: "FAMA ROLL",
    titleCard: "Osaka roll",
    description:
      "Relleno de langostinos, salmón y aguacate, cubierto con queso crema y chimichurri flameado, bañado en salsa anguila.",
    valor: 18,
  },
  {
    id: 5,
    // img: rolls6,
    slogan: "ÉXITO",
    titleCard: "Samurai roll",
    description:
      "Relleno de salmón, cebollín y queso crema, cubierto por salmón y aguacate, bañado en salsa kimushi y salsa de anguila.",
    valor: 17,
  },
  {
    id: 6,
    // img: rolls7,
    slogan: "EL INTENSO",
    titleCard: "Rocktopus",
    description:
      "Relleno de pulpo espárragos y aguacate, forrado con atún flameado con chimichurri, srirasha , salsa de anguila y perlas de arroz crocantes.",
    valor: 17,
  },
  {
    id: 7,
    // img: rolls8,
    slogan: "DEL MONTON",
    titleCard: "Tamago crab roll",
    description:
      "Maki relleno de omelett,  carne de cangrejo, cebollín y srirasha forrado en",
    valor: 16,
  },
];
