import React from "react";
import { Paper } from "@mui/material";
import Carousel from "react-material-ui-carousel";

// IMPORTADOS
import img1 from "../../../../images/carrucel/imgC1.png";
import img2 from "../../../../images/carrucel/imgC2.png";
import img3 from "../../../../images/carrucel/imgC3.png";
import img4 from "../../../../images/carrucel/imgC4.png";
import img5 from "../../../../images/carrucel/imgC5.png";
import IconBorder from "../../../../components/icons/IconBorder";

// API IMAGENES
const items = [
  {
    id: 1,
    img: `${img1}`,
    title: "img1",
  },
  {
    id: 2,
    img: `${img2}`,
    title: "img2",
  },
  {
    id: 3,
    img: `${img3}`,
    title: "img3",
  },
  {
    id: 4,
    img: `${img4}`,
    title: "img4",
  },
  {
    id: 5,
    img: `${img5}`,
    title: "img5",
  },
];

// IMAGENES
function ItemImgCarousel({ item }) {
  return (
    <Paper>
      <img src={item.img} alt={item.title} loading="lazy" width={"100%"} />
    </Paper>
  );
}

// CARRUSEL
function ImgCarousel(props) {
  return (
    <Carousel
      className="carrusel"
      // OCULTA BOTONES FLECHA
      navButtonsProps={{
        style: {
          display: "none",
        },
      }}
      // OCULTA BOTONES INFERIORES
      indicatorContainerProps={{
        style: {
          display: "none",
        },
      }}
    >
      {items.map((item) => (
        <ItemImgCarousel key={item.id} item={item} />
      ))}
    </Carousel>
  );
}

export default ImgCarousel;
